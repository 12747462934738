import React from "react";

function MDCBrowseFile(props) {
  const { acceptFileFormats, inputRef, onChange, name } = props;

  return (
    <>
      <input
        name={name}
        type="file"
        accept={acceptFileFormats}
        ref={inputRef}
        onChange={onChange}
        hidden
      />
    </>
  );
}

export default MDCBrowseFile;
