import { vorgangeDetailsService } from "../../services/vorgange/vorgangeDetailsService";
import {
  vorgangeDetailsConstants,
  HTTP_SUCCESS_STATUS_CODES,
} from "../../../constants/VorgangDetailsConstants";
import { toast } from "react-toastify";
import NotificationMessage from "../../../components/core/NotificationMessage";

function getIndividualVorgangeDetailSuccess(vorgangeDetails) {
  return {
    type: vorgangeDetailsConstants.VORGANGE_DETAILS_SUCCESS,
    vorgangeDetails,
  };
}

function getIndividualVorgangeDetails(id, isArchivData) {
  return (dispatch) => {
    return new Promise((resolve) => {
      return vorgangeDetailsService
        .getIndividualVorgangeDetails(id, isArchivData)
        .then((vorgangeDetails) => {
          dispatch(getIndividualVorgangeDetailSuccess(vorgangeDetails));
          resolve(vorgangeDetails);
        });
    });
  };
}
function resetIndividualVorgangeDetails() {
  return (dispatch) => {
    dispatch({
      type: vorgangeDetailsConstants.VORGANGE_DETAILS_SUCCESS,
      undefined,
    });
  };
}
function handleRegistrationGKSSuccess(resp) {
  return {
    type: vorgangeDetailsConstants.REGISTRATION_TO_GKS_SUCCESS,
    resp,
  };
}

function handleRegistrationGKS(ids) {
  return (dispatch) => {
    return new Promise((resolve) => {
      vorgangeDetailsService.handleRegistrationGKS(ids).then((resp) => {
        dispatch(handleRegistrationGKSSuccess(resp));
        resolve(resp);
      });
    });
  };
}

function handleRejection(id) {
  return () => {
    return new Promise((resolve) => {
      vorgangeDetailsService.handleRejection(id).then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp.status) && resp.data) {
          const { message, primaryReference, requestId } = resp.data;
          toast.info(
            <NotificationMessage
              message={message}
              primaryReference={primaryReference}
              requestId={requestId}
            />
          );
        }
        resolve(resp);
      });
    });
  };
}

function handlePullRequest(id) {
  return () => {
    return new Promise((resolve) => {
      vorgangeDetailsService.handlePullRequest(id).then((resp) => {
        if (
          resp &&
          HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status) &&
          resp.data
        ) {
          const { message, primaryReference, requestId } = resp.data;
          const KDOStatus = resp.data?.responseData?.status;
          if (KDOStatus === "PENDING" || "REGISTERED") {
            toast.info(
              <NotificationMessage
                message={message}
                primaryReference={
                  KDOStatus === "PENDING" ? "" : primaryReference
                }
                requestId={KDOStatus === "PENDING" ? "" : requestId}
              />
            );
          } else {
            // When KDOStatus is REJECTED
            toast.error(
              <NotificationMessage
                message={message}
                primaryReference={primaryReference}
                requestId={requestId}
              />
            );
          }
        } else {
          if (resp?.data?.message) {
            toast.error(<NotificationMessage message={resp.data.message} />);
          }
        }
        resolve(resp);
      });
    });
  };
}

function uploadFileSuccess(response) {
  return {
    type: vorgangeDetailsConstants.UPLOAD_SUCCESS_MODAL,
    response,
  };
}

function uploadFile(file) {
  return (dispatch) => {
    return new Promise((resolve) => {
      vorgangeDetailsService.uploadFile(file).then((response) => {
        dispatch(uploadFileSuccess(response.data));
        resolve(response);
      });
    });
  };
}

function downloadFileSuccess(resp) {
  return {
    type: vorgangeDetailsConstants.GETTING_FILE_DOWNLOAD_SUCCESS,
    resp,
  };
}

function downloadFile(requestId, fileType) {
  return (dispatch) => {
    return vorgangeDetailsService
      .downloadFile(requestId, fileType)
      .then((resp) => {
        dispatch(downloadFileSuccess(resp));
      });
  };
}
function getRequestDetails(requestId) {
  return () => {
    return vorgangeDetailsService.getRequestDetails(requestId).then((resp) => {
      return resp;
    });
  };
}
function getDataRegistrationsList(requestId) {
  return () => {
    return vorgangeDetailsService
      .getDataRegistrationsList(requestId)
      .then((resp) => {
        return resp;
      });
  };
}

function getRegisterDatenXMLSuccess(resp) {
  return { type: "GET_REGISTER_DATEN_XML_SUCCESS", resp };
}

function getRegisterDatenXML(requestId) {
  return (dispatch) => {
    return vorgangeDetailsService
      .getRegisterDatenXML(requestId)
      .then((resp) => {
        dispatch(getRegisterDatenXMLSuccess(resp));
      });
  };
}
export const vorgangeDetailsActions = {
  // Added for unit testcases start
  getIndividualVorgangeDetailSuccess,
  uploadFileSuccess,
  downloadFileSuccess,
  handleRegistrationGKSSuccess,
  // Added for unit testcases Ends

  getIndividualVorgangeDetails,
  resetIndividualVorgangeDetails,
  handleRegistrationGKS,
  handleRejection,
  handlePullRequest,
  uploadFile,
  downloadFile,
  getRequestDetails,
  getDataRegistrationsList,
  getRegisterDatenXML,
  getRegisterDatenXMLSuccess
};
