import React from "react";
import { useDispatch } from "react-redux";

// Material UI Components
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

// Assets
import carLogo from "../../../assets/images/my_digital_car.svg";
import MDCModal from "../../core/MDCModal";

import LargeTruckIcon from "../../iconComponents/LargeTruckIcon";

// Themes
import theme from "../../../themes/mdc-theme";
import MDCButton from "../../core/MDCButton";

// Actions
import { OrderLicensePlateActions } from "../../../store/actions/vorgange/OrderLicensePlateActions";

const useStyles = makeStyles((theme) => ({
  kennzeiSection: {
    marginRight: ".5rem",
  },
  headerCss: {
    padding: "2rem",
    alignItems: "center",
  },
  headerColor: { color: theme.palette.MDCColors.black },
  backgroundCSS: {
    padding: "1rem",
    paddingBottom: ".5rem",
    margin: ".5rem 0",
    backgroundColor: theme.palette.MDCColors.cardBackgroundColor,
    width: "100%",
  },
}));

const LicensePlateDeleteModal = ({
  open,
  setDeleteLicensePopup,
  requestId,
  orderId,
}) => {
  const { headerCss, headerColor } = useStyles();

  const dispatch = useDispatch();

  const getModalHeader = () => {
    return (
      <Grid container className={headerCss}>
        <Grid item xs={8} className={headerColor}>
          Kennzeichenbestellung abbrechen
        </Grid>
        <Grid item xs={4}>
          <img alt="My Digital Car" src={carLogo} width={"95%"} />
        </Grid>
      </Grid>
    );
  };

  const getModalBody = () => {
    return (
      <Grid container padding="2rem" alignItems="center" flexDirection="column">
        <Grid>
          <LargeTruckIcon />
        </Grid>
        <Grid marginY="2rem" color={theme?.palette?.MDCColors?.color18}>
          Sind Sie sicher, dass Sie den Kennzeichenbestellprozess abrechen
          wollen?
        </Grid>
        <Grid container justifyContent="space-between">
          <MDCButton
            variant="outlined"
            label={"Abbrechen"}
            onClick={() => setDeleteLicensePopup(false)}
          />
          <MDCButton
            variant="contained"
            label={"Bestätigen"}
            onClick={() => {
              dispatch(
                OrderLicensePlateActions.deleteOrderLicensePlate(
                  requestId,
                  orderId
                )
              );
              setDeleteLicensePopup(false);
            }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <MDCModal
        open={open}
        title={getModalHeader()}
        body={getModalBody()}
        size="sm"
      />
    </>
  );
};

export default LicensePlateDeleteModal;
