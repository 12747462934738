export const FEES_REPORTS_NO_SELECT_ERROR =
  "Bitte wählen Sie mindestens einen Vorgang vor dem Download aus.";

export const GET_FEES_REPORT_CSV_LIST = "GET_FEES_REPORT_CSV_LIST";

export const GETTING_FEES_REPORT_DETAILS_ROW_CSV_SUCCESS =
  "GETTING_FEES_REPORT_DETAILS_ROW_CSV_SUCCESS";

export const REQUEST_FEES_REPORT_DETAILS_ROW_CSV =
  "REQUEST_FEES_REPORT_DETAILS_ROW_CSV";

export const REQUEST_FEES_REPORT_ZIP_CSV = "REQUEST_FEES_REPORT_ZIP_CSV";

export const GETTING_FEES_REPORT_ZIP_CSV_SUCCESS =
  "GETTING_FEES_REPORT_ZIP_CSV_SUCCESS";
