import { Subject } from "rxjs";
import { filter } from "rxjs/operators";

const alertSubject = new Subject();
const defaultId = "default-alert";
const defaultCloseIcon = true;

export const alertType = {
  success: "success",
  error: "error",
  info: "info",
  warning: "warning",
};

// enable subscribing to alerts observable
function onAlert(id = defaultId) {
  return alertSubject.asObservable().pipe(filter((x) => x && x.id === id));
}

// core alert method
function alert(newAlert) {
  newAlert.id = newAlert.id || defaultId;
  newAlert.closeIcon =
    newAlert.closeIcon === undefined ? defaultCloseIcon : newAlert.closeIcon;
  alertSubject.next(newAlert);
}

// convenience methods
function success(message, options) {
  alert({ ...options, type: alertType.success, message });
}

function error(message, options) {
  alert({ ...options, type: alertType.error, message });
}

function info(message, options) {
  alert({ ...options, type: alertType.info, message });
}

function warn(message, options) {
  alert({ ...options, type: alertType.warning, message });
}

// clear alerts
function clear(id = defaultId) {
  alertSubject.next({ id });
}

export const globalAlertService = {
  onAlert,
  success,
  error,
  info,
  warn,
  alert,
  clear,
};
