import * as React from "react";

function CancelIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} {...props}>
      <g data-name="Gruppe 3909">
        <g data-name="Komponente 152 \u2013 17">
          <path
            data-name="Pfad 897"
            d="M24.776 13.889l-3.1-2.732.958-4.2-4.278-.4-1.687-4.059-3.41 2.235-3.477-2.236-1.68 4.139-4.248.435.979 4.135-3.062 2.748 3.189 2.921-.925 4.409 4.094.3 1.543 3.908 3.612-2.271 3.68 2.27 1.536-3.987 4.066-.337-.949-4.349zm-7.884-3.693l-4.526 8.309a.2.2 0 01-.311.066l-3.128-2.734a.3.3 0 01-.052-.366l.256-.436a.232.232 0 01.36-.069l2.236 1.942a.24.24 0 00.376-.08l3.949-7.275a.3.3 0 01.438-.118l.292.209a.436.436 0 01.109.551z"
            fill="none"
            stroke="currentColor"
            strokeMiterlimit={10}
          />
        </g>
        <path
          data-name="Linie 140"
          fill="none"
          stroke="currentColor"
          strokeWidth={3}
          d="M5.5 24.5l17-21"
        />
        <g data-name="Ellipse 68" fill="none" stroke="currentColor">
          <circle cx={14} cy={14} r={14} stroke="none" />
          <circle cx={14} cy={14} r={13.5} />
        </g>
      </g>
    </svg>
  );
}

export default CancelIcon;
