import * as React from "react";

// Material UI Components
import Chip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";

// Styles
const useStyles = makeStyles((theme) => ({
  button: {
    "&.MuiChip-defaultDefault": {
      backgroundColor: (props) =>
        props?.bgColor ? `${props?.bgColor}` : theme.palette.MDCColors.white,
      borderRadius: (props) => (props?.bgColor ? `0` : "5px"),
      fontWeight: (props) => (props?.bgColor && `600`),
      padding: ".3rem",
      cursor: "pointer",
      color: (props) => props?.textColor && `${props?.textColor}`,
      "&:hover": {
        backgroundColor: (props) =>
          props?.bgColor ? props?.bgColor : theme.palette.MDCColors.color35,
      },
    },
    "&.MuiChip-outlinedDefault": {
      backgroundColor: theme.palette.MDCColors.color35,
      border: `2px solid ${theme.palette.MDCColors.color23}`,
      borderRadius: "5px",
      padding: ".3rem",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.MDCColors.color35,
      },
    },
  },
}));

export default function MDCChip(props) {
  const { icon, label, isActive, color } = props;

  const { button } = useStyles(props);

  return (
    <Chip
      className={button}
      icon={icon}
      label={label}
      color={color}
      variant={isActive ? "outlined" : "default"}
    />
  );
}
