// Get User Status Label
const getUserStatus = (user) => {
  if (user?.requiredActions?.length) {
    return "Angelegt";
  } else if (user?.enabled) {
    return "Aktiv";
  } else {
    return "Gesperrt";
  }
};

export const convertKeycloakObjectToClientObject = (user) => {
  Object.keys(user)?.forEach((item) => {
    if (item === "groups") {
      let previousGroups = [],
        previousSelectedGroups = [];

      user[item]?.forEach((roleDetails) => {
        if (roleDetails["path"].split("/")?.includes("Roles")) {
          const role = roleDetails["name"];

          user["roles"] =
            role + (user["roles"] !== undefined ? `, ${user["roles"]}` : "");

          previousSelectedGroups.push(roleDetails);
        }

        previousGroups.push(roleDetails);
      });
      user["previousGroups"] = previousGroups;
      user["previousSelectedGroups"] = previousSelectedGroups;
      user[item] = user["roles"];
    } else if (item === "enabled") {
      user[item] = getUserStatus(user);
    } else if (item === "empEmailId" && user[item]?.includes("api-user")) {
      //If its API user,then disabling the row
      user["disabledApiRole"] = true;
    } else {
      user[item] = user[item];
    }
  });
  return user;
};
