import React, { useState } from "react";

// MDC Components
import MDCSelect from "./MDCSelect";

const CustomMenuFilter = (props) => {
  const [selectedVal, setSelectedVal] = useState(0);
  let options = [
    {
      label: "Alle",
      value: "",
    },
  ];

  Object.entries(props.columnDef.lookup).map((item) =>
    options.push({
      label: item[1],
      value: item[0],
    })
  );

  function handleChange(e) {
    const val = e.target.value;
    setSelectedVal(val);
    props.onFilterChanged(props.columnDef.tableData.id, val);
  }

  return (
    <MDCSelect
      name="steuerzahlweise"
      value={selectedVal || ""}
      handleChange={handleChange}
      options={options}
      isfilterMenu
    />
  );
};

export default CustomMenuFilter;
