const initialState = {
  loading: true,
};

const requestedXmlLogsReducer = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }

  switch (action.type) {
    case "GET_REQUESTED_XML_LOGS_LIST":
      return Object.assign({}, state, {
        rows: action?.resp?.content?.length > 0 ? action?.resp?.content : [],
        totalCount: +action.resp.totalElements,
        loading: false,
      });

    case "GET_REQUESTED_XML_LOGS_ZIP_SUCCESS":
      return Object.assign({}, state, {
        csvData: action.resp,
        loading: false,
      });

    default:
      return state;
  }
};

export default requestedXmlLogsReducer;
