import * as React from "react";

function ButtonDocumentIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17.5}
      height={21.5}
      {...props}
    >
      <g
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          data-name="Path 1385"
          d="M9.75.75h-7a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-11Z"
        />
        <path data-name="Path 1386" d="M9.75.75v7h7" />
      </g>
    </svg>
  );
}

export default ButtonDocumentIcon;
