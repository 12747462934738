import * as React from "react";

const InfoIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
    <path
      fill="#00c4de"
      d="M6.3 10.5h1.4V6.3H6.3ZM7 0a7 7 0 1 0 7 7 7 7 0 0 0-7-7Zm0 12.6A5.6 5.6 0 1 1 12.6 7 5.607 5.607 0 0 1 7 12.6Zm-.7-7.7h1.4V3.5H6.3Z"
      data-name="Icon material-info-outline"
    />
  </svg>
);

export default InfoIcon;
