import React from "react";
import Grid from "@mui/material/Grid";
import theme from "../../themes/mdc-theme";
const MDCDividerLine = (props) => {
  const { color } = props;
  return (
    <>
      <Grid
        borderBottom={`.1rem solid ${
          color ? color : theme.palette.MDCColors.white
        }`}
        opacity={0.3}
        margin={"1rem 0"}
      />
    </>
  );
};

export default MDCDividerLine;
