import * as React from "react";
const ServiceInfoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 35 34"
    {...props}
  >
    <path
      fill="#fff"
      d="M15.3 25.5h3.4V15.3h-3.4ZM17 0a17 17 0 1 0 17 17A17.006 17.006 0 0 0 17 0Zm0 30.6A13.6 13.6 0 1 1 30.6 17 13.618 13.618 0 0 1 17 30.6Zm-1.7-18.7h3.4V8.5h-3.4Z"
      data-name="Icon material-info-outline"
    />
  </svg>
);
export default ServiceInfoIcon;
