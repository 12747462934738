import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

import { NavDropdown } from "react-bootstrap";

// Icons
import DownArrowIcon from "../iconComponents/DownArrowIcon";
import UpArrowIcon from "../iconComponents/UpArrowIcon";
import ErrorIcon from "../iconComponents/ErrorIcon";

//Core Components
import MDCButtonDropdown from "./MDCButtonDropdown";

import { closeIconCSS } from "../shared/AlertCSS";

// Theme
import theme from "../../themes/mdc-theme";
// Constants
import { USER_ROLE_ADMIN } from "../../constants/userConstants";

const useStyles = makeStyles(() => ({
  closeIcon: closeIconCSS,
}));

export default function MDCGlobalAlertError({
  alerts,
  certificateDetails,
  userAuthority,
  handleNotification,
  cssClasses,
}) {
  const [showError, setShowError] = useState(false);

  const displayDownloadFiles = (alerts) => {
    const isAdmin = userAuthority?.includes(USER_ROLE_ADMIN);

    const classes = useStyles();

    return (
      <>
        {alerts?.map((alert, index) => (
          <div
            key={index}
            style={{
              background: "none",
              backgroundColor: theme.palette.MDCColors.white,
            }}
            className={cssClasses(alert)}
          >
            {alert.closeIcon && (
              <button
                className={classes.closeIcon}
                onClick={() => removeAlert(alert)}
              >
                &times;
              </button>
            )}
            <div>
              <span dangerouslySetInnerHTML={{ __html: alert.message }} />{" "}
              {alert?.forwardLink && (
                <a href={alert?.forwardLink}>
                  {!certificateDetails && isAdmin
                    ? "Bitte vervollständigen Sie diese."
                    : "Jetzt Nutzerinformationen bearbeiten"}
                </a>
              )}
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <Grid container marginTop="1rem">
      <NavDropdown
        direction="bottom"
        className="global-error"
        title={
          <MDCButtonDropdown
            startIcon={<ErrorIcon />}
            variant={"outlined"}
            label={"Erforderliche Informationen aktualisieren"}
            endIcon={showError ? <UpArrowIcon /> : <DownArrowIcon />}
            onClick={() => {
              handleNotification();
            }}
          />
        }
        onToggle={(toggleValue) => {
          setShowError(toggleValue);
        }}
      >
        {alerts && alerts.length > 0 && displayDownloadFiles(alerts)}
      </NavDropdown>
    </Grid>
  );
}
