// Constants
import { deadLetterQueueConstants } from "../../../constants/DeadLetterQueuesConstants";

const initialState = {
  loading: true,
};

const deadLetterQueueReducer = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }

  switch (action.type) {
    case deadLetterQueueConstants.GET_DEAD_LETTER_QUEUE_LIST:
      return Object.assign({}, state, {
        rows: action?.resp?.content?.length > 0 ? action?.resp?.content : [],
        totalCount: +action.resp.totalElements,
        loading: false,
      });

    case deadLetterQueueConstants.GET_DEAD_LETTER_QUEUE_XML_SUCCESS:
      return Object.assign({}, state, {
        xmlData: action.resp,
        loading: false,
      });

    default:
      return state;
  }
};

export default deadLetterQueueReducer;
