import React from "react";
import PropTypes from "prop-types";

// Material UI Components
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    "&.MuiButton-contained": {
      fontFamily: theme.palette.typography.fontFamily,
      fontSize: "1.125rem",
      textTransform: "initial",
      borderRadius: "5px",
      border: `solid 2px ${theme.palette.MDCColors.color23}`,
      padding: ".4rem 1rem",
      marginTop: ".625rem",
      "&:hover": {
        borderColor: theme.palette.MDCColors.color23,
      },
      background: theme.palette.MDCColors.color23,
      marginRight: ".125rem",
      transition: "all 0.3s ease",
      "&:hover": {
        background: theme.palette.MDCColors.color33,
        border: `solid 2px ${theme.palette.MDCColors.color23}`,
      },
      color: theme.palette.MDCColors.black,
      "&:not(:disabled):hover": {
        color: theme.palette.MDCColors.black,
      },
      "&:disabled": {
        pointerEvents: "unset",
        cursor: "not-allowed",
        border: `solid 2px ${theme.palette.MDCColors.color18}`,
        "&:hover": {
          borderColor: theme.palette.MDCColors.color18,
        },
        color: theme.palette.MDCColors.color18,
        opacity: 1,
        background: theme.palette.MDCColors.color21,
        transition: "all 0.3s ease",
        "&:hover": {
          background: theme.palette.MDCColors.color21,
        },
      },
    },
    "&.MuiButton-outlined": {
      fontFamily: theme.palette.typography.fontFamily,
      fontSize: "1.125rem",
      textTransform: "initial",
      borderRadius: "5px",
      border: `solid 2px ${theme.palette.MDCColors.color23}`,
      marginRight: ".5rem",
      padding: ".4rem 1rem",
      marginTop: (props) => (props?.removeMarginTop ? "0" : ".625rem"),
      "&:hover": {
        borderColor: theme.palette.MDCColors.color23,
      },
      background: theme.palette.MDCColors.white,
      transition: "all 0.3s ease",
      "&:hover": {
        background: theme.palette.MDCColors.color33,
        border: `solid 2px ${theme.palette.MDCColors.color23}`,
      },
      color: theme.palette.MDCColors.black,
      "&:not(:disabled):hover": {
        color: theme.palette.MDCColors.black,
      },
      "&:disabled": {
        pointerEvents: "unset",
        cursor: "not-allowed",
        border: `solid 2px ${theme.palette.MDCColors.color18}`,
        "&:hover": {
          borderColor: theme.palette.MDCColors.color18,
        },
        color: theme.palette.MDCColors.color18,
        opacity: 1,
        background: theme.palette.MDCColors.color21,
        transition: "all 0.3s ease",
        "&:hover": {
          background: theme.palette.MDCColors.color21,
        },
      },
    },
    "&.MuiButton-text": {
      fontFamily: theme.palette.typography.fontFamily,
      fontSize: "1.125rem",
      textTransform: "initial",
      color: theme.palette.MDCColors.black,
      opacity: 1,
      // borderLeft: `2px solid ${theme.palette.MDCColors.color21}`,
      padding: "0.75rem",
      fontWeight: 400,
      "&:not(:disabled):hover": {
        color: theme.palette.MDCColors.black,
        backgroundColor: theme.palette.MDCColors.color35,
      },
      "&:disabled": {
        pointerEvents: "unset",
        cursor: "not-allowed",
      },
    },
  },
}));

const MDCButton = (props) => {
  const { label, onClick, disabled, variant, endIcon, className, startIcon } =
    props;

  const { button } = useStyles(props);

  return (
    <Button
      variant={variant}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      className={[button, className].join(" ")}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

MDCButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disbled: PropTypes.bool,
};

export default MDCButton;
