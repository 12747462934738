import React from "react";

// Assets
import iconProfileSmall from "../../assets/images/icon_profil_small.svg";
import iconQESCompletionGrey from "../../assets/images/icon_vollmacht_grey.svg";

//constants
import { VOLLMACHT_TOOLTIP_MESSAGES } from "../../constants/ManualRequestFormConstants";

import { makeStyles } from "@mui/styles";

// Core Components
import MDCTooltip from "./MDCTooltip";

const useStyles = makeStyles({
  vollmachtIconCSS: {
    marginLeft: ".5rem",
    marginTop: "-7px"
  },
});

const VollmachtsRelevantIcon = (props) => {
  const { vollmachtIconCSS } = useStyles();

  return (
    <>
      <MDCTooltip
        title={
          props.isQESCompleted
            ? VOLLMACHT_TOOLTIP_MESSAGES.QES_COMPLETED_MESSAGE
            : VOLLMACHT_TOOLTIP_MESSAGES.VOLLMACHTRELEVANT
        }
      >
        <img
          className={vollmachtIconCSS}
          src={props.isQESCompleted ? iconQESCompletionGrey : iconProfileSmall}
          alt="Vollmachtsrelevant"
        />
      </MDCTooltip>
    </>
  );
};

export default VollmachtsRelevantIcon;
