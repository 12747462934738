import React from "react";
import { connect } from "react-redux";
import carLogo from "../../assets/images/my_digital_car.svg";
import EinreichenSubmit from "./EinreichenSubmit";
import { history } from "../../helpers/history";
import MDCModal from "../../components/core/MDCModal";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import theme from "../../themes/mdc-theme";

const styles = () => ({
  headerCss: {
    padding: "2rem",
    alignItems: "center",
  },
  headerColor: { color: theme.palette.MDCColors.black },
  contentCss: {
    fontFamily: theme.palette.typography.fontFamily,
    color: theme.palette.MDCColors.color15,
    padding: "0 2rem",
  },
});
class PopupDialog extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      displayPopup: false,
    };
  }

  componentDidMount() {
    this.setState({ displayPopup: this.props.show });
  }

  handlePopupClose = (value) => {
    if (value) {
      this.props?.handleDialogClose && this.props.handleDialogClose(true); // To close detail view after registration api response
      window.location.pathname === "/manual-request" && history.push("/"); // To traverse to home page after registration api response in manual entry page
    } else {
      this.props.handlePopupClose();
    }
  };

  render() {
    const { popUpHeading, classes } = this.props;
    const getModalHeader = () => {
      return (
        <Grid container className={classes.headerCss}>
          <Grid item xs={8} className={classes.headerColor}>
            <h2> {popUpHeading}</h2>
          </Grid>
          <Grid item xs={4}>
            <img alt="My Digital Car" src={carLogo} width="95%" />
          </Grid>
        </Grid>
      );
    };
    const getModalBody = () => {
      return (
        <div className={classes.contentCss}>
          <EinreichenSubmit
            certificateDetails={this.props.certificateDetails}
            requestIds={this.props.requestIds}
            handleLoader={(value) => {
              this.props.handleLoader(value);
            }}
            handlePopupClose={(isCloseDetailView) =>
              this.handlePopupClose(isCloseDetailView)
            }
            totalSelectedRecords={this.props?.totalSelectedRecords}
            selectedRecordsInStatusNeu={this.props?.selectedRecordsInStatusNeu}
            isFromME={this.props?.isFromME}
            isFromDetailView={this.props?.isFromDetailView}
          />
        </div>
      );
    };
    return (
      <div>
        <MDCModal
          open={this.props.show}
          title={getModalHeader()}
          body={getModalBody()}
          size="sm"
        />
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PopupDialog));
