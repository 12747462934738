import { userConstants } from "../../constants/userConstants";

let user =
  localStorage.getItem("user") !== "undefined"
    ? JSON.parse(localStorage.getItem("user"))
    : undefined;
const initialState = user ? { loggedIn: true, user, redirectUrl: "" } : {};

export default (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };

    case userConstants.LOGOUT_SUCCESS:
      return {
        redirectUrl: action?.logout?.headers?.location,
      };

    case userConstants.USER_UPDATE:
      return Object.assign({}, state, {
        user: action.user,
      });
    case userConstants.COMPANY_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        companyDetails: action.resp.data,
      });
    case userConstants.VERIMI_REGISTER_SUCCESS:
      return Object.assign({}, state, {
        registerDetails: action.responseData,
      });
    case userConstants.GET_PRODUCT_VERSION_SUCCESS:
      return Object.assign({}, state, {
        productVersion: action.responseData,
      });
    case userConstants.GET_EXCEL_TEMPALTE_SUCCESS:
      return Object.assign({}, state, {
        excelTempalte: action.resp,
      });
    case userConstants.GET_TEMPALTE_PDF_SUCCESS:
      return Object.assign({}, state, {
        tempaltePDF: action.resp,
      });

    default:
      return state;
  }
};
