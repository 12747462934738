import React from "react";
import { connect } from "react-redux";

// Material UI components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

// Actions
import { userActions } from "../../../store/actions/userActions";
import theme from "../../../themes/mdc-theme";

class Footer extends React.Component {
  componentDidMount = () => {
    this.props.getProductVersion();
  };

  render() {
    return (
      <Container maxWidth="xl">
        <Grid container color={theme.palette.MDCColors.white} paddingTop="2rem">
          <p>Version {this.props.productVersion}</p>
        </Grid>
      </Container>
    );
  }
}

function mapState(state) {
  return {
    productVersion: state.authentication.productVersion,
  };
}

const actionCreators = {
  getProductVersion: userActions.getProductVersion,
};

export default connect(mapState, actionCreators)(Footer);
