import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { statusMessagesColorScheme } from "../../helpers/utils";
import MDCDividerLine from "./MDCDividerLine";
import Link from "@mui/material/Link";

const useStyles = makeStyles({
  statusMessagesCss: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "1.25rem 0",
    opacity: ".9",
  },
});

const MDCGroupNotification = (props) => {
  const { statusType, statusMessages, showMessageDetailDialog } = props;
  const { statusMessagesCss } = useStyles();
  return (
    <>
      {statusType && statusMessages?.length > 0 && (
        <Grid
          container
          backgroundColor={statusMessagesColorScheme[statusType]?.color}
          color={statusMessagesColorScheme[statusType]?.textColor}
          className={statusMessagesCss}
        >
          {/**Left Icon*/}
          <Grid item xs={1.5}>
            {statusMessagesColorScheme[statusType]?.icon}
          </Grid>

          {/**Notification messages for Whole Group*/}
          <Grid item xs={6.5}>
            {statusMessages?.map((message, index) => {
              const messagesLength = statusMessages?.length;
              return (
                <div key={index}>
                  {message?.title + " "}
                  {message?.description && (
                    <Link
                      component="button"
                      color="inherit"
                      variant="body1"
                      onClick={() =>
                        showMessageDetailDialog(message?.description)
                      }
                    >
                      Details
                    </Link>
                  )}

                  {index !== messagesLength - 1 && messagesLength > 1 && (
                    <MDCDividerLine
                      color={statusMessagesColorScheme[statusType].textColor}
                    />
                  )}
                </div>
              );
            })}
          </Grid>

          {/**Right Icon */}
          <Grid item xs={1.5}>
            {statusMessagesColorScheme[statusType].icon}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default MDCGroupNotification;
