import React from "react";
import { connect } from "react-redux";
import { ConfirmationPopupConstants } from "../../constants/PopupDialogConstants";
import { vorgangeDetailsActions } from "../../store/actions/vorgange/vorgangeDetailsActions";
//Core Components
import MDCButton from "../core/MDCButton";
import MDCBanner from "../core/MDCBanner";

// Helpers
import { history } from "../../helpers/history";

// Material UI
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import theme from "../../themes/mdc-theme";
import EinreichenLogo from "../../assets/images/Icon_Einreichen.svg";
import errorMessageLogo from "../../assets/images/icon_Fehler.svg";

const styles = () => ({
  schrittDiv: {
    display: "flex",
    flexDirection: "column !important",
    justifyContent: "space-between",
    color: theme.palette.MDCColors.color30,
    maxHeight: "186px",
  },
  stepper: {
    display: "flex",
    flexDirection: "column !important",
    justifyContent: "center",
    alignItems: "center",
    height: "1.5rem",
    width: "1.5rem",
    backgroundColor: theme.palette.MDCColors.color30,
    borderRadius: "50%",
    padding: "5px 0",
    fontSize: "1.5rem",
    color: theme.palette.MDCColors.white,
  },
  verticalLine: {
    borderLeft: `2px dotted ${theme.palette.MDCColors.color23}`,
    height: "146px",
  },
  width100: {
    width: "100%",
  },
  errorCss: {
    border: "2px solid #ec5867",
    marginX: "0.5rem",
    boxShadow: "0px 3px 0.938rem #5c5b5b",
    padding: "2px 5px",
    borderRadius: 5,
    lineHeight: "normal",
  },
  infoMessage: {
    margin: "1.5rem auto",
  },
});
class EinreichenSubmit extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial values
    this.state = {
      userSelection: "KDO",
    };
  }

  handleChange(event) {
    this.setState({
      userSelection: event.target.value,
    });
  }

  handleClose = (isRegisteringRequest) => {
    if (isRegisteringRequest) {
      this.handleRegistration();
    }
    this.props.handlePopupClose();
  };

  handleRegistration = () => {
    this.props.handleLoader(true);

    this.props.handleRegistrationGKS(this.props.requestIds).then((response) => {
      if (!response) {
        history.push("/");
      }

      this.props.handleLoader(false);
      this.props.handlePopupClose(true); // Sending true flag to close detail view/manual entry after gks registration api response
    });
  };

  displayError = (errorMessage) => {
    return (
      <Grid
        container
        className={this.props.classes.errorCss}
        justifyContent={"left"}
        flexDirection={"row"}
        padding={"0.5rem"}
      >
        <Grid item paddingLeft={"0.5rem"} alignSelf={"center"} xs={1}>
          <img src={errorMessageLogo} />
        </Grid>
        <Grid item xs={11}>
          <span>{errorMessage}</span>
        </Grid>
      </Grid>
    );
  };

  render() {
    const selectedRecordsInStatusNeu = this.props?.selectedRecordsInStatusNeu;
    return (
      <>
        <Grid container>
          <Grid container justifyContent="center">
            <img src={EinreichenLogo} />
          </Grid>
          {!(this.props?.isFromME || this.props?.isFromDetailView) && (
            <Grid
              container
              justifyContent="center"
              className={this.props.classes.infoMessage}
            >
              <MDCBanner
                message={` ${selectedRecordsInStatusNeu} der ${
                  this.props?.totalSelectedRecords
                } ausgewählten Vorgänge werden weitergeleitet. ${
                  selectedRecordsInStatusNeu <= 1
                    ? "Vorgang befindet"
                    : "Vorgänge befinden"
                } sich nicht im Versandfertig-Status und werden übersprungen.`}
                isInfoIcon
              />
            </Grid>
          )}
          {!this.props?.certificateDetails && (
            <Grid container marginTop="2rem">
              {this.displayError(
                ConfirmationPopupConstants.CERTIFICATE_DETAIL_ERROR
              )}
            </Grid>
          )}

          <Grid container marginTop="2rem">
            <p>{ConfirmationPopupConstants.CONTENT_PART1}</p>
            <p className={this.props?.classes?.width100}>
              {ConfirmationPopupConstants.CONTENT_PART2}
            </p>
            <p>{ConfirmationPopupConstants.CONTENT_PART3}</p>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          marginBottom={"3rem"}
          marginTop={"1rem"}
        >
          <MDCButton
            variant="outlined"
            label={"Schließen"}
            onClick={() => this.handleClose()}
          />
          <MDCButton
            disabled={
              !this.props?.certificateDetails ||
              selectedRecordsInStatusNeu === 0
            }
            variant="contained"
            label={"Bestätigen"}
            onClick={() => this.handleClose(true)}
          />
        </Grid>
      </>
    );
  }
}
function mapStateToProps() {
  return {};
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleRegistrationGKS: (ids) =>
      dispatch(vorgangeDetailsActions.handleRegistrationGKS(ids)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EinreichenSubmit));
