// Service
import { manualRequestFormService } from "../../services/vorgange/manualRequestFormService";

// Constants
import { manualRequestFormConstants } from "../../../constants/ManualRequestFormConstants";
import {
  HTTP_SUCCESS_STATUS_CODES,
  TEIL1_ENDPOINT,
} from "../../../constants/VorgangDetailsConstants";

import { toast } from "react-toastify";
import NotificationMessage from "../../../components/core/NotificationMessage";

function createManualEntrySuccess(manualRequestFormDetails) {
  return {
    type: manualRequestFormConstants.CREATE_MANUAL_ENTRY_SUCCESS,
    manualRequestFormDetails,
  };
}

function createManualEntry(data, requestId, createNewRequest) {
  return (dispatch) => {
    return new Promise((resolve) => {
      return manualRequestFormService
        .createManualEntry(data, requestId, createNewRequest)
        .then((manualRequestFormDetails) => {
          dispatch(createManualEntrySuccess(manualRequestFormDetails));
          if (
            HTTP_SUCCESS_STATUS_CODES?.includes(
              manualRequestFormDetails?.status
            ) &&
            manualRequestFormDetails.data
          ) {
            const { message, primaryReference, requestId } =
              manualRequestFormDetails.data;
            toast.info(
              <NotificationMessage
                message={message}
                primaryReference={primaryReference}
                requestId={requestId}
              />
            );
          }
          resolve(manualRequestFormDetails);
        });
    });
  };
}

function vollmachtTabValidationSuccess(resp) {
  return {
    type: manualRequestFormConstants.VOLLMACHT_TAB_VALIDATION_SUCCESS,
    resp,
  };
}

function vollmachtTabValidation(requestId) {
  return (dispatch) => {
    return manualRequestFormService
      .vollmachtTabValidation(requestId)
      .then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
          dispatch(vollmachtTabValidationSuccess(resp));
        }
      });
  };
}

function getQRCodeSuccess(resp) {
  return {
    type: manualRequestFormConstants.VOLLMACHT_TAB_QRCODE_SUCCESS,
    resp,
  };
}

function getQRCode(requestId, QRCodeStatus) {
  return (dispatch) => {
    return manualRequestFormService
      .getQRCode(requestId, QRCodeStatus)
      .then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
          dispatch(getQRCodeSuccess(resp));
        }
      });
  };
}

function getVollmachtSigningStatusSuccess(resp) {
  return {
    type: manualRequestFormConstants.VOLLMACHT_SIGNING_STATUS_SUCCESS,
    resp,
  };
}

function getVollmachtSigningStatus(requestId) {
  return (dispatch) => {
    return manualRequestFormService
      .getVollmachtSigningStatus(requestId)
      .then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
          dispatch(getVollmachtSigningStatusSuccess(resp));
        }
      });
  };
}

function getVollmachtSignedSEPADocumentSuccess(resp) {
  return {
    type: manualRequestFormConstants.VOLLMACHT_SIGNED_SEPA_SUCCESS,
    resp,
  };
}

function getVollmachtSignedPOADocumentSuccess(resp) {
  return {
    type: manualRequestFormConstants.VOLLMACHT_SIGNED_POA_SUCCESS,
    resp,
  };
}

function getZBTeil1DocumentsSuccess(resp) {
  return {
    type: manualRequestFormConstants.ZB_TEIL1_DOC_SUCCESS,
    resp,
  };
}

function getZBTeil2DocumentsSuccess(resp) {
  return {
    type: manualRequestFormConstants.ZB_TEIL2_DOC_SUCCESS,
    resp,
  };
}

function getVollmachtSignedDocuments(requestId, docName) {
  return (dispatch) => {
    return manualRequestFormService
      .getVollmachtSignedDocuments(requestId, docName)
      .then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
          if (docName === "sepa") {
            dispatch(getVollmachtSignedSEPADocumentSuccess(resp));
          } else {
            dispatch(getVollmachtSignedPOADocumentSuccess(resp));
          }
        }
      });
  };
}
function getZulassungsbescheinigungDocuments(requestId, docName) {
  return (dispatch) => {
    return manualRequestFormService
      .getZulassungsbescheinigungDocuments(requestId, docName)
      .then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
          if (docName === TEIL1_ENDPOINT) {
            dispatch(getZBTeil1DocumentsSuccess(resp));
            return resp;
          } else {
            dispatch(getZBTeil2DocumentsSuccess(resp));
            return resp;
          }
        }
      });
  };
}

function resetVollmachtSignedData() {
  return (dispatch) => {
    dispatch({
      type: manualRequestFormConstants.VOLLMACHT_SIGNED_PROCESS_RESET,
      resp: {
        poaDocument: null,
        sepaDocument: null,
        vollmachtSigningStatus: null,
      },
    });
  };
}

function deleteQESDetailsSuccess(resp) {
  return {
    type: manualRequestFormConstants.VOLLMACHT_LOSCHEN_SUCCESS,
    resp,
  };
}

function deleteQESDetails(requestId) {
  return (dispatch) => {
    return manualRequestFormService.deleteQES(requestId).then((resp) => {
      if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
        dispatch(deleteQESDetailsSuccess(resp));
      }
    });
  };
}

function getCountriesList() {
  return () => {
    return manualRequestFormService.getCountriesList().then((resp) => {
      if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
        return resp;
      }
    });
  };
}

function downloadWirtschaftsPDFSuccess(resp) {
  return {
    type: manualRequestFormConstants.DOWNLOAD_WIRTSCHAFTS_PDF_SUCCESS,
    resp,
  };
}

function downloadWirtschaftsPDF() {
  return (dispatch) => {
    return manualRequestFormService.downloadWirtschaftsPDF().then((resp) => {
      if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
        dispatch(downloadWirtschaftsPDFSuccess(resp));
      }
    });
  };
}

function getWirtschaftszweigDataSuccess(resp) {
  return {
    type: manualRequestFormConstants.GET_WIRTSCHAFTS_DATA_SUCCESS,
    resp,
  };
}

function getWirtschaftszweigData() {
  return (dispatch) => {
    return manualRequestFormService.getWirtschaftszweigData().then((resp) => {
      if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
        dispatch(getWirtschaftszweigDataSuccess(resp?.data));
      }
    });
  };
}

function getSteuerbefreiungDataSuccess(resp) {
  return {
    type: manualRequestFormConstants.GET_STEUERBEFREIUNG_DATA_SUCCESS,
    resp,
  };
}

function getSteuerbefreiungData() {
  return (dispatch) => {
    return manualRequestFormService.getSteuerbefreiungData().then((resp) => {
      if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
        dispatch(getSteuerbefreiungDataSuccess(resp?.data));
      }
    });
  };
}

export const manualRequestFormActions = {
  // Added for unit testcases starts
  createManualEntrySuccess,
  vollmachtTabValidationSuccess,
  getQRCodeSuccess,
  getVollmachtSigningStatusSuccess,
  getVollmachtSignedSEPADocumentSuccess,
  getVollmachtSignedPOADocumentSuccess,
  getZBTeil1DocumentsSuccess,
  getZBTeil2DocumentsSuccess,
  deleteQESDetailsSuccess,
  downloadWirtschaftsPDFSuccess,
  getWirtschaftszweigDataSuccess,
  getSteuerbefreiungDataSuccess,
  // Added for unit testcases Ends

  createManualEntry,
  vollmachtTabValidation,
  getQRCode,
  getVollmachtSigningStatus,
  getVollmachtSignedDocuments,
  getZulassungsbescheinigungDocuments,
  resetVollmachtSignedData,
  deleteQESDetails,
  getCountriesList,
  downloadWirtschaftsPDF,
  getWirtschaftszweigData,
  getSteuerbefreiungData,
};
