import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";

// Core Components
import MDCTooltip from "./MDCTooltip";

const useStyles = makeStyles((theme) => ({
  defaultDropDownCSS: {
    "& .MuiListSubheader-root": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      color: "#000 !important",
      fontFamily: theme.palette.typography.fontFamily,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.MDCColors.color23,
    },
    marginTop: "1rem",
    "& .MuiOutlinedInput-root": {
      padding: "4px !important",
      color: theme.palette.MDCColors.color18,
      foneWeight: "initial !important",
    },
    "& .MuiAutocomplete-root": {
      border: `2px solid ${theme.palette.MDCColors.color23}`,
      borderRadius: "5px",
      background: theme.palette.MDCColors.color21,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  errorText: {
    "& .MuiAutocomplete-root": {
      border: `2px solid ${theme.palette.MDCColors.errorDanger}`,
      background: "rgba(236, 88, 103, 0.3)",
    },
  },
  disabledMenu: {
    "& .MuiAutocomplete-root": {
      border: "0px",
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.MDCColors.color18,
      opacity: 0.5,
    },
  },
  subheaderCSS: {
    fontFamily: theme.palette.typography.fontFamily,
    color: "#5F5F5F !important",
  },
}));

const MDCGroupedDropdown = (props) => {
  const { options, value, onChange, error, disabled, placeholder } = props;

  const { defaultDropDownCSS, errorText, disabledMenu, subheaderCSS } =
    useStyles();

  let MDCStyles = defaultDropDownCSS;
  if (error) {
    MDCStyles = `${MDCStyles} ${errorText}`;
  }

  if (disabled) {
    MDCStyles = `${MDCStyles} ${disabledMenu}`;
  }

  const onValueChange = (newValue) => {
    //providing the empty object value in case of null selected option to make it React controlled component(P.S.-To prevent uncontrolled component warning in Console)
    onChange(newValue === null ? "" : newValue?.id);
  };

  return (
    <>
      <div className={MDCStyles}>
        <Autocomplete
          value={value}
          disablePortal
          options={
            options?.sort((a, b) => -b.header.localeCompare(a.header)) || []
          }
          groupBy={(option) => option?.header}
          getOptionLabel={(option) => option?.subHeader}
          renderInput={(params) => (
            <TextField {...params} placeholder={placeholder} />
          )}
          disabled={disabled}
          onChange={(e, newValue) => onValueChange(newValue)}
          noOptionsText={"Ihre Suche ergab keinen Treffer."}
          renderOption={(props, option) => (
            <div className={subheaderCSS}>
              <MDCTooltip
                key={option?.id}
                title={
                  <a>
                    <h8>{option?.descriptionHeader}</h8>
                    <p className={subheaderCSS}>{option?.description}</p>
                  </a>
                }
                placement={"right"}
              >
                <span {...props}>{option?.subHeader}</span>
              </MDCTooltip>
            </div>
          )}
        />
      </div>
    </>
  );
};

export default MDCGroupedDropdown;
