import { NOTIFICATION_CONSTANTS } from "../../../constants/notificationConstants";

const initialState = {};

const notificationReducer = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case NOTIFICATION_CONSTANTS.GET_TOKEN_FOR_WEBSOCKET_SUCCESS:
      return Object.assign({}, { data: action.resp });
    default:
      return state;
  }
};
export default notificationReducer;
