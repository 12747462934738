// Service
import { OrderLicensePlateService } from "../../services/vorgange/OrderLicensePlateService";

// Constants
import { HTTP_SUCCESS_STATUS_CODES } from "../../../constants/VorgangDetailsConstants";

import { orderLicensePlateConstants } from "../../../constants/LicensePlateConstants";

function getOrderLicensePlateSuccess(resp) {
  return {
    type: orderLicensePlateConstants.GET_ORDER_LICENSE_PLATE_SUCCESS,
    resp,
  };
}

function getOrderLicensePlate(requestId) {
  return async (dispatch) => {
    const resp = await OrderLicensePlateService.getOrderLicensePlate(requestId);
    if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
      dispatch(getOrderLicensePlateSuccess(resp));
    }
  };
}

function setCreateOrderLicensePlateRequest(data) {
  return {
    type: orderLicensePlateConstants.CREATE_ORDER_LICENSE_PLATE_REQUEST_DATA,
    orderLicensePlateFormData: data,
  };
}

function createOrderLicensePlateSuccess(orderLicensePlateFormDetails) {
  return {
    type: orderLicensePlateConstants.CREATE_ORDER_LICENSE_PLATE_SUCCESS,
    orderLicensePlateFormDetails,
  };
}

function createOrderLicensePlate(data, requestId) {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      const orderLicensePlateFormDetails = await OrderLicensePlateService.createOrderLicensePlate(
        data,
        requestId
      );
      await dispatch(createOrderLicensePlateSuccess(orderLicensePlateFormDetails));
      await dispatch(getOrderLicensePlate(requestId));
      resolve(orderLicensePlateFormDetails);
    });
  };
}

function deleteOrderLicensePlateSuccess(resp) {
  return {
    type: orderLicensePlateConstants.DELETE_ORDER_LICENSE_PLATE_SUCCESS,
    resp,
  };
}

function deleteOrderLicensePlate(requestId, orderId) {
  return async (dispatch) => {
    const resp = await OrderLicensePlateService.deleteOrderLicensePlate(
      requestId, orderId
    );
    if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
      dispatch(deleteOrderLicensePlateSuccess(resp));
      dispatch(getOrderLicensePlate(requestId));
    }
  };
}

export const OrderLicensePlateActions = {
  // Added for unit testcases starts
  setCreateOrderLicensePlateRequest,
  createOrderLicensePlateSuccess,
  getOrderLicensePlateSuccess,
  deleteOrderLicensePlateSuccess,
  // Added for unit testcases Ends

  createOrderLicensePlate,
  getOrderLicensePlate,
  deleteOrderLicensePlate,
};
