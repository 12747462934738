import React from "react";

import Tooltip from "@mui/material/Tooltip";

// Theme
import theme from "../../themes/mdc-theme";

const MDCTooltip = ({ title, children, showWhiteSpace, placement }) => {
  return (
    <Tooltip
      placement={placement || "top"}
      title={title || ""}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            padding: "10px",
            color: "#000",
            bgcolor: "#FFF",
            boxShadow: `0rem 0.188rem 0.375rem ${theme.palette.MDCColors.color18}`,
            "& .MuiTooltip-arrow": {
              color: "#FFF",
            },
            whiteSpace: showWhiteSpace ? "pre" : "",
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default MDCTooltip;
