import React from "react";
import { Grid } from "@mui/material";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      // Error fallback UI for any component
      return (
        <Grid
          container
          color="white"
          justifyContent={"center"}
          alignContent={"center"}
          height={"60vh"}
        >
          <h2>Please Connect With MDC Admin...</h2>

          {/* This below <details/> component is used by developer for tracing of error or any undefined issues coming from which component */}

          {/* <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details> */}
        </Grid>
      );
    }

    // Normally, just render children/component
    return this.props.children;
  }
}
