import { makeStyles } from "@mui/styles";
import React from "react";

import {
  alertCSS,
  alertInfoCSS,
  alertErrorCSS,
  alertSuccessCSS,
  alertDuplicateCSS,
  alertWithInfoIconCSS,
} from "../shared/AlertCSS";

const useStyles = makeStyles(() => ({
  alert: alertCSS,
  alertInfo: alertInfoCSS,
  alertError: alertErrorCSS,
  alertSuccess: alertSuccessCSS,
  alertDuplicate: alertDuplicateCSS,
  alertWithInfoIcon: alertWithInfoIconCSS,
}));

export default function MDCBanner({
  message,
  error,
  success,
  isDuplicate,
  isInfoIcon,
}) {
  const {
    alert,
    alertInfo,
    alertSuccess,
    alertError,
    alertDuplicate,
    alertWithInfoIcon,
  } = useStyles();

  let MDCStyles = `${alert}`;

  if (error) {
    MDCStyles = `${MDCStyles} ${alertError}`;
  } else if (success) {
    MDCStyles = `${MDCStyles} ${alertSuccess}`;
  } else if (isDuplicate) {
    MDCStyles = `${MDCStyles} ${alertDuplicate}`;
  } else if (isInfoIcon) {
    MDCStyles = `${MDCStyles} ${alertWithInfoIcon}`;
  } else {
    MDCStyles = `${MDCStyles} ${alertInfo}`;
  }

  return (
    <div className={`${MDCStyles}`}>
      <span>{message}</span>
    </div>
  );
}
