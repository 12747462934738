import Container from "@mui/material/Container";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Helper
import { history } from "../../helpers/history";

// Icons
import backArrow from "../../assets/images/icon_left_arrow.svg";

// Material UI
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

// Inner Components
import POARecordQES from "./POARecordQES";
import MDCLoader from "../core/MDCLoader";

// Actions
import { VollmachtsDatenBankDataActions } from "../../store/actions/userProfile/vollmachtsDatenBankAction";

const useStyles = makeStyles((theme) => ({
  backArrow: {
    width: "1.5rem",
    height: "1.25rem",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundImage: `url(${backArrow})`,
    marginBottom: "2rem",
    cursor: "pointer",
    marginLeft: "0.5rem",
    marginTop: "1.5rem",
  },
  profilLabel: {
    fontSize: "2.5rem",
    color: theme.palette.MDCColors.white,
    marginBottom: "2rem",
  },
  formBackground: {
    padding: "2%",
    background: `${theme.palette.MDCColors.white} 0% 0% no-repeat padding-box`,
    boxShadow: `0px 3px 6px ${theme.palette.MDCColors.color18}`,
    borderRadius: "5px",
  },
  showContentCenter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    "-webkit-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
}));

const VollmachtsDatenBankQESProcess = (props) => {
  const classes = useStyles();

  const poaId = props?.location?.state?.poaId;

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(
      VollmachtsDatenBankDataActions.getVollmachtBankSigningStatus(poaId)
    ).then(() => setLoading(false));
  }, []);

  return (
    <Container maxWidth="xl">
      {loading && (
        <div className={classes.showContentCenter}>
          <MDCLoader />
        </div>
      )}
      {!loading && (
        <Grid>
          <div className={classes.backArrow} onClick={() => history.goBack()} />
          <label className={classes.profilLabel}>Vollmachtsdatenbank</label>
          <Grid container className={classes.formBackground}>
            <POARecordQES {...props} />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default VollmachtsDatenBankQESProcess;
