import React from "react";

// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";

// Material UI
import { makeStyles } from "@mui/styles";
import { Select, MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  defaultMenu: {
    "& .MuiSelect-selectMenu": {
      background: theme.palette.MDCColors.color21,
      border: `2px solid ${theme.palette.MDCColors.color23}`,
      color: theme.palette.MDCColors.color18,
      fontFamily: theme.palette.typography.fontFamily,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      marginTop: "0.625rem",
      fontSize: "1rem",
      fontFamily: theme.palette.typography.fontFamily,
      padding: (props) =>
        props.isSmall
          ? "0.65rem 0rem 0rem 0.938rem"
          : "0.938rem 0.938rem 0rem 0.938rem",
      height: (props) =>
        props.isSmall
          ? "calc(1rem + 0.01rem + 0.625rem)"
          : "calc(1rem + 0.172rem + 0.625rem)",
      borderRadius: "5px",
      "&:focus": {
        backgroundColor: theme.palette.MDCColors.color21,
        borderRadius: "5px",
      },
    },
    "& .MuiSelect-icon": {
      top: "calc(50% - 0.625rem)",
      color: theme.palette.MDCColors.color23,
    },
  },
  filterMenu: {
    "& .MuiSelect-selectMenu": {
      background: theme.palette.MDCColors.white,
      border: "0px",
      borderRadius: "0rem",
      padding: "0.625rem 0.625rem 0rem 0.938rem !important",
      height: "calc(1rem + 0.0rem + 0.625rem) !important",
      marginTop: "0rem",
      "&:focus": {
        backgroundColor: theme.palette.MDCColors.white,
        borderRadius: "0px",
      },
    },
    "& .MuiSelect-icon": {
      color: theme.palette.MDCColors.color23,
    },
  },
  disabledMenu: {
    "& .MuiSelect-selectMenu": {
      border: "0px",
      height: (props) => props.isSmall && "calc(1rem + 0.0172rem + 0.625rem)",
      padding: (props) => props.isSmall && "0.983rem 0 0rem 0.938rem",
    },
    "& .MuiSelect-icon": {
      color: theme.palette.MDCColors.disabledColor,
    },
  },
  errorMenu: {
    "& .MuiSelect-selectMenu": {
      border: `2px solid ${theme.palette.MDCColors.errorDanger}`,
      background: "rgba(236, 88, 103, 0.3)",
      "&:focus": {
        backgroundColor: "rgba(236, 88, 103, 0.3)",
      },
    },
    "& .MuiSelect-icon": {
      color: theme.palette.MDCColors.textColor,
    },
  },
  menuItemCSS: {
    fontFamily: theme.palette.typography.fontFamily,
  },
}));

const MDCSelect = (props) => {
  const {
    value,
    handleChange,
    options,
    name,
    disabled,
    isfilterMenu,
    error,
    placeholder,
    disableEmpty,
  } = props;

  const { defaultMenu, filterMenu, disabledMenu, errorMenu, menuItemCSS } =
    useStyles(props);

  let MDCStyles = defaultMenu;
  if (isfilterMenu) {
    MDCStyles = `${MDCStyles} ${filterMenu}`;
  }
  if (disabled) {
    MDCStyles = `${MDCStyles} ${disabledMenu}`;
  }
  if (error) {
    MDCStyles = `${MDCStyles} ${errorMenu}`;
  }

  return (
    <>
      <Select
        name={name}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        displayEmpty
        fullWidth
        className={MDCStyles}
        disableUnderline
      >
        {placeholder && (
          <MenuItem disabled={disableEmpty} value="" className={menuItemCSS}>
            <div>{placeholder}</div>
          </MenuItem>
        )}
        {options?.map((option, index) => (
          <MenuItem key={index} value={option?.value} className={menuItemCSS}>
            {option?.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default MDCSelect;
