import React from "react";

// Assets
import InfoIcon from "../iconComponents/InfoIcon";

import { makeStyles } from "@mui/styles";

// Core Components
import MDCTooltip from "./MDCTooltip";

const useStyles = makeStyles({
  vollmachtIconCSS: {
    marginLeft: ".5rem",
    marginBottom: ".2rem",
  },
});

const MDCInfoIcon = (props) => {
  const { vollmachtIconCSS } = useStyles();

  return (
    <>
      <MDCTooltip title={props.title}>
        <span className={vollmachtIconCSS}>
          <InfoIcon />
        </span>
      </MDCTooltip>
    </>
  );
};

export default MDCInfoIcon;
