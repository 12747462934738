import React from "react";
import {
  ABLAGE,
  ABLAGE_HEADING,
  ABLAGE_CONTENT,
  ERROR,
  ERROR_CONTENT,
  ERROR_CONTENT_MANDATORY,
  ABORT,
  ABORT_HEADING,
  ABORT_CONTENT,
  ADMIN_INFO_COPY_ABORT,
  ADMIN_INFO_COPY_HEADING,
  ADMIN_INFO_COPY_CONTENT,
  QR_CODE,
  QR_CODE_HEADING,
  QR_CODE_CONTENT_FIRST,
  QR_CODE_CONTENT_SECOND,
} from "../../constants/messageConstants";
//Core Components
import MDCButton from "../core/MDCButton";
import MDCModal from "../core/MDCModal";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
//Images
import EinreichenLogo from "../../assets/images/Nachricht_absenden.svg";
import AblageLogo from "../../assets/images/Nachricht_versendet.svg";
import ErrorLogo from "../../assets/images/Fehlermeldung.svg";

const MessageDialog = (props) => {
  const useStyles = makeStyles((theme) => ({
    msgContent: {
      fontSize: "1rem",
      fontFamily: theme.palette.typography.fontFamily,
      color: theme.palette.MDCColors.color18,
    },
    headingCss: {
      fontSize: "1.125rem",
      fontWeight: "bold",
      color: theme.palette.MDCColors.textColor,
      marginTop: "1rem",
    },
    contentCss: {
      marginTop: "1rem",
      marginBottom: "0.625rem",
      textAlign: "left",
    },
    footerButtonsGrid: {
      textAlign: "right",
      padding: "1rem",
    },
    einreichenMessageLogo: {
      background: `url(${EinreichenLogo})`,
      backgroundSize: "40% !important",
    },
    ablageMessageLogo: Object.assign({
      background: `url(${AblageLogo})`,
    }),
    errorMessageLogo: Object.assign({
      background: `url(${ErrorLogo})`,
      backgroundSize: "34% !important",
    }),
    LogoCss: {
      height: "20vh",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "60%",
    },
  }));

  const {
    msgContent,
    headingCss,
    contentCss,
    footerButtonsGrid,
    LogoCss,
    einreichenMessageLogo,
    ablageMessageLogo,
    errorMessageLogo,
  } = useStyles(); //getting css styles to be used as className

  const handleClose = (userConfirmation) => {
    props.handleDialogClose(userConfirmation);
  };

  const imageForMessage = () => {
    // To get different images for different popups
    if ([ABORT, QR_CODE, ADMIN_INFO_COPY_ABORT].includes(props?.messageFor)) {
      return einreichenMessageLogo;
    }
    if (props.messageFor === ABLAGE) return ablageMessageLogo;

    if (props.messageFor === ERROR) return errorMessageLogo;
  };

  const getMessageContent = (messageFor) => {
    if (messageFor === ABLAGE) {
      return (
        <div>
          <div className={headingCss}>{ABLAGE_HEADING}</div>
          <div className={contentCss}>{ABLAGE_CONTENT}</div>
        </div>
      );
    }
    if (messageFor === ERROR) {
      return (
        <div className={contentCss}>
          {props.mandatoryFieldError
            ? ERROR_CONTENT_MANDATORY
            : props?.apiErrorMessage || ERROR_CONTENT}
        </div>
      );
    }
    if (messageFor === ABORT) {
      return (
        <div>
          <div className={headingCss}>{ABORT_HEADING}</div>
          <div className={contentCss}>{ABORT_CONTENT}</div>
        </div>
      );
    }
    if (messageFor === ADMIN_INFO_COPY_ABORT) {
      return (
        <div>
          <div className={headingCss}>{ADMIN_INFO_COPY_HEADING}</div>
          <div className={contentCss}>{ADMIN_INFO_COPY_CONTENT}</div>
        </div>
      );
    }
    if (messageFor === QR_CODE) {
      return (
        <div>
          <div className={headingCss}>{QR_CODE_HEADING}</div>
          <div className={contentCss}>{QR_CODE_CONTENT_FIRST}</div>
          <div className={contentCss}>{QR_CODE_CONTENT_SECOND}</div>
        </div>
      );
    }
  };

  const getModalBody = () => {
    return (
      <Grid container>
        <Grid item xs={5}>
          <div className={`${LogoCss} ${imageForMessage()}`} />
        </Grid>
        <Grid item xs={7} className={msgContent}>
          {getMessageContent(props.messageFor)}
        </Grid>
      </Grid>
    );
  };

  const getModalFooter = () => {
    return (
      <Grid container justifyContent="end">
        {props.messageFor === ERROR && (
          <Grid item className={footerButtonsGrid}>
            <MDCButton
              variant="contained"
              label={"Bestätigen"}
              onClick={() => handleClose(false)}
            />
          </Grid>
        )}
        {props.messageFor === ABLAGE && (
          <Grid item className={footerButtonsGrid}>
            <MDCButton
              variant="outlined"
              label={"Okay"}
              onClick={() => handleClose(props.redirectState)}
            />
            <MDCButton
              variant="contained"
              label={"Zurück zur Vorgangsliste"}
              onClick={() => handleClose(true)}
            />
          </Grid>
        )}
        {(props.messageFor === ABORT ||
          props.messageFor === ADMIN_INFO_COPY_ABORT ||
          props.messageFor === QR_CODE) && (
          <Grid item className={footerButtonsGrid}>
            <MDCButton
              variant="outlined"
              label={"Abbrechen"}
              onClick={() => handleClose(false)}
            />
            <MDCButton
              variant="contained"
              label={"Bestätigen"}
              onClick={() => handleClose(true)}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <div>
      <MDCModal
        open={props.show}
        body={getModalBody()}
        footer={getModalFooter()}
        size="lg"
      />
    </div>
  );
};

export default MessageDialog;
