import React, { Component } from "react";
import VorgangeDetailsDialog from "../vorgangeDetails/VorgangeDetailsDialog";

// Helper
import { history } from "../../helpers/history";

// Actions
import { vorgangeDetailsActions } from "../../store/actions/vorgange/vorgangeDetailsActions";

import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const styles = (theme) => ({
  primaryReferenceStyle: {
    color: theme.palette.MDCColors.color23,
    cursor: "pointer",
    textDecoration: "underline",
  },
});
class NotificationMessage extends Component {
  constructor(props) {
    super(props);
    this.state = { showVorgangDetailDialog: false, rowDetails: {} };
  }

  getStatusAndViewRequest = (requestId) => {
    //This API call first checks the status of request and then decide whether to open in ME form or detail view
    this.props.getRequestDetails(requestId).then((resp) => {
      if (resp?.status === 200 && resp?.data) {
        const rowDetails = resp?.data;
        this.setState({ rowDetails });

        if (rowDetails?.status === "Gespeichert") {
          history.push("/manual-request", {
            requestId: requestId,
          });
        } else {
          this.setState({ showVorgangDetailDialog: true });
        }
      }
    });
  };

  handleDialogClose = () => {
    //To close Detail view
    this.setState({ showVorgangDetailDialog: false });
  };

  render() {
    const { primaryReference, message, requestId } = this.props;

    let messageArray;
    if (primaryReference) {
      messageArray = message?.split(primaryReference);
    }

    return (
      <>
        {primaryReference ? (
          <div>
            {messageArray &&
              messageArray.at(0) !== undefined &&
              messageArray.at(0)}
            <span
              className={this.props?.classes?.primaryReferenceStyle}
              onClick={() => this.getStatusAndViewRequest(requestId)}
            >
              {primaryReference}
            </span>
            {messageArray &&
              messageArray.at(1) !== undefined &&
              messageArray.at(1)}
          </div>
        ) : (
          <span>{message}</span>
        )}

        {/* Vorgange Details Dialog */}
        {this.state.showVorgangDetailDialog && (
          <VorgangeDetailsDialog
            show={this.state.showVorgangDetailDialog}
            rowDetails={this.state.rowDetails}
            handleDialogClose={this.handleDialogClose}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRequestDetails: (requestId) =>
      dispatch(vorgangeDetailsActions.getRequestDetails(requestId)),
  };
};

NotificationMessage.propTypes = {
  notificationDetails: PropTypes.shape({
    primaryReference: PropTypes.string,
    message: PropTypes.string,
    requestId: PropTypes.string,
  }),
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(NotificationMessage));
