import React from "react";

// Material UI Components
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  defaultRadioButton: {
    "& .MuiTypography-root.MuiFormControlLabel-label": {
      marginLeft: "0.938rem",
      color: theme.palette.MDCColors.color18,
      fontSize: "1rem",
      fontFamily: theme.palette.typography.fontFamily,
      "&.Mui-disabled": {
        color: theme.palette.MDCColors.color18,
      },
    },
    "& .MuiSvgIcon-root": { color: theme.palette.MDCColors.color23 },
  },
  disabledRadioButton: {
    "& .MuiSvgIcon-root": { color: theme.palette.MDCColors.color18 },
  },
  duplicateRadioButton: {
    "& .MuiSvgIcon-root": { color: theme.palette.MDCColors.color34 },
  },
}));

let MDCRadioButton = (props) => {
  const { name, selected, onChange, options, row, disabled, duplicate } = props;
  const { defaultRadioButton, disabledRadioButton, duplicateRadioButton } =
    useStyles();
  let MDCStyles = defaultRadioButton;

  if (disabled && !duplicate) {
    MDCStyles = `${MDCStyles} ${disabledRadioButton}`;
  }
  if (duplicate) {
    MDCStyles = `${MDCStyles} ${duplicateRadioButton}`;
  }
  return (
    <>
      <FormControl component="fieldset">
        <RadioGroup
          row={row}
          onChange={onChange}
          value={selected}
          name={name}
        >
          {options?.map((option, index) => (
            <FormControlLabel
              value={option?.value}
              control={<Radio />}
              className={MDCStyles}
              label={option?.label}
              disabled={disabled}
              key={index}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default MDCRadioButton;
