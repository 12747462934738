import React from "react";
import { connect } from "react-redux";
import MDCModal from "../core/MDCModal";
import carLogo from "../../assets/images/my_digital_car.svg";
import MDCButton from "../core/MDCButton";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import iconArchivVerwerfen from "../../assets/images/Icon_archiv_verwerfen.svg";
import DiscardIcon from "../iconComponents/DiscardIcon";
// Theme
import theme from "../../themes/mdc-theme";
const styles = () => ({
  imageWidth: {
    height: "11.625em",
    width: "13.3em",
  },
  headerCss: {
    padding: "2rem",
    alignItems: "center",
  },

  headerColor: { color: theme.palette.MDCColors.black },
  labelColor: { color: theme.palette.MDCColors.color18 },
});
class DeleteConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      displayPopup: false,
    };
  }

  componentDidMount() {
    this.setState({ displayPopup: this.props.show });
  }

  render() {
    const getModalHeader = () => {
      return (
        <Grid container className={this.props?.classes.headerCss}>
          <Grid item xs={8} className={this.props?.classes.headerColor}>
            <h4> {this.props?.headerTitle}</h4>
          </Grid>
          <Grid item xs={4}>
            <img alt="My Digital Car" src={carLogo} width="95%" />
          </Grid>
        </Grid>
      );
    };
    const getModalBody = () => {
      return (
        <Grid
          container
          alignItems="center"
          flexDirection="column"
          padding="4rem"
        >
          <Grid item marginTop="1rem">
            <img
              src={iconArchivVerwerfen}
              className={this.props?.classes?.imageWidth}
              alt={"img"}
            />
          </Grid>
          <Grid
            item
            marginTop="2rem"
            className={this.props?.classes.labelColor}
          >
            {this.props.confirmationMessage}
          </Grid>
          <Grid container marginTop="3rem" justifyContent="end">
            <MDCButton
              variant="outlined"
              label={"Abbrechen"}
              onClick={() => this.props.handlePopupClose()}
            />
            <MDCButton
              variant="outlined"
              label={"Löschen"}
              endIcon={
                <DiscardIcon color={theme?.palette?.MDCColors?.color23} />
              }
              onClick={() => this.props.handlePopupSubmit()}
            />
          </Grid>
        </Grid>
      );
    };

    return (
      <div>
        <MDCModal
          open={this.props.show}
          title={getModalHeader()}
          body={getModalBody()}
          width="36.25rem"
        />
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DeleteConfirmationDialog));
