export const checkBoxValues = [
  "kennzeichenZuweisen",
  "reservieterKundenkreis",
  "naechstesKennzeichen",
  "MerkmalElektrokennzeichen",
];

export const halfWidthInputs = [
  "auskunft_NatuerlichePerson_FamiliennameNachname",
  "vorname",
  "Strasse",
  "Hausnummer",
];

export const vorgangeDetailsConstants = {
  VORGANGE_DETAILS_SUCCESS: "VORGANGE_DETAILS_SUCCESS",
  REGISTRATION_TO_GKS_SUCCESS: "REGISTRATION_TO_GKS_SUCCESS",
  PULLREQUEST_SUCCESS: "PULLREQUEST_SUCCESS",
  UPLOAD_SUCCESS_MODAL: "UPLOAD_SUCCESS_MODAL",
  GETTING_FILE_DOWNLOAD_SUCCESS: "GETTING_FILE_DOWNLOAD_SUCCESS",
  GET_REGISTER_DATEN_XML_SUCCESS: "GET_REGISTER_DATEN_XML_SUCCESS",
  VORGANGE_DETAILS_DISPLAY: "VORGANGE_DETAILS_DISPLAY",
};

export const ignoredFieldForManualEntry = ["status", "erstezulassung"];

export const HTTP_SUCCESS_STATUS_CODES = [200, 201, 202, 204];

export const QESFilesName = [
  { name: "SEPA-Mandat", type: "sepa" },
  { name: "Vollmachtsantrag", type: "poa" },
];

// For Genehmigt status
export const DataRegistrationFilesName = [
  { name: "Registerdaten", type: "xml" },
];

// For Genehmigt status for non AB
export const ZulassungsFilesName = [
  { name: "Zulassungsnachweis", type: "teil3" },
  { name: "Zulassungsbescheid", type: "teil1" },
  { name: "Gebührenbescheid", type: "teil2" },
];

// For Genehmigt status for AB
export const ABZulassungsFilesName = [
  { name: "Abmeldebescheinigung", type: "abmeldebescheinigung" },
  { name: "Gebührenbescheid", type: "teil2" },
];

// For Abgelehnt status
export const RejectedAbgelehntFilesName = [
  { name: "Ablehnungsbescheid", type: "ablehnungsbescheid" },
  { name: "Gebührenbescheid", type: "teil2" },
];

export const TEIL1 = "teil1";
export const TEIL2 = "teil2";
export const TEIL3 = "teil3";
export const ABGELEHNT_RECORD = "ablehnungsbescheid";

export const TEIL1_ENDPOINT = "zulassungsbescheid";
export const TEIL2_ENDPOINT = "gebuehrenbescheid";
export const TEIL3_ENDPOINT = "zulassungsnachweis";
export const abmeldebescheinigung = "abmeldebescheinigung";
export const ABGELEHNT_ENDPOINT = "ablehnungsbescheid";

export const TENANT_STATUSES = {
  Aktiv: "Aktiv",
  "Bestätigung offen": "Bestätigung offen",
  Gesperrt: "Gesperrt",
};

export const BLOCK_TENANT_POPUP_MESSAGE =
  "Sind Sie sicher, dass Sie diesen Mandanten sperren möchten?";
export const UNBLOCK_TENANT_POPUP_MESSAGE =
  "Sind Sie sicher, dass Sie diesen Mandanten entsperren möchten?";

export const DASH_NO_SELECT_ERROR =
  "Bitte wählen Sie mindestens einen Eintrag aus, um die gewünschte Funktionalität zu nutzen.";
