import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";

// Material UI Components
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { makeStyles } from "@mui/styles";

// Core Components
import MDCTooltip from "./MDCTooltip";

// Utils
import { formatDate } from "../../helpers/utils";

const useStyles = makeStyles((theme) => ({
  defaultDatePicker: {
    "& .MuiInputBase-root": {
      border: `2px solid ${theme.palette.MDCColors.color23}`,
      background: theme.palette.MDCColors.color21,
      borderRadius: "5px",
      color: theme.palette.MDCColors.color18,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontSize: "1rem",
      fontFamily: theme.palette.typography.fontFamily,
      padding: "0.625rem",
      height: "calc(1rem + 1rem + 0.625rem)",
      lineHeight: "1",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "&.MuiFormControl-root": {
      width: "100%",
      marginBottom: "0px",
    },
    "& .MuiInputAdornment-positionEnd": {
      "& .MuiIconButton-label": {
        color: theme.palette.MDCColors.color23,
      },
    },
  },
  errorText: {
    "& .MuiInputBase-root": {
      border: `2px solid ${theme.palette.MDCColors.errorDanger}`,
      background: "rgba(236, 88, 103, 0.3)",
    },
    "& .MuiInputAdornment-positionEnd": {
      "& .MuiIconButton-label": {
        color: theme.palette.MDCColors.textColor,
      },
    },
  },
  disabledText: {
    "& .MuiInputBase-root": {
      border: "0",
    },
    "& .MuiInputAdornment-positionEnd": {
      "& .MuiIconButton-label": {
        color: theme.palette.MDCColors.textColor,
      },
    },
  },
  filterMenu: {
    "& .MuiInputBase-root": {
      background: theme.palette.MDCColors.white,
      border: "0px",
      borderRadius: "0rem",
      marginBottom: "0.625rem",
      padding: "0.625rem 0.025rem 0rem 0.238rem",
      height: "calc(1rem + 0.0rem + 0.625rem)",
      marginTop: "0rem",
      "&:focus": {
        backgroundColor: theme.palette.MDCColors.white,
        borderRadius: "0px",
      },
    },
    "& .MuiSelect-icon": {
      display: "none",
    },
  },
  datePickerDialogCalender: {
    "& .MuiPickersToolbarText-toolbarTxt": {
      color: `${theme.palette.MDCColors.black}`,
    },
    "& .MuiPickersDay-daySelected": {
      backgroundColor: `${theme.palette.MDCColors.color23} !important`, // Set the background color of the selected day
    },
    "& .MuiPickersToolbar-toolbar": {
      backgroundColor: `${theme.palette.MDCColors.color23} !important`,
    },
    "& .MuiButton-textPrimary": {
      color: `${theme.palette.MDCColors.color23} !important`,
    },
  },
}));

export default function MDCDatePicker(props) {
  const [selectedDate, setSelectedDate] = React.useState(null);

  const {
    isfilterMenu,
    readOnly,
    error,
    isManualEntry,
    value,
    disablePastDate,
    isToolTip,
    minDate,
    maxDate,
  } = props;

  const {
    defaultDatePicker,
    errorText,
    disabledText,
    filterMenu,
    datePickerDialogCalender,
  } = useStyles();

  let MDCStyles = defaultDatePicker;
  if (isfilterMenu) {
    MDCStyles = `${MDCStyles} ${filterMenu} `;
  }

  if (readOnly) {
    MDCStyles = `${MDCStyles} ${disabledText}`;
  }

  if (error) {
    MDCStyles = `${MDCStyles} ${errorText}`;
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);

    if (isManualEntry) {
      props.onChange(date);
    } else if (isfilterMenu) {
      props.onFilterChanged(props?.columnDef?.tableData?.id, date);
    }
  };

  // Disable the Input for the Filter
  const isInputReadonly = isfilterMenu ? { readOnly: true } : {};

  const formattedDate = selectedDate ? formatDate(selectedDate) : "";

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
      <MDCTooltip title={isToolTip && formattedDate ? formattedDate : ""}>
        <KeyboardDatePicker
          minDate={minDate}
          maxDate={maxDate}
          disablePast={disablePastDate}
          clearable
          className={MDCStyles}
          variant="dialog"
          format="dd.MM.yyyy"
          margin="normal"
          disabled={readOnly}
          value={
            selectedDate ? selectedDate : isManualEntry && value ? value : null
          }
          InputProps={isInputReadonly}
          onChange={handleDateChange}
          placeholder="tt.mm.jjjj"
          clearLabel="Löschen"
          cancelLabel="Abbrechen"
          KeyboardButtonProps={{
            "aria-label": "Datum ändern",
          }}
          minDateMessage={false}
          invalidDateMessage={false}
          maxDateMessage={false}
          DialogProps={{
            PaperProps: {
              className: datePickerDialogCalender,
            },
          }}
        />
      </MDCTooltip>
    </MuiPickersUtilsProvider>
  );
}
