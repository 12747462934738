import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21.788}
      height={16.866}
      {...props}
    >
      <g data-name="Gruppe 3401" strokeLinecap="round">
        <g data-name="Pfad 397" fill="currentColor" strokeLinejoin="round">
          <path d="M8.604 8.129a361.77 361.77 0 001.733.625c.214.075.474.165.754.02.19-.074.959-.352 1.828-.664l-2.164 3.744L8.604 8.13z" />
          <path d="M11.234 9.526c-.375.127-.722.065-.96-.004l.481.833.479-.829m3.357-2.812l-3.836 6.64-3.833-6.638s3.834 1.404 3.833 1.386c-.001-.019 3.836-1.388 3.836-1.388z" />
        </g>
        <path
          data-name="Pfad 398"
          d="M.755 6.499v7.939a1.89 1.89 0 001.986 1.678s16.688-.027 16.6 0c1.551 0 1.7-1.41 1.7-2.257-.007.023 0-7.36 0-7.36"
          fill="none"
          stroke="currentColor"
          strokeWidth={1.5}
        />
        <path
          data-name="Pfad 400"
          d="M11.151.75v7.51"
          fill="none"
          stroke="currentColor"
          strokeWidth={1.5}
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
