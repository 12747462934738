import React from "react";
import { useSelector } from "react-redux";

// Components
import MDCButton from "../../core/MDCButton";

// Icon
import CancelIcon from "../../iconComponents/CancelIcon";

// Material UI
import Grid from "@mui/material/Grid";

// Constants
import { USER_ROLE_APPROVER } from "../../../constants/userConstants";

const VollmachtLoschen = ({ handleModal }) => {
  const userDetails = useSelector((state) => state?.authentication?.user);

  const isAdminAndSupportUser =
    !userDetails?.roles?.includes(USER_ROLE_APPROVER);

  return (
    <Grid container>
      <Grid marginLeft="auto" marginBottom="2%">
        <MDCButton
          variant="outlined"
          label={"Vollmacht löschen"}
          onClick={handleModal}
          disabled={isAdminAndSupportUser}
          endIcon={<CancelIcon />}
        />
      </Grid>
    </Grid>
  );
};

export default VollmachtLoschen;
