import * as React from "react";

const SmallTruckIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={12.364} {...props}>
    <path
      fill="#00c4de"
      d="M14.682 3.091h-2.318V0H1.545A1.55 1.55 0 0 0 0 1.545v8.5h1.545a2.318 2.318 0 1 0 4.636 0h4.636a2.318 2.318 0 1 0 4.636 0H17V6.182ZM3.864 11.2a1.159 1.159 0 1 1 1.159-1.159A1.158 1.158 0 0 1 3.864 11.2ZM14.3 4.25l1.515 1.932h-3.451V4.25Zm-1.164 6.95a1.159 1.159 0 1 1 1.164-1.155 1.158 1.158 0 0 1-1.164 1.155Z"
      data-name="Icon material-local-shipping"
    />
  </svg>
);

export default SmallTruckIcon;
