import { createTheme } from "@mui/material/styles";

const MDCDefaultTheme = createTheme({
  palette: {
    primary: {
      light: "#B3D7DB",
      main: "#00c4de",
      dark: "#00c4de",
      contrastText: "#5C5B5B",
    },
    secondary: {
      light: "#FFe5B2",
      main: "#FFAA01",
      dark: "#FFCF7F",
      contrastText: "#5C5B5B",
    },
    text: {
      disabled: "#5c5b5b",
    },
    typography: {
      fontFamily: "Fira Sans !important",
      forntSize: "14px",
      fontWeightLight: "300",
      fontWeightRegular: "400",
      fontWeightMedium: "500",
      fontWeightBold: "600",
      subtitle1: {
        fontSize: 12,
      },
      button: {
        textTransform: "none",
      },
    },
    MDCColors: {
      color1: "#b3d3d8",
      color2: "#004969",
      color3: "#5185b5",
      color4: "#397b94",
      color5: "#707070",
      color6: "#337ab7",
      color7: "#007d9c",
      color8: "#a9a9a9",
      //color9: "#f2f2f2", in main.scss
      color10: "#373985",
      // color13: "#e3e3e3", in main.scss
      color15: "#606060",
      color16: "#1f7983",
      color17: "#0e4efc",
      color18: "#5c5b5b",
      color19: "#f1f1f1",
      color20: "#ffde06",
      color21: "#e6e6e6",
      color23: "#00c4de",
      color27: "#6b6c6c",
      // color29: "#2ab294", using in constant file
      color30: "#ffa000",
      color31: "#e9e9e9",
      color34: "#ffaa01",
      color35: "#E8FCFF",
      color36: "#C02A2A",
      color37: "#DD9A13",
      black: "#000000",
      white: "#ffffff",
      grey: "#c8c8c8",
      color33: "#b3d7db",
      errorSuccess: "#73bc7f",
      errorDanger: "#ec5867",
      verimiColor: "#00c29d",
      verimiHoverColor: "#43756c",

      // New Colors
      textColor: "#5F5F5F",
      backgroundColor: "#5F5F5F",
      cardBackgroundColor: "#E6E6E64D",
      disabledColor: "#00000042",
    },
  },
  typography: {
    fontFamily: "Fira Sans",
    forntSize: "14px",
    body1: {
      letterSpacing: "0",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          letterSpacing: "0", // set your desired letter-spacing value here
        },
      },
    },
  },
});

export default MDCDefaultTheme;
