import { toast } from "react-toastify";

// Constants
import { userConstants } from "../../constants/userConstants";

// Service
import { userService } from "../services/userService";

// Helper
import { history } from "../../helpers/history";

// Components
import NotificationMessage from "../../components/core/NotificationMessage";
import { HTTP_SUCCESS_STATUS_CODES } from "../../constants/VorgangDetailsConstants";

function logoutSuccess(logout) {
  return { type: userConstants.LOGOUT_SUCCESS, logout };
}

function logout() {
  return () => {
    userService.logout().then(() => {
      //we are redirecting from BE only after logout
      //dispatch(logoutSuccess(success));
    });
  };
}

function verimiLoginSuccess(user) {
  return { type: userConstants.LOGIN_SUCCESS, user };
}

function verimiLogin(accessToken) {
  return (dispatch) => {
    userService.verimiLogin(accessToken).then((user) => {
      if (user.user_type) {
        dispatch(verimiLoginSuccess(user));
      }
    });
  };
}

function updateUserDetails(user) {
  return { type: userConstants.USER_UPDATE, user };
}

function getUserDetails() {
  return (dispatch) => {
    return userService.getUserDetails().then((user) => {
      if (!user) {
        history.push("/login");
        localStorage.clear();
      }
      dispatch(verimiLoginSuccess(user));
    });
  };
}

function saveEmployeeId(employeeId) {
  return (dispatch) => {
    return userService.saveEmployeeID(employeeId).then((resp) => {
      if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status) && resp.data) {
        toast.success(<NotificationMessage message={resp.data.message} />);
        //dispatch Notification for user update as well
        const user = JSON.parse(localStorage.getItem("user"));
        dispatch(updateUserDetails(user));
      } else {
        if (resp?.message) {
          toast.error(
            <NotificationMessage
              message={{
                message: resp.data.responseData.errorMessage,
              }}
            />
          );
        }
      }
    });
  };
}

function companyDetailsSuccess(resp) {
  return {
    type: userConstants.COMPANY_DETAILS_SUCCESS,
    resp,
  };
}

function getCompanyDetails(aliasName) {
  return (dispatch) => {
    return userService.getCompanyDetails(aliasName).then((resp) => {
      if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
        dispatch(companyDetailsSuccess(resp));
      }
    });
  };
}

function getExcelTempalteSuccess(resp) {
  return {
    type: userConstants.GET_EXCEL_TEMPALTE_SUCCESS,
    resp,
  };
}
function getTempaltePDFSuccess(resp) {
  return {
    type: userConstants.GET_TEMPALTE_PDF_SUCCESS,
    resp,
  };
}

function getExcelTempalte() {
  return (dispatch) => {
    return userService.getExcelTempalte().then((resp) => {
      if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
        dispatch(getExcelTempalteSuccess(resp));
      }
    });
  };
}

function getTempaltePDF(docType) {
  return (dispatch) => {
    return userService.getTempaltePDF(docType).then((resp) => {
      if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
        dispatch(getTempaltePDFSuccess(resp));
      }
    });
  };
}

// Verimi Register Starts
function verimiRegisterSuccess(responseData) {
  return { type: userConstants.VERIMI_REGISTER_SUCCESS, responseData };
}

function verimiRegister(data) {
  return (dispatch) => {
    userService.verimiRegister(data).then((resp) => {
      if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
        dispatch(verimiRegisterSuccess(resp?.data?.responseData));
      }
    });
  };
}
// Verimi Register Ends

function getProductVersionSuccess(responseData) {
  return { type: userConstants.GET_PRODUCT_VERSION_SUCCESS, responseData };
}

function getProductVersion() {
  return (dispatch) => {
    userService.getProductVersion().then((resp) => {
      if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
        dispatch(getProductVersionSuccess(resp?.data));
      }
    });
  };
}

function tenantContractIdRegistration(tenantIds) {
  return () => {
    return userService.tenantContractIdRegistration(tenantIds).then((resp) => {
      return resp;
    });
  };
}

export const userActions = {
  // Added for unit testcases
  verimiLoginSuccess,
  companyDetailsSuccess,
  logoutSuccess,
  updateUserDetails,
  getProductVersionSuccess,
  getExcelTempalteSuccess,
  getTempaltePDFSuccess,
  getUserDetails,
  // Added for unit testcases Ends

  logout,
  verimiLogin,
  saveEmployeeId,
  getCompanyDetails,
  verimiRegister,
  getProductVersion,
  getExcelTempalte,
  getTempaltePDF,
  tenantContractIdRegistration,
};
