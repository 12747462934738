import axios from "axios";
// Config
import { config } from "../config";

const dateColumns = ["letzteAnderung"];

function getvollmachtsDatenBankData(query) {
  var headers = {
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  let url = `${config.apiUrl}/dauervollmacht?size=${
    query?.pageSize || 5
  }&page=${query?.page || 0}`;

  if (query?.orderBy && query?.orderDirection !== "") {
    url += `&sort=${query?.orderBy?.field}&order=${query?.orderDirection}`;
  }

  //handle filter
  if (query?.filters && query?.filters?.length > 0) {
    let filters = "";
    query?.filters?.map((filter) => {
      if (filters !== "") {
        filters += "&";
      }
      if (dateColumns.includes(filter.column.field)) {
        filter.value = moment(filter.value).format("YYYY-MM-DD");
      }
      return (filters += `${filter.column.field}${filter.operator}${filter.value}`);
    });
    url += `&${filters}`;
  }

  return axios
    .get(url, headers)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}
function deletePOARecords(requestIds) {
  let url = `${config.apiUrl}/dauervollmacht${
    requestIds.length === 1
      ? `/${requestIds[0]}` //for single record
      : `?ids=${requestIds.toString()}` // for  multiple records
  }`;

  return axios
    .delete(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}
function createPOARecords(formValuesPOA) {
  let url = `${config.apiUrl}/dauervollmacht`;

  return axios
    .post(url, formValuesPOA)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function updatePOARecords(poaId, formValuesPOA) {
  let url = `${config.apiUrl}/dauervollmacht/${poaId}`;

  return axios
    .put(url, formValuesPOA)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

// Get Bank POA Details
function getVollmachtBankPOADetails(poaId) {
  let url = `${config.apiUrl}/dauervollmacht/${poaId}`;
  return axios
    .get(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

// Update Bank POA Details for QES
function updateVollmachtBankPOAForQES(data, poaId) {
  const url = `${config.apiUrl}/dauervollmacht/${poaId}`;

  return axios
    .patch(url, data)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

// Delete POA QES Process Data
function deleteQESForPOARecords(requestId) {
  let url = `${config.apiUrl}/dauervollmacht/qes/${requestId}`;

  return axios
    .delete(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function generateVollmachtBankPOAQRCode(poaId) {
  const url = `${config.apiUrl}/dauervollmacht/${poaId}/qes`;
  return axios
    .post(url, {}, { responseType: "blob" })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function getVollmachtBankPOAQRCode(poaId) {
  const url = `${config.apiUrl}/dauervollmacht/${poaId}/qes/qrcode`;
  return axios
    .get(url, {
      responseType: "blob",
    })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function regenerateVollmachtBankPOAQRCode(poaId) {
  const url = `${config.apiUrl}/dauervollmacht/${poaId}/qes`;
  return axios
    .put(
      url,
      {},
      {
        responseType: "blob",
      }
    )
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

// Get POA QES Status
function getVollmachtBankSigningStatus(poaId) {
  let url = `${config.apiUrl}/dauervollmacht/${poaId}/qes/status`;

  return axios
    .get(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

export const vollmachtsDatenBankService = {
  getvollmachtsDatenBankData,
  deletePOARecords,
  createPOARecords,
  getVollmachtBankPOADetails,
  updateVollmachtBankPOAForQES,
  updatePOARecords,

  // For QES Process
  generateVollmachtBankPOAQRCode,
  getVollmachtBankPOAQRCode,
  regenerateVollmachtBankPOAQRCode,
  deleteQESForPOARecords,
  getVollmachtBankSigningStatus,
};
