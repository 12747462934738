import { config } from "../config";
import axios from "axios";

/**
 * Get Individual Vorgange Details
 *
 * @param {*} id
 * @param {*} isArchivData
 * @returns
 */
function getIndividualVorgangeDetails(id, isArchivData) {
  let url = `${config.apiUrl}/${
    isArchivData ? "housekeeping/archive" : "vorgangs"
  }/${id}/vorgang-attributes`;

  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}


/**
 * Create GKS Regristration
 *
 * @param {*} id
 * @returns
 */
function handleRegistrationGKS(ids) {
  return axios
    .post(`${config.apiUrl}/vorgangs/registration`, ids)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

/**
 * Rejection
 *
 * @param {*} id
 * @returns
 */
function handleRejection(id) {
  return axios
    .post(`${config.apiUrl}/vorgangs/${id}/reject-registration`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

/**
 * Create Pull Request
 *
 * @param {*} id
 * @returns
 */
function handlePullRequest(id) {
  return axios
    .post(`${config.apiUrl}/iteos/${id}/pull-request`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

/**
 * Upload Vorgangs File
 *
 * @param {*} file
 * @returns
 */
function uploadFile(file) {
  if (file) {
    return axios
      .post(`${config.apiUrl}/vorgangs`)
      .then((activityList) => {
        return activityList;
      })
      .catch((error) => {
        return error;
      });
  }
}

/**
 * Download File
 *
 * @param {*} requestId
 * @param {*} fileType
 * @returns
 */
function downloadFile(requestId, fileType) {
  const url = `${config.apiUrl}/iteos/${requestId}/response-${fileType}`;
  const responseType = "blob";
  return axios
    .get(url, { responseType })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error;
    });
}

/**
 * get particular request details
 *
 * @param {*} requestId
 * @returns
 */
function getRequestDetails(requestId) {
  const url = `${config.apiUrl}/vorgangs/${requestId}`;
  return axios
    .get(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

/**
 * get Data Registrations List Details
 *
 * @param {*} requestId
 * @returns
 */
function getDataRegistrationsList(requestId) {
  const url = `${config.apiUrl}/vorgangs/${requestId}/register-daten`;
  return axios
    .get(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function getRegisterDatenXML(requestId) {
  const url = `${config.apiUrl}/vorgangs/${requestId}/register-daten/xml`;
  return axios
    .get(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}
export const vorgangeDetailsService = {
  getIndividualVorgangeDetails,
  handleRegistrationGKS,
  handleRejection,
  handlePullRequest,
  uploadFile,
  downloadFile,
  getRequestDetails,
  getDataRegistrationsList,
  getRegisterDatenXML,
};
