const initialState = {};

const csvImportExportReducer = (state, action) => {

  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case "GET_EXCEL_RESPONSE_SUCCESS":
      return Object.assign({}, { data: action.resp.data });
    case "GET_EXCEL_RESPONSE_ERROR":
      return Object.assign({}, { error: action.error.data });
    case "GET_EXCEL_RESPONSE_RESET":
      return initialState;
    default:
      return state;
  }
};
export default csvImportExportReducer;
