import * as React from "react";

function SvgComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path id="Icon_material-person" data-name="Icon material-person" d="M18,18a6,6,0,1,0-6-6A6,6,0,0,0,18,18Zm0,3c-4.005,0-12,2.01-12,6v3H30V27C30,23.01,22.005,21,18,21Z" transform="translate(-6 -6)" fill="#00c4de"/>
    </svg>
  );
}

export default SvgComponent;
