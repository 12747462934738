import React from "react";
import PropTypes from "prop-types";

// Material UI Components
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    "&.MuiButton-outlined": {
      fontFamily: theme.palette.typography.fontFamily,
      fontSize: "1rem",
      fontWeight: "400",
      textTransform: "initial",
      borderRadius: "5px",
      border: `solid 1px ${theme.palette.MDCColors.white}`,
      marginRight: ".5rem",
      // padding: ".4rem 1rem",
      marginTop: ".625rem",
      "&:hover": {
        borderColor: theme.palette.MDCColors.white,
        background: theme.palette.MDCColors.white,
      },
      background: theme.palette.MDCColors.white,
      // transition: "all 0.3s ease",
      color: theme.palette.MDCColors.color18,
      "&:not(:disabled):hover": {
        color: "none",
      },
    },
    "&.MuiButton-text": {
      fontFamily: theme.palette.typography.fontFamily,
      fontSize: "1.125rem",
      textTransform: "initial",
      color: theme.palette.MDCColors.color23,
      opacity: 1,
      borderLeft: `2px solid ${theme.palette.MDCColors.color21}`,
      padding: "0.75rem",
      fontWeight: 400,
      "&:not(:disabled):hover": {
        color: "#fff",
        backgroundColor: theme.palette.MDCColors.color23,
      },
    },
  },
}));

const MDCButtonDropdown = (props) => {
  const { label, onClick, disabled, variant, endIcon, className, startIcon } =
    props;
  const { button } = useStyles(props);
  return (
    <Button
      variant={variant}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      className={[button, className].join(" ")}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

MDCButtonDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disbled: PropTypes.bool,
};

export default MDCButtonDropdown;
