import * as React from "react";

function RightArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
    >
      <g
        id="Right_arrow"
        data-name="Right arrow"
        transform="translate(-3580 -27)"
      >
        <rect
          id="Rectangle_892"
          data-name="Rectangle 892"
          width="10"
          height="10"
          transform="translate(3580 27)"
          fill="none"
        />
        <path
          id="Icon_ionic-md-arrow-dropright"
          data-name="Icon ionic-md-arrow-dropright"
          d="M13.5,9l5,5-5,5Z"
          transform="translate(3569.5 18)"
          fill="#047c89"
        />
      </g>
    </svg>
  );
}

export default RightArrowIcon;
