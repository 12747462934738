// Constants
import { orderLicensePlateConstants } from "../../../constants/LicensePlateConstants";

const initialState = {};

export default (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }

  switch (action.type) {
    case orderLicensePlateConstants.CREATE_ORDER_LICENSE_PLATE_REQUEST_DATA:
      return Object.assign({}, state, {
        orderLicensePlateFormData: action?.orderLicensePlateFormData,
      });
    case orderLicensePlateConstants.CREATE_ORDER_LICENSE_PLATE_SUCCESS:
      return Object.assign({}, state, {
        orderLicensePlateFormDetails: action?.orderLicensePlateFormDetails,
      });
    case orderLicensePlateConstants.GET_ORDER_LICENSE_PLATE_SUCCESS:
      return Object.assign({}, state, {
        orderLicensePlateDetails: action?.resp?.data[0] || {},
      });
    default:
      return state;
  }
};
