import * as React from "react";

function BlockIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={19} height={19} {...props}>
      <g
        data-name="Ellipse 57"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
      >
        <circle cx={9.5} cy={9.5} r={9.5} stroke="none" />
        <circle cx={9.5} cy={9.5} r={8.5} />
      </g>
      <path
        data-name="Linie 115"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        d="M3.5 15.5l12-11"
      />
    </svg>
  );
}

export default BlockIcon;
