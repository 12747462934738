// Constants
import {
  GETTING_FEES_REPORT_DETAILS_ROW_CSV_SUCCESS,
  GETTING_FEES_REPORT_ZIP_CSV_SUCCESS,
  GET_FEES_REPORT_CSV_LIST,
} from "../../../../constants/FeesReportConstants";

const initialState = {
  loading: true,
};

const feesReportListReducer = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }

  switch (action.type) {
    case GET_FEES_REPORT_CSV_LIST:
      return Object.assign({}, state, {
        rows: action.resp.content,
        totalCount: action.resp.totalElements,
        loading: false,
      });

    case GETTING_FEES_REPORT_DETAILS_ROW_CSV_SUCCESS:
      return Object.assign({}, state, {
        rowCsvData: action.resp,
        loading: false,
      });

    case GETTING_FEES_REPORT_ZIP_CSV_SUCCESS:
      return Object.assign({}, state, {
        csvData: action.resp,
        loading: false,
      });

    default:
      return state;
  }
};

export default feesReportListReducer;
