export const getFileExtension = (filename) => {
  if (!filename) {
    return "";
  }
  const ext = filename?.substring(
    filename.lastIndexOf(".") + 1,
    filename.length
  );
  return ext === null ? "" : ext;
};
