import { config } from "../config";
import axios from "axios";

import { qrCode } from "../../../helpers/vorgange";

function createManualEntry(data, requestId, createNewRequest = false) {
  let url = `${config.apiUrl}/vorgangs/manual-request`;
  let method = "post";

  if (requestId && !createNewRequest) {
    url = `${config.apiUrl}/vorgangs/${requestId}/vorgang-attributes`;
    method = "put";
  }

  return axios({
    method: method,
    url: url,
    data: data,
  })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
}

function vollmachtTabValidation(requestId) {
  const url = `${config.apiUrl}/qes/${requestId}/vollmacht`;
  return axios({
    method: "get",
    url: url,
  })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function getQRCode(requestId, QRCodeStatus) {
  const url = `${config.apiUrl}/qes/${requestId}/qrcode${
    QRCodeStatus === qrCode.generateNewQRCode ? "/renewal" : ""
  }`;
  return axios({
    method: "post",
    url: url,
    responseType: "blob",
  })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function getVollmachtSigningStatus(requestId) {
  let url = `${config.apiUrl}/qes/${requestId}/status`;
  return axios({
    method: "get",
    url: url,
  })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function getVollmachtSignedDocuments(requestId, docName) {
  let url = `${config.apiUrl}/qes/${requestId}/signedDocument/${docName}`;
  return axios({
    method: "get",
    url: url,
    responseType: "blob",
  })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function getZulassungsbescheinigungDocuments(requestId, docName) {
  let url = `${config.apiUrl}/vorgangs/${requestId}/${docName}`;
  return axios({
    method: "get",
    url: url,
    responseType: "blob",
  })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

/**
 * Delete QES Process Data
 *
 * @param {*} requestId
 * @returns
 */
async function deleteQES(requestId) {
  const url = `${config.apiUrl}/qes/${requestId}`;

  try {
    const resp = await axios.delete(url);
    return resp;
  } catch (error) {
    return error;
  }
}

function getCountriesList() {
  const url = `${config.apiUrl}/tcs/countries`;
  return axios({
    method: "get",
    url: url,
  })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

async function getWirtschaftszweigData() {
  const url = `${config.apiUrl}/vorgangs/templates/schluesselBeruf-data`;

  try {
    const resp = await axios({
      method: "get",
      url: url,
    });
    return resp;
  } catch (error) {
    return error;
  }
}

function getSteuerbefreiungData() {
  const url = `${config.apiUrl}/vorgangs/templates/steuerbefreiung-data`;
  return axios({
    method: "get",
    url: url,
  })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function downloadWirtschaftsPDF() {
  const url = `${config.apiUrl}/vorgangs/templates/schluesselBeruf`;
  return axios({
    method: "get",
    url: url,
    responseType: "blob",
  })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

export const manualRequestFormService = {
  createManualEntry,
  vollmachtTabValidation,
  getQRCode,
  getVollmachtSigningStatus,
  getVollmachtSignedDocuments,
  getZulassungsbescheinigungDocuments,
  deleteQES,
  getCountriesList,
  downloadWirtschaftsPDF,
  getWirtschaftszweigData,
  getSteuerbefreiungData,
};
