import * as React from "react";

function DiscardIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21.788}
      height={18.116}
      viewBox="0 0 15.689 20.351"
      {...props}
    >
      <g fill="none" stroke="currentColor" strokeWidth={1.5}>
        <path
          data-name="Pfad 737"
          d="M14.939 8.179l-1.653 11.422H4.94L2.97 8.179z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          data-name="Linie 116"
          strokeLinecap="round"
          d="M.931 7.272l12.948-3.808"
        />
        <path
          data-name="Pfad 738"
          d="M2.371 6.733l1.063-2.724 6.8-2.045 2.342 1.911"
          strokeLinejoin="round"
        />
        <path
          data-name="Pfad 739"
          d="M4.119 3.22C3.557.384 8.39.099 9.029 1.966"
        />
        <path
          data-name="Linie 117"
          strokeLinecap="round"
          d="M6.882 10.318l1.142 7.616"
        />
        <path
          data-name="Linie 118"
          strokeLinecap="round"
          d="M11.355 10.318l-1.142 7.616"
        />
      </g>
    </svg>
  );
}

export default DiscardIcon;
