import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";

class PrivateRoute extends React.Component {
  isAuthorised = (roles) => {
    let allowed = false;
    roles.map((role) => {
      if (!allowed) {
        allowed = this.props?.authentication?.user?.roles?.includes(role);
      }
    });
    return allowed;
  };

  render() {
    const { component: Component, roles, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          const currentUser = this.props?.authentication?.user;

          if (!currentUser) {
            if (this.props?.authentication?.redirectUrl) {
              return (
                <Redirect
                  to={{
                    pathname: "/logout",
                    state: {
                      redirectUrl: this.props?.authentication?.redirectUrl,
                    },
                  }}
                />
              );
            } else {
              // not logged in so redirect to login page with the return url
              return (
                <Redirect
                  to={{ pathname: "/login", state: { from: props.location } }}
                />
              );
            }
          }

          // check if route is restricted by role
          if (
            roles &&
            !this.isAuthorised(roles) &&
            !this.props?.authentication.redirectUrl
          ) {
            // role not authorised so redirect to home page
            return <Redirect to={{ pathname: "/" }} />;
          }

          // authorised so return component
          return (
            <ErrorBoundary>
              <Component {...props} />
            </ErrorBoundary>
          );
        }}
      />
    );
  }
}

function mapState(state) {
  const { authentication } = state;
  return { authentication };
}

export default connect(mapState)(PrivateRoute);
