export const ADMIN_CONSTANTS = {
  ADD_USER: "ADMIN_ADD_USER",
  GET_CLIENT: "ADMIN_GET_CLIENT",
  GET_ALL_USERS: "ADMIN_GET_ALL_USERS",
  GET_USERS: "ADMIN_GET_USERS",
  GET_ALL_USERS_SUCCESS: "GET_ALL_USERS_SUCCESS",
  GET_VERIMI_SUCCESS: "GET_VERIMI_SUCCESS",
  GET_VERIMI_FAILURE: "GET_VERIMI_FAILURE",
  ADD_VERIMI_SUCCESS: "ADD_VERIMI_SUCCESS",
  ADD_VERIMI_FAILURE: "ADD_VERIMI_FAILURE",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
  SAVE_USER_SUCCESS: "SAVE_USER_SUCCESS",
};