import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={55.918}
      height={55.918}
      {...props}
    >
      <path
        data-name="Pfad 721"
        d="m18.875 0 37.043 18.875-18.875 37.043L0 37.043Z"
        fill="none"
      />
      <path
        data-name="Pfad 722"
        d="M20.094 43.394a3.475 3.475 0 0 0 4.66-1.514l-6.173-3.146a3.475 3.475 0 0 0 1.513 4.66Zm13.98-4.542 3.932-7.717c2.414-4.738 1.92-9.987-1.98-13.296l.535-1.049a2.6 2.6 0 0 0-4.633-2.36l-.534 1.048c-4.95-1.2-9.498 1.46-11.92 6.213l-3.933 7.717-4.649 1.52-.786 1.544 24.696 12.584.786-1.544Zm-2.674.673-14.477-7.05 4.582-9.411c1.952-3.832 5.16-6.868 10.781-4.592s5.254 7.584 3.304 11.412Z"
        fill="currentColor"
      />
      <path
        data-name="Pfad 723"
        d="M43.042 21.707a8.841 8.841 0 0 1 .119 8.515"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        data-name="Pfad 724"
        d="M25.188 12.596a8.841 8.841 0 0 0-6.504 5.497"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        data-name="Pfad 725"
        d="M21.558 11.448s-3.496 1.796-4.146 4.596"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        data-name="Pfad 726"
        d="M22.831 10.657a5.378 5.378 0 0 1 2.174-.645"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgComponent;
