import * as React from "react";

function DownArrowIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12.46}
      height={6.946}
      {...props}
    >
      <path
        data-name="Pfad 711"
        d="M.67.765L6.045 5.62 11.816.765"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
      />
    </svg>
  );
}

export default DownArrowIcon;
