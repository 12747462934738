import * as React from "react"

const ErrorIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19.225}
    height={18.322}
    {...props}
  >
    <g data-name="Gruppe 3283">
      <path
        data-name="Pfad 391"
        d="m3.542 17.572 1.672-6.239.542-2.235L7.779.75h3.794l1.926 8.348.472 2.046 1.522 6.428Z"
        fill="none"
        stroke="#ec5867"
        strokeMiterlimit={10}
        strokeWidth={1.5}
      />
    </g>
    <path
      data-name="Pfad 392"
      d="M5.754 9.224h7.589l.506 2.024-8.474-.126Z"
      fill="#ec5867"
    />
    <path
      data-name="Pfad 393"
      d="M6.83 4.671h5.565l.506 2.024H6.324Z"
      fill="#ec5867"
    />
    <path
      data-name="Linie 118"
      fill="none"
      stroke="#ec5867"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M0 17.572h19.225"
    />
  </svg>
)

export default ErrorIcon
