import * as React from "react";

function NextIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16.287}
      height={15.028}
      {...props}
    >
      <g fill="none" stroke="currentColor" strokeWidth={1.5}>
        <path
          data-name="Pfad 85"
          d="M15.537 7.588L.75.75l3.962 6.838L.75 14.277z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path data-name="Pfad 394" d="M4.07 7.515h10.982" />
      </g>
    </svg>
  );
}

export default NextIcon;
