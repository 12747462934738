import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
// Actions
import { notificationActions } from "../../../store/actions/notification/notificationActions";
import MDCLoader from "../../core/MDCLoader";

import MDCNotificationDialog from "../../core/MDCNotificationDialog";

const styles = () => ({
  notificationContainer: {
    maxHeight: "26.125rem",
    overflowY: "scroll",
  },
});
class NotificationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      notificationsData: this.props.notificationsData,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.notificationsData !== prevProps.notificationsData) {
      this.setState({ notificationsData: this.props?.notificationsData });
    }
  }

  handleDelete = async (id) => {
    const idIsSingle = typeof id === "string";
    const ids = idIsSingle
      ? [id]
      : this.state?.notificationsData?.map(({ messageId }) => messageId);

    const filteredData = this.state?.notificationsData?.filter(
      (obj) => obj.messageId !== id
    );

    this.setState({ notificationsData: filteredData });

    this.props.deleteNotifications(ids).then((isDeleted) => isDeleted);
  };

  render() {
    return (
      <>
        {this.state.showLoader ? (
          <Grid container justifyContent={"center"}>
            <MDCLoader />
          </Grid>
        ) : (
          <MDCNotificationDialog
            notificationsData={this.state?.notificationsData}
            handleDelete={this.handleDelete}
            notificationCount={this.props.notificationCount}
          />
        )}
      </>
    );
  }
}

function mapState() {
  return {};
}

const actionCreators = {
  deleteNotifications: notificationActions.deleteNotifications,
};

export default connect(
  mapState,
  actionCreators
)(withStyles(styles)(NotificationDialog));
