import React from "react";
import { connect } from "react-redux";
import carLogo from "../../assets/images/my_digital_car.svg";
import MDCModal from "../core/MDCModal";
import MDCButton from "../core/MDCButton";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import theme from "../../themes/mdc-theme";
import iconArchivVerwerfen from "../../assets/images/Icon_archiv_verwerfen.svg";

import {
  VERWERFEN_TEXT_QUESTION,
  VERWERFEN_TEXT,
} from "../../constants/messageConstants";

const styles = () => ({
  headerCss: {
    padding: "2rem",
    alignItems: "center",
  },
  headerColor: { color: theme.palette.MDCColors.black },
  contentCss: {
    fontFamily: theme.palette.typography.fontFamily,
    color: theme.palette.MDCColors.color15,
    padding: "0 2rem",
  },
  footerCss: {
    padding: "2rem",
  },
});
class VerwerfenPopupDialog extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const getVerwerfenModalHeader = () => {
      return (
        <Grid container className={classes.headerCss}>
          <Grid item xs={7} className={classes.headerColor}>
            <h4>Verwerfen</h4>
          </Grid>
          <Grid item xs={5}>
            <img alt="My Digital Car" src={carLogo} width="95%"/>
          </Grid>
        </Grid>
      );
    };
    const getVerwerfenModalFooter = () => {
      return (
        <Grid
          container
          justifyContent="space-between"
          className={classes.footerCss}
        >
          <Grid item>
            <MDCButton
              onClick={() => this.props.handleVerwerfenClose()}
              variant="outlined"
              label="Abbrechen"
            />
          </Grid>
          <Grid item>
            <MDCButton
              onClick={() => this.props.handleVerwerfenSubmit()}
              variant="contained"
              label="Bestätigen"
            />
          </Grid>
        </Grid>
      );
    };
    const getVerwerfenModalBody = () => {
      return (
        <Grid container className={classes.contentCss}>
          <Grid container justifyContent="center">
            <img src={iconArchivVerwerfen} />
          </Grid>
          <Grid container marginTop="2rem">
            <p>{VERWERFEN_TEXT_QUESTION}</p>
            <p>{VERWERFEN_TEXT}</p>
          </Grid>
        </Grid>
      );
    };
    return (
      <div>
        <MDCModal
          open={this.props.show}
          title={getVerwerfenModalHeader()}
          body={getVerwerfenModalBody()}
          footer={getVerwerfenModalFooter()}
          size="sm"
        />
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(VerwerfenPopupDialog));
