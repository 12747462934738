import React from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  licensePlate: {
    backgroundColor: theme.palette.MDCColors.white,
    position: "relative",
    color: theme.palette.MDCColors.color5,
    boxShadow: `0px 3px 6px ${theme.palette.MDCColors.color18}`,
    border: `0.5px solid ${theme.palette.MDCColors.color5}`,
    borderRadius: "5px",
    width: "fit-content",
    paddingRight: "25px",
    marginBottom: "25px",
  },
  licensePlateBlue: {
    backgroundColor: theme.palette.MDCColors.color17,
    borderBottomLeftRadius: "5px",
    borderTopLeftRadius: "5px",
    fontSize: "0.831rem",
    padding: "0.35rem",
    textAlign: "center",
    marginRight: "0.625rem",
  },
  roundDashedLogo: {
    width: "13px",
    height: "13px",
    opacity: 1,
    borderRadius: "50%",
  },
  borderYellow: {
    border: `0.5px dashed ${theme.palette.MDCColors.color20}`,
  },
  borderGrey: {
    border: `0.5px dashed ${theme.palette.MDCColors.color5}`,
  },
  licensePlateBlueText: {
    color: theme.palette.MDCColors.white,
    fontSize: "1rem",
  },
  licensePlateText: {
    fontSize: "1.9rem",
    margin: "5px",
    minWidth: "2ch",
    textTransform: " uppercase",
  },
  marginRight0: {
    marginRight: 0,
  },
  licensePlateTwoRoundLogo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "5px",
  },
  roundBlueLogo: {
    width: "8px",
    height: "7px",
    border: `1px dashed ${theme.palette.MDCColors.black}`,
    backgroundColor: theme.palette.MDCColors.color17,
    opacity: "1",
    borderRadius: "50%",
  },
}));

const MDCLicensePlate = (props) => {
  const {
    licensePlate,
    licensePlateBlue,
    roundDashedLogo,
    borderYellow,
    licensePlateBlueText,
    licensePlateText,
    licensePlateTwoRoundLogo,
    roundBlueLogo,
    borderGrey,
    marginRight0,
  } = useStyles();

  return (
    <Grid container className={licensePlate}>
      <div className={licensePlateBlue}>
        <div className={`${roundDashedLogo} ${borderYellow}`} />
        <div className={licensePlateBlueText}>D</div>
      </div>
      <Grid display="flex">
        <label className={licensePlateText}>{props.licensePlateF}</label>
        <div className={licensePlateTwoRoundLogo}>
          <div className={roundBlueLogo} />
          <div className={`${roundDashedLogo} ${borderGrey}`} />
        </div>
        <label className={`${licensePlateText} ${marginRight0}`}>
          {props.licensePlateM}
        </label>
        <label className={licensePlateText}>
          {props.licensePlateL}
          {props?.isELicensePlate && props.licensePlateF.length ? "E" : ""}
        </label>
      </Grid>
    </Grid>
  );
};

export default MDCLicensePlate;
