import React from "react";

// Assests
import MDCSPinner from "../../assets/MDC-Spinner-Auto.gif";

function MDCLoader() {
  return (
    <div id="divLoading">
      <p id="loading">
        <img src={MDCSPinner} alt="loading" />
      </p>
    </div>
  );
}

export default MDCLoader;
