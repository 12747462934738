import React from "react";
import PropTypes from "prop-types";

// Material UI Components
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";

// Core Components
import MDCLabel from "./MDCLabel";

const useStyles = makeStyles((theme) => ({
  backgroundCSS: {
    padding: "1rem",
    paddingBottom: ".5rem",
    margin: ".5rem 0",
    backgroundColor: theme.palette.MDCColors.cardBackgroundColor,
    width: "100%",
  },
  contentTextCSS: {
    color: theme.palette.MDCColors.color18,
    margin: ".25rem 0",
    wordBreak: "break-word",
  },
}));

const MDCTextArea = (props) => {
  const { id, headerText, contentText, isCommaText } = props;

  const { backgroundCSS, contentTextCSS } = useStyles();
  return (
    <>
      <Grid className={backgroundCSS} id={id}>
        <Grid marginBottom={".5rem"} fontWeight={"600"}>
          <MDCLabel label={headerText} />
        </Grid>
        {isCommaText ? (
          <>
            {contentText.map((text, index) => {
              return (
                <>
                  {!text?.includes(undefined) && (
                    <div key={index}>
                      {/**Checking for empty string,whitespace characters or undefined string in this regex */}

                      {!/^\s*$/.test(text) && (
                        <label className={contentTextCSS}>
                          {text}
                          {/*For not showing comma in last line*/}
                          {contentText.length - 1 !== index ? "," : ""}{" "}
                        </label>
                      )}
                    </div>
                  )}
                </>
              );
            })}
          </>
        ) : (
          <MDCLabel label={contentText[0]} variant="" />
        )}
      </Grid>
    </>
  );
};
MDCTextArea.propTypes = {
  isCommaText: PropTypes.bool,
  headerText: PropTypes.string,
  contentText: PropTypes.array,
};
export default MDCTextArea;
