// Constants
import {
  RESET_SUPPORT_KBA_SUCCESS,
  SUPPORT_KBA_REQUEST,
  SUPPORT_KBA_SUCCESS,
} from "../../../constants/RequestXMLLogsConstants";

const initialState = {
  loading: true,
  isSupportKBALoading: false,
};

const vorgangeListReducer = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case "GET_VORGANGE_LIST":
      return Object.assign({}, state, {
        rows: action.resp.content,
        totalCount: +action.resp.totalElements,
        loading: false,
      });

    case "ROW_DETAILS_RESPONSE_SUCCESS":
      return Object.assign({}, state, {
        rowDetails: action.resp.data,
        loading: false,
      });

    case "GETTING_DETAILS_XML_SUCCESS":
      return Object.assign({}, state, {
        xmlData: action.resp,
        loading: false,
      });

    case "GETTING_ROW_DETAILS_RESPONSE":
    case "GETTING_DETAILS_XML":
      return Object.assign({}, state, {
        loading: true,
      });

    case "SET_DASHBOARD_TABLE_QUERY":
      return Object.assign({}, state, {
        dashboardTableQuery: action?.query,
      });

    case "IS_DASHBOARD_EINREICHEN_NOTIFICATION":
      return Object.assign({}, state, {
        einreichenNotificationData: action?.data,
      });

    case SUPPORT_KBA_REQUEST:
      return Object.assign({}, state, {
        isSupportKBALoading: true,
      });

    case SUPPORT_KBA_SUCCESS:
      return Object.assign({}, state, {
        supportKBA: action?.resp,
        isSupportKBALoading: false,
      });

    case RESET_SUPPORT_KBA_SUCCESS:
      return Object.assign({}, state, {
        isSupportKBALoading: false,
        supportKBA: [],
      });

    default:
      return state;
  }
};
export default vorgangeListReducer;
