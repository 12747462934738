import React from "react";
import { makeStyles } from "@mui/styles";

// Actions
import NotificationMessage from "./NotificationMessage";

import {
  alertCSS,
  alertErrorCSS,
  alertSuccessCSS,
  alertInfoCSS,
  closeIconCSS,
} from "../shared/AlertCSS";

const useStyles = makeStyles((theme) => ({
  notificationContainer: {
    maxHeight: "26.125rem",
    overflowY: "scroll",
  },
  notificationMessage: {
    marginBottom: "1rem",
  },
  notificationTimestamp: {
    position: "absolute",
    right: "1.7vw",
    bottom: 0,
    background: theme.palette.MDCColors.color1,
    padding: "2px 5px",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    fontSize: "0.875rem",
    lineHeight: "normal",
  },
  alert: alertCSS,
  alertError: alertErrorCSS,
  alertSuccess: alertSuccessCSS,
  alertInfo: alertInfoCSS,
  closeIcon: closeIconCSS,
  removeAllNotifications: {
    border: 0,
    padding: "5px 20px",
    fontSize: "1rem",
    background: theme.palette.MDCColors.color23,
    color: theme.palette.MDCColors.white,
    borderRadius: 5,
  },
}));

const MDCNotificationDialog = ({
  notificationsData,
  notificationCount,
  handleDelete,
}) => {
  const classes = useStyles();
  
  const cssClasses = (alert, classes) => {
    if (!alert) return;
    let CSSclasses = classes.alert;
    let newClass = "";
    if (alert === "success") {
      newClass = classes.alertSuccess;
    } else if (alert === "info") {
      newClass = classes.alertInfo;
    } else if (alert === "error") {
      newClass = classes.alertError;
    }
    return `${CSSclasses} ${newClass}`;
  };

  return (
    <div>
      <div className={classes.notificationContainer}>
        {notificationsData?.map(
          (
            {
              messageType,
              messageId,
              message,
              primaryReference,
              timestamp,
              requestId,
            },
            index
          ) => (
            <div key={index} className={cssClasses(messageType, classes)}>
              <button
                className={classes.closeIcon}
                onClick={() => handleDelete(messageId)}
              >
                &times;
              </button>
              <div className={classes.notificationMessage}>
                <NotificationMessage
                  message={message}
                  primaryReference={primaryReference}
                  requestId={requestId}
                  className={classes.notificationMessage}
                />
              </div>
              <div className={classes.notificationTimestamp}>
                <span>{timestamp}</span>
              </div>
            </div>
          )
        )}
      </div>

      {notificationCount !== 0 && (
        <button
          className={classes.removeAllNotifications}
          onClick={handleDelete}
        >
          Alle löschen
        </button>
      )}
    </div>
  );
};

export default MDCNotificationDialog;
