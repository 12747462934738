import axios from "axios";
import "regenerator-runtime/runtime";
import { config } from "../config";

const getTokenForWebsocket = () => {
  const headers = {
    // 'Access-Control-Allow-Origin': '*',
    //'Accept': 'application/json',
    //'Content-Type': 'application/json',
    "X-Requested-With": "XMLHttpRequest",
  };
  let url = `${config.apiUrl}/notifications/token-for-websocket`;

  return axios
    .get(url, headers)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
};

const deleteNotifications = async (ids) => {
  let url = `${config.apiUrl}/notifications`;
  try {
    const resp = await axios.delete(url, { data: ids });
    return resp?.status === 204;
  } catch (error) {
    return error;
  }
};

const getGroupStatusMessages = (groupId, tenantAlias) => {
  let url = `${config.apiUrl}/status/broadcast-message/${groupId}/${tenantAlias} `;
  return axios
    .get(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
};

export const notificationService = {
  getTokenForWebsocket,
  deleteNotifications,
  getGroupStatusMessages,
};
