import React from "react";
import MDCGroupNotification from "../../core/MDCGroupNotification";

const StatusServiceMessages = (props) => {
  const { statusServiceData, showMessageDetailDialog } = props;
  const getStatusMessagesList = (statusMessages) => {
    // converting message objects Array into Array of string messages
    return statusMessages.map((msg) => {
      return { title: msg.title, description: msg.description };
    });
  };
  return (
    <>
      {statusServiceData?.length ? (
        statusServiceData.map((serviceInfo, index) => (
          <div key={index}>
            <MDCGroupNotification
              statusType={serviceInfo.messageType}
              statusMessages={getStatusMessagesList(
                serviceInfo?.statusMessages
              )}
              showMessageDetailDialog={showMessageDetailDialog}
            />
          </div>
        ))
      ) : (
        <></>
      )}
    </>
  );
};

export default StatusServiceMessages;
