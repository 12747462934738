import * as React from "react";

const LargeTruckIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} {...props}>
    <g data-name="Certificate loschen" transform="translate(-6013 -6700)">
      <circle
        cx={50}
        cy={50}
        r={50}
        fill="#e8fcff"
        data-name="Ellipse 106"
        transform="translate(6013 6700)"
      />
      <path
        fill="#00c4de"
        d="M6073.931 6744.534h-4.1v-5.465h-19.128a2.741 2.741 0 0 0-2.733 2.733v15.03h2.733a4.1 4.1 0 0 0 8.2 0h8.2a4.1 4.1 0 1 0 8.2 0h2.727V6750Zm-19.129 14.347a2.05 2.05 0 1 1 2.05-2.05 2.047 2.047 0 0 1-2.05 2.05Zm18.446-12.3 2.678 3.416h-6.094v-3.413Zm-2.05 12.3a2.05 2.05 0 1 1 2.05-2.05 2.047 2.047 0 0 1-2.05 2.05Z"
        data-name="Icon material-local-shipping"
      />
    </g>
  </svg>
);

export default LargeTruckIcon;
