import React from "react";
import { connect } from "react-redux";
import carLogo from "../../assets/images/my_digital_car.svg";
import MDCModal from "../../components/core/MDCModal";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import theme from "../../themes/mdc-theme";
import MDCButton from "../../components/core/MDCButton";
import MDCSecondaryTabs from "../../components/core/MDCSecondaryTabs";
import MDCSimpleTable from "../../components/core/MDCSimpleTable";
import MDCLoader from "../../components/core/MDCLoader";
import { vorgangeDetailsActions } from "../../store/actions/vorgange/vorgangeDetailsActions";

const styles = () => ({
  headerCss: {
    padding: "2rem",
    alignItems: "center",
  },
  headerColor: { color: theme.palette.MDCColors.black },
  contentCss: {
    fontFamily: theme.palette.typography.fontFamily,
    color: theme.palette.MDCColors.color15,
    padding: "0 2rem",
  },
});
class DataRegistrationDialog extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      displayPopup: false,
      showLoader: true,
      dataRegistrationsListTeil1: [],
      dataRegistrationsListTeil2: [],
      activeTabValue: 0,
    };
  }

  componentDidMount() {
    this.getDataRegistrationsList();
    this.setState({ displayPopup: this.props.show });
  }
  registrationDataTableColumns = [
    {
      title: "Pos.",
      field: "pos",
      cellStyle: { width: "10%", maxWidth: "10%" },
      headerStyle: { width: "10%", maxWidth: "10%" },
    },
    {
      title: "Registerkategorie",
      field: "registerkategorie",
      cellStyle: { width: "20%", maxWidth: "20%" },
      headerStyle: { width: "20%", maxWidth: "20%" },
    },
    {
      title: "Registerfeld",
      field: "registerfeld",
      cellStyle: { width: "20%", maxWidth: "20%" },
      headerStyle: { width: "20%", maxWidth: "20%" },
    },
    {
      title: "Wert",
      field: "wert",
      cellStyle: { width: "30%", maxWidth: "30%" },
      headerStyle: { width: "30%", maxWidth: "30%" },
    },
  ];
  getDataRegistrationsList = () => {
    if (this.props?.requestId) {
      this.props.getDataRegistrationsList(this.props.requestId).then((resp) => {
        if (resp?.status === 200 && resp?.data) {
          this.setState({
            dataRegistrationsListTeil1:
              resp?.data["Zulassungsbescheinigung Teil 1"],
            dataRegistrationsListTeil2:
              resp?.data["Zulassungsbescheinigung Teil 2"],
          });
        }
      });
    }
  };
  tableOptions = () => {
    return {
      paging: false,
      search: false,
      sorting: false,
      toolbar: false,
      maxBodyHeight: "25rem",
    };
  };
  getActiveTabValue = (value) => {
    this.setState({ activeTabValue: value });
  };
  handleRegisterDatenXML = () => {
    this.props.getRegisterDatenXML(this.props?.requestId).then(() => {
      const xmlData = this.props.vorgangeDetailsReducer.registerDatenXmlData;
      if (xmlData?.status === 200) {
        const url = window.URL.createObjectURL(new Blob([xmlData.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${xmlData.headers["x-suggested-filename"]}`
        );
        document.body.appendChild(link);
        link.click();
      }
    });
  };

  render() {
    const { classes } = this.props;
    const navItems = [
      {
        id: 0,
        tabTitle: "Zulassungsbescheinigung Teil 1",
        tabContent: (
          <MDCSimpleTable
            id={"Data-Registrations-List"}
            columns={this.registrationDataTableColumns}
            data={this.state.dataRegistrationsListTeil1}
            options={this.tableOptions()}
          />
        ),
      },
      {
        id: 1,
        tabTitle: "Zulassungsbescheinigung Teil 2",
        tabContent: (
          <MDCSimpleTable
            id={"Data-Registrations-List"}
            columns={this.registrationDataTableColumns}
            data={this.state.dataRegistrationsListTeil2}
            options={this.tableOptions()}
          />
        ),
      },
    ];
    const getModalHeader = () => {
      return (
        <Grid container className={classes.headerCss}>
          <Grid item xs={8} />
          <Grid item xs={4}>
            <img alt="My Digital Car" src={carLogo} width="95%" />
          </Grid>
        </Grid>
      );
    };
    const getModalBody = () => {
      return (
        <>
          {!(
            this.state.dataRegistrationsListTeil1.length ||
            this.state.dataRegistrationsListTeil2.length
          ) ? (
            <Grid container justifyContent={"center"}>
              <MDCLoader />
            </Grid>
          ) : (
            <div className={classes.contentCss}>
              <MDCSecondaryTabs
                navItems={navItems}
                getActiveTabValue={(value) => this.getActiveTabValue(value)}
              />
            </div>
          )}
        </>
      );
    };
    const getModalFooter = () => {
      return (
        <Grid
          container
          justifyContent={"space-between"}
          className={classes.headerCss}
        >
          <MDCButton
            variant="outlined"
            label={"Schließen"}
            onClick={this.props.handlePopupClose}
          />
          <MDCButton
            variant="contained"
            label={"Download"}
            onClick={() => {
              this.handleRegisterDatenXML();
            }}
          />
        </Grid>
      );
    };
    return (
      <div>
        <MDCModal
          open={this.props.show}
          title={getModalHeader()}
          body={getModalBody()}
          footer={getModalFooter()}
          size="lg"
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { vorgangeDetailsReducer: state.vorgangeDetailsReducer };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDataRegistrationsList: (requestID) =>
      dispatch(vorgangeDetailsActions.getDataRegistrationsList(requestID)),
    getRegisterDatenXML: (requestID) =>
      dispatch(vorgangeDetailsActions.getRegisterDatenXML(requestID)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DataRegistrationDialog));
