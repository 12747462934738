import { NOTIFICATION_CONSTANTS } from "../../../constants/notificationConstants";
import { notificationService } from "../../services/notification/notificationService";

function getTokenForWebsocketSuccess(resp) {
  return {
    type: NOTIFICATION_CONSTANTS.GET_TOKEN_FOR_WEBSOCKET_SUCCESS,
    resp,
  };
}
const getTokenForWebsocket = () => {
  return (dispatch) => {
    return notificationService.getTokenForWebsocket().then((resp) => {
      dispatch(getTokenForWebsocketSuccess(resp));
      return resp;
    });
  };
};

const deleteNotifications = (ids) => {
  return () => {
    return notificationService.deleteNotifications(ids).then((resp) => {
      return resp;
    });
  };
};

const getGroupStatusMessages = (groupId,tenantAlias) => {
  return () => {
    return notificationService.getGroupStatusMessages(groupId,tenantAlias).then((resp) => {
      return resp;
    });
  };
};

export const notificationActions = {
  // Function added for unit test case start
  getTokenForWebsocketSuccess,
  getTokenForWebsocket,
  deleteNotifications,
  getGroupStatusMessages,
};
