export const LicensePlateSizes = [
  {
    label: "Kfz-Kennzeichen 520x110mm (2x)",
    value: "520x110mm-2",
  },
  {
    label: "Kfz-Kennzeichen 520x110mm (3x)",
    value: "520x110mm-3",
  },
];

export const AddressOptions = [
  {
    label: "Adresse des Halters",
    value: "adresseDesHalters",
  },
  {
    label: "Individuelle Adresse eingeben",
    value: "individuelleAddresse",
  },
];

export const weeksOptions = [
  {
    label: "Täglich",
    value: "Täglich",
  },
  {
    label: "Wöchentlich",
    value: "Wöchentlich",
  },
];

export const nameTypeOptions = [
  {
    label: "Unternehmensname",
    value: "juristische",
  },
  {
    label: "Personenname",
    value: "naturliche",
  },
];

export const individualAddressFields = [
  { fieldId: "strasse", fieldLabel: "Straße", maxLength: 40 },
  { fieldId: "hausnummer", fieldLabel: "Nummer", maxLength: 25 },
  { fieldId: "postleitzahl", fieldLabel: "Postleitzahl", maxLength: 10 },
  { fieldId: "stadt", fieldLabel: "Stadt", maxLength: 40 },
];

export const orderLicensePlateConstants = {
  CREATE_ORDER_LICENSE_PLATE_REQUEST_DATA: "CREATE_ORDER_LICENSE_PLATE_REQUEST_DATA",
  CREATE_ORDER_LICENSE_PLATE_SUCCESS: "CREATE_ORDER_LICENSE_PLATE_SUCCESS",
  GET_ORDER_LICENSE_PLATE_SUCCESS: "GET_ORDER_LICENSE_PLATE_SUCCESS",
  DELETE_ORDER_LICENSE_PLATE_SUCCESS: "DELETE_ORDER_LICENSE_PLATE_SUCCESS",
};

export const LICENSE_PLATE_SAVE_INFO = `Bitte klicken Sie „Speichern & Prüfen“, um eine
                    Kennzeichenbestellung auszulösen.`;
export const LICENSE_PLATE_BUTTON_INFO = `Mit dem Klick auf den Button "Hier klicken" gelangen Sie
                      zum Kennzeichenbestellprozess.`;

