import React, { Suspense } from "react";
import { matchPath } from "react-router";

import "react-toastify/dist/ReactToastify.css";

import MDCLoader from "../core/MDCLoader";

//Components
import Header from "./header/Header";
import Footer from "./footer/Footer";
import MDCNotificationToaster from "../core/MDCNotificationToaster";

const pathWithoutHeader = ["/login"]; //route without header

const externalMatch = matchPath(window.location.pathname, {
  path: "/external/:version/vorgang/:primaryReference",
  exact: true,
});

const Layout = ({ children, authentication }) => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            WebkitTransform: "translate(-50%, -50%)",
            transform: "translate(-50%, -50%)",
          }}
        >
          <MDCLoader />
        </div>
      }
    >
      <div>
        <MDCNotificationToaster />

        {pathWithoutHeader?.includes(window.location.pathname) ||
        (externalMatch && externalMatch.params) ? null : (
          <Header />
        )}
        {children}
        {pathWithoutHeader?.includes(
          window.location.pathname
        ) ? null : !authentication?.user ? null : (
          <Footer />
        )}
      </div>
    </Suspense>
  );
};

export default Layout;
