import React from "react";

// Material UI Components
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  defaultCheckBox: {
    "& .MuiSvgIcon-root": { color: theme.palette.MDCColors.color23 },
    "& .MuiTypography-root.MuiFormControlLabel-label": {
      marginLeft: "0.938rem",
      color: theme.palette.MDCColors.color18,
      fontSize: "1rem",
      fontFamily: theme.palette.typography.fontFamily,
      "&.Mui-disabled": {
        color: theme.palette.MDCColors.color18,
      },
    },
  },
  disabledCheckBox: {
    "& .MuiSvgIcon-root": { color: theme.palette.MDCColors.color18 },
  },
  duplicateCheckBox: {
    "& .MuiSvgIcon-root": { color: theme.palette.MDCColors.color34 },
  },
}));

const MDCCheckbox = (props) => {
  const {
    label,
    onChange,
    name,
    checked,
    className,
    disabled,
    inputRef,
    duplicate,
  } = props;
  const { defaultCheckBox, disabledCheckBox, duplicateCheckBox } = useStyles();
  let MDCStyles = defaultCheckBox;

  if (disabled && !duplicate) {
    MDCStyles = `${MDCStyles} ${disabledCheckBox}`;
  }
  if (duplicate) {
    MDCStyles = `${MDCStyles} ${duplicateCheckBox}`;
  }
  
  return (
    <>
      {label ? (
        <FormGroup>
          <FormControlLabel
          componentsProps={{ typography: { variant: 'span' } }}
            control={
              <Checkbox
                name={name}
                label={label}
                checked={checked}
                onChange={onChange}
                className={MDCStyles}
                disabled={disabled}
                inputRef={inputRef}
              />
            }
            label={label}
          />
        </FormGroup>
      ) : (
        <Checkbox
          name={name}
          label={label}
          checked={checked}
          onChange={onChange}
          className={className}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default MDCCheckbox;
