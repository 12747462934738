// Constants
import { vollmachtsdatenbankPOAConstants } from "../../../constants/VollmachtsDatenBankConstants";
import { HTTP_SUCCESS_STATUS_CODES } from "../../../constants/VorgangDetailsConstants";

// Service
import { vollmachtsDatenBankService } from "../../services/userProfile/vollmachtsDatenBankService";

// Helper
import { history } from "../../../helpers/history";

function getvollmachtsDatenBankData(query) {
  return () => {
    return vollmachtsDatenBankService
      .getvollmachtsDatenBankData(query)
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES.includes(response?.status) &&
          response.data
        ) {
          return response;
        }
      });
  };
}
function deletePOARecords(requestIds) {
  return () => {
    return vollmachtsDatenBankService
      .deletePOARecords(requestIds)
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES.includes(response?.status)
        ) {
          return response;
        }
      });
  };
}

function getVollmachtBankDetailsSuccess(resp) {
  return {
    type: vollmachtsdatenbankPOAConstants.GET_VOLLMACHTS_BANK_DETAILS_SUCCESS,
    resp,
  };
}

function getVollmachtBankPOADetails(poaId) {
  return (dispatch) => {
    return vollmachtsDatenBankService
      .getVollmachtBankPOADetails(poaId)
      .then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
          dispatch(getVollmachtBankDetailsSuccess(resp));
          return resp;
        }
      });
  };
}

function createPOARecords(formValuesPOA) {
  return (dispatch) => {
    return vollmachtsDatenBankService
      .createPOARecords(formValuesPOA)
      .then((response) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(response?.status)) {
          dispatch(getVollmachtBankPOADetails(response?.data?.id));
          dispatch(getvollmachtsDatenBankData());
          return response;
        }
      });
  };
}

function updatePOARecords(poaId, formValuesPOA) {
  return (dispatch) => {
    return vollmachtsDatenBankService
      .updatePOARecords(poaId, formValuesPOA)
      .then((response) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(response?.status)) {
          dispatch(getVollmachtBankPOADetails(poaId));
          return response;
        }
      });
  };
}

function updateVollmachtBankForQESSuccess(resp) {
  return {
    type: vollmachtsdatenbankPOAConstants.UPDATE_VOLLMACHTS_BANK_FOR_QES_SUCCESS,
    resp,
  };
}

// Update Bank POA Details for QES
function updateVollmachtBankPOAForQES(data, poaId) {
  return (dispatch) => {
    return vollmachtsDatenBankService
      .updateVollmachtBankPOAForQES(data, poaId)
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES?.includes(response?.status) &&
          response.data
        ) {
          dispatch(updateVollmachtBankForQESSuccess(response));

          if (!Object.keys(response?.data?.validationMessages || {}).length) {
            history.push("/vollmachtsdatenbank");
          }

          return response;
        } else {
          return response.response;
        }
      });
  };
}

function generateVollmachtBankPOAQRCodeSuccess(resp) {
  return {
    type: vollmachtsdatenbankPOAConstants.GENERATE_VOLLMACHTS_BANK_POA_QR_CODE_SUCCESS,
    resp,
  };
}

function generateVollmachtBankPOAQRCode(poaId) {
  return (dispatch) => {
    return vollmachtsDatenBankService
      .generateVollmachtBankPOAQRCode(poaId)
      .then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
          dispatch(generateVollmachtBankPOAQRCodeSuccess(resp));
        }
      });
  };
}

function getVollmachtBankPOAQRCodeSuccess(resp) {
  return {
    type: vollmachtsdatenbankPOAConstants.GET_VOLLMACHTS_BANK_POA_QR_CODE_SUCCESS,
    resp,
  };
}

function getVollmachtBankPOAQRCode(poaId) {
  return (dispatch) => {
    return vollmachtsDatenBankService
      .getVollmachtBankPOAQRCode(poaId)
      .then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
          dispatch(getVollmachtBankPOAQRCodeSuccess(resp));
        }

        if (resp?.status === 204) {
          dispatch(generateVollmachtBankPOAQRCode(poaId));
        }
      });
  };
}

function regenerateVollmachtBankPOAQRCodeSuccess(resp) {
  return {
    type: vollmachtsdatenbankPOAConstants.REGENERATE_VOLLMACHTS_BANK_POA_QR_CODE_SUCCESS,
    resp,
  };
}

function regenerateVollmachtBankPOAQRCode(poaId) {
  return (dispatch) => {
    return vollmachtsDatenBankService
      .regenerateVollmachtBankPOAQRCode(poaId)
      .then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
          dispatch(regenerateVollmachtBankPOAQRCodeSuccess(resp));
        }
      });
  };
}

// Delete POA QES Process Data
function deleteQESForPOARecords(requestId) {
  return () => {
    return vollmachtsDatenBankService
      .deleteQESForPOARecords(requestId)
      .then((response) => {
        if (
          !response?.isAxiosError &&
          HTTP_SUCCESS_STATUS_CODES.includes(response?.status)
        ) {
          return response;
        }
      });
  };
}

function getVollmachtBankSigningStatusSuccess(resp) {
  return {
    type: vollmachtsdatenbankPOAConstants.GET_VOLLMACHTS_BANK_POA_QES_STATUS_SUCCESS,
    resp,
  };
}

function getVollmachtBankSigningStatus(poaId) {
  return (dispatch) => {
    return vollmachtsDatenBankService
      .getVollmachtBankSigningStatus(poaId)
      .then((resp) => {
        if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
          dispatch(getVollmachtBankSigningStatusSuccess(resp));
        }
      });
  };
}

function resetVollmachtBankDetailsData() {
  return {
    type: vollmachtsdatenbankPOAConstants.RESET_VOLLMACHTS_BANK_DETAILS_SUCCESS,
    payload: {},
  };
}

export const VollmachtsDatenBankDataActions = {
  getVollmachtBankDetailsSuccess,
  generateVollmachtBankPOAQRCodeSuccess,
  regenerateVollmachtBankPOAQRCodeSuccess,
  updateVollmachtBankForQESSuccess,
  getVollmachtBankSigningStatusSuccess,
  getVollmachtBankPOAQRCodeSuccess,
  getvollmachtsDatenBankData,
  deletePOARecords,
  createPOARecords,
  getVollmachtBankPOADetails,
  updateVollmachtBankPOAForQES,
  updatePOARecords,
  resetVollmachtBankDetailsData,

  // Vollmacht QES Process
  getVollmachtBankPOAQRCode,
  generateVollmachtBankPOAQRCode,
  regenerateVollmachtBankPOAQRCode,
  getVollmachtBankSigningStatus,

  deleteQESForPOARecords,
};
