import React from "react";

// Material UI Components
import { makeStyles } from "@mui/styles";
import Badge from "@mui/material/Badge";

const useStyles = makeStyles((theme) => ({
  defaultBadgeCSS: {
    cursor: "pointer",
    "& .MuiBadge-badge": {
      color: theme.palette.MDCColors.black,
      backgroundColor: theme.palette.MDCColors.color23,
      padding: 0,
      transform: "scale(1) translate(0%, 7%)",
    },
  },
}));

const MDCBadge = (props) => {
  const { icon, badgeContent } = props;

  const { defaultBadgeCSS } = useStyles();

  return (
    <>
      <Badge badgeContent={badgeContent} className={defaultBadgeCSS}>
        {icon}
      </Badge>
    </>
  );
};

export default MDCBadge;
