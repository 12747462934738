/**
 * vorgangeListService: provides activitylist & other functionality related to vorgange services
 * @ngdoc service
 * @name vorgangeListService
 * @memberof src/store/services
 */
import { config } from "../config";
import axios from "axios";
import moment from "moment";

// utils
import { convertStringToDate } from "../../../helpers/utils";

const dateColumns = ["gewünschtesUmsetzungsdatum", "letzteAnderung"];

/**
 * get vorgange list based on query parameter
 * @memberof vorgangeListService
 * @param {object} query
 */
function getVorgangeList(query, activeTab) {
  let errorFilter = "";

  if (query?.error) {
    errorFilter = `&error=${query?.error}`;
  } else if (query?.duplicate) {
    errorFilter = `&duplicate=${query?.duplicate}`;
  } else if (query?.unread) {
    errorFilter = `&unread=${query?.unread}`;
  } else if (query?.qesCompleted) {
    errorFilter = `&qesCompleted=${query?.qesCompleted}`;
  }

  let url = `${config.apiUrl}/${
    activeTab === "Archiv" ? "housekeeping/archive" : "vorgangs"
  }?size=${query?.pageSize || 50}&page=${query?.page || 0}`;

  if (query?.orderBy && query?.orderDirection !== "") {
    url += `&sort=${query?.orderBy?.field}&order=${query?.orderDirection}`;
  }

  if (errorFilter && activeTab !== "Archiv") {
    url += errorFilter;
  }

  //handle filter
  if (query?.filters && query?.filters?.length > 0) {
    let filters = "";
    query?.filters?.map((filter) => {
      if (filters !== "") {
        filters += "&";
      }
      if (dateColumns?.includes(filter.column.field)) {
        filter.value = moment(filter.value).format("YYYY-MM-DD");
      }
      return (filters += `${filter.column.field}${filter.operator}${filter.value}`);
    });
    url += `&${filters}`;
  }
  return axios
    .get(url, { timeout: 120000 }) //Giving it 120 secs Timeout,because sometimes filtering in BE takes time because of Database Computations
    .then((resp) => {
      if (resp.data) {
        resp.data.content.map((row) => {
          row.letzteAnderung = row.letzteAnderung
            ? convertStringToDate(row.letzteAnderung)
            : "";
          return row;
        });
        return resp.data;
      }
    })
    .catch((error) => {
      return error;
    });
}

/**
 * get featch details when click on vogangelist's row
 * @memberof vorgangeListService
 * @param {string} id
 */
function getPullRequestDetails(id) {
  const url = `${config.apiUrl}/iteos/${id}/pull-request-response`;

  return axios
    .get(url)
    .then((resp) => {
      if (resp.data && resp.data.dateTime) {
        resp.data.dateTime = moment(resp.data.dateTime).format(
          "DD.MM.YYYY HH:MM:SS"
        );
      }
      return resp;
    })
    .catch((error) => {
      return error;
    });
}
/**
 * export row details in xml
 * @memberof vorgangeListService
 * @param {string} id
 */
function exportDetailsInXml(id) {
  const url = `${config.apiUrl}/vorgangs/${id}/xml`;
  return axios
    .get(url, { responseType: "blob" })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error;
    });
}

function readRecord(ids) {
  const url = `${config.apiUrl}/vorgangs/read`;
  return axios
    .put(url, ids)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

async function requestXmlLog(requestId) {
  const url = `${config.apiUrl}/vorgangs/${requestId}/access-artifacts`;

  try {
    const resp = await axios
      .put(url);
    return resp;
  } catch (error) {
    return error;
  }
}

async function getSupportKBA(kbaSupportId) {
  const url = `${config.apiUrl}/kba-support/${kbaSupportId}`;

  try {
    const resp = await axios
      .get(url);
    return resp.data;
  } catch (error) {
    return error;
  }
}

// exports the object of methods
export const vorgangeListService = {
  getVorgangeList,
  getPullRequestDetails,
  exportDetailsInXml,
  readRecord,
  requestXmlLog,
  getSupportKBA
};
