import * as React from "react"

function EditIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20.297}
      height={23.532}
      {...props}
    >
      <g data-name="Gruppe 3603">
        <g data-name="Gruppe 3602" fill="none">
          <path
            data-name="Pfad 775"
            d="M12.92 2.67L18 6.36l-8.06 11.09a11.878 11.878 0 00-2.228-2.208 12.97 12.97 0 00-2.852-1.484z"
            stroke="currentColor"
            strokeLinejoin="bevel"
            strokeWidth={2.00022}
          />
          <g
            data-name="Ellipse 67"
            transform="rotate(36 6.348 21.067)"
            stroke="currentColor"
            strokeWidth={2}
          >
            <ellipse cx={4} cy={2.5} rx={4} ry={2.5} stroke="none" />
            <ellipse cx={4} cy={2.5} rx={3} ry={1.5} />
          </g>
          <g data-name="Pfad 774">
            <path d="M4.036 21.263a.657.657 0 01-.989-.718l.517-6.541c.097-.746 1.342-1.847 1.84-1.486a6.328 6.328 0 013.59.853 6.513 6.513 0 011.95 3.174c.497.361-.152 1.895-.832 2.218z" />
            <path
              d="M5.206 18.619l3.803-1.565v-.001c-.135-.492-.6-1.634-1.192-2.064-.584-.425-1.783-.504-2.279-.476H5.53l-.324 4.106m-1.909 2.62c-.186-.135-.29-.382-.25-.694l.517-6.541c.097-.747 1.341-1.848 1.839-1.486 0 0 2.205-.154 3.59.853 1.385 1.007 1.95 3.174 1.95 3.174.498.361-.151 1.895-.831 2.218l-6.077 2.5c-.284.135-.551.112-.738-.024z"
              fill="currentColor"
            />
          </g>
          <path
            data-name="Pfad 776"
            d="M4.316 18.678s1.287-.672 1.241.902"
            stroke="currentColor"
            strokeWidth={2.00022}
          />
        </g>
      </g>
    </svg>
  )
}

export default EditIcon
