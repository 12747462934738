import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  errorTextCss: {
    color: "red",
    marginTop: ".4rem",
    marginBottom: ".4rem",
  },
  marginTopCss: {
    color: "red",
    marginTop: "0",
  },
});
function ErrorText(props) {
  const { id, error, isRemoveMarginTop } = { ...props };
  const { errorTextCss, marginTopCss } = useStyles();

  const styles = isRemoveMarginTop ? marginTopCss : errorTextCss;

  return (
    <div className={styles} id={id}>
      {error}
    </div>
  );
}

export default ErrorText;
