import { Grid } from "@mui/material";

// Core Components
import CustomMenuFilter from "../components/core/CustomMenuFilter";
import MDCTooltip from "../components/core/MDCTooltip";

// Theme
import theme from "../themes/mdc-theme";

// Constants
import { userStatusFilterOption } from "./TenantUserConstants";

export const FORM_CONTROL_LABELS = {
  LOGO: "Logo Upload",
  ZUORDNUNG: "Mandantenzuordnung",
  INFORMATIONEN: "Mandanteninformationen",
  DIENSTLEISTUNG: "Für Dienstleistungsgebühr",
  ZULLAS: "Für behördliche Zulassungsgebühren",
  EINGEN: "Für die KFZ Steuer bei Eigenzulassungen",
};
export const VERIMI_ADMIN_CONSTANTS = {
  GET_LOGO_SUCCESS: "GET_LOGO_SUCCESS",
  GET_DETAIL_SUCCESS: "GET_DETAIL_SUCCESS",
  UPDATE_DETAIL_SUCCESS: "UPDATE_DETAIL_SUCCESS",
  GET_OPERATION_IDS_SUCCESS: "GET_OPERATION_IDS_SUCCESS",
  GET_EMPLOYEE_IDS_SUCCESS: "GET_EMPLOYEE_IDS_SUCCESS",
  GET_OPERATION_IDS_ASSOCIATED_TO_EMPLOYEE_ID_SUCCESS:
    "GET_OPERATION_IDS_ASSOCIATED_TO_EMPLOYEE_ID_SUCCESS",
  GET_EMPLOYEE_IDS_ASSOCIATED_TO_OPERATION_ID_SUCCESS:
    "GET_EMPLOYEE_IDS_ASSOCIATED_TO_OPERATION_ID_SUCCESS",
  GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
  GET_WEBHOOK_DETAILS_SUCCESS: "GET_WEBHOOK_DETAILS_SUCCESS",
  TEST_WEBHOOK_DETAILS_SUCCESS: "TEST_WEBHOOK_DETAILS_SUCCESS",
  SUBMIT_WEBHOOK_DETAILS_SUCCESS: "SUBMIT_WEBHOOK_DETAILS_SUCCESS",
  GET_NACHRICHTEN_DATA_SUCCESS: "GET_NACHRICHTEN_DATA_SUCCESS",
  GET_USER_GROUPS_SUCCESS: "GET_USER_GROUPS_SUCCESS",
  CREATE_NACHRICHTEN_SUCCESS: "CREATE_NACHRICHTEN_SUCCESS",
  AUTOFORWARD_USERDETAILS_SUCCESS: "AUTOFORWARD_USERDETAILS_SUCCESS",
  REGISTRATION_TYPES_LIST: "REGISTRATION_TYPES_LIST",
};

export const PAGE_CONSTANTS = {
  logoSize: 5242880,
  logoPixel: 200,
  dealerNumberLength: 15,
  fieldLength: 255,
  keine: "Keine (None)",
  strasse: "strasse",
  nummer: "nummer",
  checkboxLabelTax:
    "Entspricht der Bankverbindung für die Dienstleisungsgebühr",
  checkboxLabelHolder:
    "Entspricht der Bankverbindung für behördlichen Zulassungsgebühren",
};

export const LOGO_DETAILS = ["- .png/.jpg/.jpeg/.svg", "- Maximal 5 MB"];

export const webhookTestSuccessMessage = `Der Webhook-Test war erfolgreich. Mit dem Klick auf "Bestätigen" werden die Webhook-Einstellungen gespeichert.`;
export const webhookDuplicateHeaderError =
  "Bitte geben Sie unterschiedliche Kopfzeilen (Überschriften) ein.";

export const CARD_TYPE = {
  service: "serviceBankdata",
  tax: "taxBankdata",
  holder: "holderBankdata",
  client: "CLIENT",
};

export const ALLOWED_EXTENSIONS = [
  "jpg",
  "png",
  "JPG",
  "PNG",
  "svg",
  "SVG",
  "jpeg",
  "JPEG",
];
export const GKS_CERTIFICATE_ALLOWED_EXTENSIONS = ["jks", "p12"];

export const CLIENT_COLUMNS = [
  { id: "groupMember", label: "Gruppenzugehörigkeit" },
  { id: "dealerNumber", label: "Händlernummer / Betriebsnummer" },
  { id: "markenkennung", label: "Markenkennung" },
  { id: "dealerName", label: "Unternehmenszuordnung" },
  { id: "majorCustomerId", label: "Großkunden-ID vorhanden?" },
];

export const TENANT_COLUMNS = [
  { id: "tenantName", label: "Name des Unternehmens" },
  { id: "strasse", label: "Straße" },
  { id: "nummer", label: "Nummer" },
  { id: "postleitzahl", label: "Postleitzahl" },
  { id: "ort", label: "Stadt" },
];

// For tenant details page - 1st tab in the mandan Information
export const TENANT_ADDRESS_DETAILS = [
  { id: "tenantName", label: "Name des Unternehmens", maxLength: 60 },
  { id: "strasse", label: "Straße", maxLength: 40 },
  { id: "nummer", label: "Nummer", maxLength: 25 },
  { id: "postleitzahl", label: "Postleitzahl", maxLength: 10 },
  { id: "ort", label: "Stadt", maxLength: 40 },
];

// Group Details - 1st tab in the mandan Information
export const GROUP_DETAILS = [
  { id: "groupName", label: "Name der Gruppe" },
  { id: "groupId", label: "Gruppen-ID" },
];

export const BANK_COLUMNS = [
  { id: "kontoinhaber", label: "Kontoinhaber", maxLength: 70 },
  { id: "iban", label: "IBAN", maxLength: 34 },
  { id: "kreditinstitut", label: "Kreditinstitut", maxLength: 255 },
  { id: "bic", label: "BIC", maxLength: 11 },
];

export const GROUPS = [
  { label: "Volkswagen Gruppe", value: "Volkswagen Gruppe" },
  { label: "BMW Gruppe", value: "BMW Gruppe" },
  { label: "Daimler AG", value: "Daimler AG" },
  { label: "Keine (None)", value: "Keine (None)" },
];

export const ADMIN_ERROR = {
  dealerNumberLength:
    "Die Händlernummer darf nicht länger als 15 Zeichen sein. Bitte überprüfen Sie Ihre Eingabe.",
  dealerNumberMissing:
    "Die Händlernummer darf nicht leer sein oder Leerzeichen enthalten. Bitte überprüfen Sie Ihre Eingabe.",
  notEmpty:
    "Die Marken dürfen nicht leer sein. Bitte überprüfen Sie Ihren Eintrag.",
  fieldLength: "Ihre Eingabe darf nur aus 255 Zeichen bestehen",
  kkError: "Die Eingabe kann maximal aus 255 Stellen bestehen.",
  fileType: "Bitte laden Sie ein den Vorgaben entsprechendes Logo hoch.",
  fileSize:
    "Bitte laden Sie ein Logo entsprechend der Angaben hoch. Die maximale Größe des Logos darf 5 MB betragen.",
  filePixel: "Die Datei sollte mindestens 200 x 200 Pixel groß sein.",
};
export const TAB_NAMES = {
  MANDANTENINFORMATIONEN: "MANDANTENINFORMATIONEN",
  BANKVERBINDUNGEN: "BANKVERBINDUNGEN",
  BETRIEBSNUMMERN: "BETRIEBSNUMMERN",
  BETRIEBSVERWALTUNG: "BETRIEBSVERWALTUNG",
  MITARBEITERVERWALTUNG: "MITARBEITERVERWALTUNG",
  KBA: "KBA",
  EINSTELLUNGEN: "EINSTELLUNGEN",
};
export const CERTIFICATE_INSTRUCTIONS = [
  "Sie benötigen ein Zertifikat, um bei den \n Zulassungsstellen Ihre Großkundennummer \n zu verwenden. Ein Zertifikat ist eine \n technische Beglaubigung Ihrer \n Zulassungsvorgänge.",
  "Sollten Sie kein Zertifikat besitzen,\n können wir Ihnen dieses erstellen.",
  "Besitzen Sie bereits ein Zertifikat,\n laden Sie dieses bitte über die \n Upload-Funktion hoch.",
];
export const CERTIFICATE_ERROR =
  "Um die Antwort zu ändern, müssen sie erst das Zertifikat löschen.";
export const GKS_CERTIFICATE_UPLOAD_ERRORS = [
  "Es ist nur die Erweiterung jks zulässig",
  "Die Zertifikatsgröße sollte weniger als 500 KB betragen",
];

export const employeeIDsTableColumns = [
  {
    title: "Mitarbeiter:innenname",
    field: "employeeName",
    cellStyle: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      padding: "0.8rem",
      maxWidth: "0rem",
      wordWrap: "break-word",
      fontFamily: theme.palette.typography.fontFamily,
      fontSize: "1rem",
    },
    render: (rowData) => (
      <MDCTooltip title={rowData.employeeName}>
        <span>{rowData.employeeName}</span>
      </MDCTooltip>
    ),
  },
  {
    title: "Mitarbeiter:innennr.",
    field: "employeeId",
    cellStyle: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      padding: "0.8rem",
      maxWidth: "0rem",
      wordWrap: "break-word",
      color: theme.palette.MDCColors.textColor,
      fontFamily: theme.palette.typography.fontFamily,
      fontSize: "1rem",
    },
  },
];
export const operationIDsTableColumns = [
  {
    title: "Betriebsname",
    field: "operationName",
    cellStyle: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      padding: "0.8rem",
      maxWidth: "0rem",
      wordWrap: "break-word",
      fontFamily: theme.palette.typography.fontFamily,
      fontSize: "1rem",
    },
    render: (rowData) => (
      <MDCTooltip title={rowData.operationName}>
        <span>{rowData.operationName}</span>
      </MDCTooltip>
    ),
  },
  {
    title: "Betriebsnummer",
    field: "operationId",
    cellStyle: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      padding: "0.8rem",
      maxWidth: "0rem",
      wordWrap: "break-word",
      fontFamily: theme.palette.typography.fontFamily,
      fontSize: "1rem",
    },
  },
];

export const ADDRESS_COLUMNS_LABELS = [
  { label: "Betriebsname", name: "operationName", maxLength: 150 },
  { label: "Betriebsnummer", name: "operationId", maxLength: 20 },
  { label: "Straße", name: "strasse", maxLength: 40 },
  { label: "Nummer", name: "nummer", maxLength: 25 },
  { label: "Postleitzahl", name: "postleitzahl", maxLength: 10 },
  { label: "Stadt", name: "ort", maxLength: 40 },
];

export const userDetailsTableColumns = [
  {
    title: "Mitarbeiternummer",
    field: "employeeId",
    filterPlaceholder: "Suche",
    cellStyle: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "2rem",
    },
  },
  {
    title: "Vorname",
    field: "firstName",
    filterPlaceholder: "Suche",
    cellStyle: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "2rem",
    },
  },
  {
    title: "Nachname",
    field: "lastName",
    filterPlaceholder: "Suche",
    cellStyle: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "2rem",
    },
  },
  {
    title: "E-Mail Adresse",
    field: "empEmailId",
    filterPlaceholder: "Suche",
    cellStyle: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "2rem",
    },
  },
  {
    title: "Rolle",
    field: "roles",
    filterPlaceholder: "Suche",
    cellStyle: { paddingLeft: "2rem" },
  },
  {
    title: "Status",
    field: "enabled",
    filterPlaceholder: "Suche",
    cellStyle: { paddingLeft: "2rem" },
    lookup: { ...userStatusFilterOption },
    filterComponent: (props) => (
      <Grid width="10rem">
        <CustomMenuFilter {...props} />
      </Grid>
    ),
    render: (rowData) => (
      <>
        <span
          style={{
            color:
              rowData?.enabled === "Aktiv"
                ? "green"
                : rowData?.enabled === "Gesperrt"
                ? "red"
                : "",
          }}
        >
          {rowData.enabled}
        </span>
      </>
    ),
  },
];

export const nachrichtenTableColumns = [
  {
    title: "Titel",
    field: "title",
    filterPlaceholder: "Suche",
    cellStyle: { paddingLeft: "2rem" },
  },
  {
    title: "Gruppe",
    field: "groupId",
    filterPlaceholder: "Suche",
    cellStyle: { paddingLeft: "2rem" },
  },
  {
    title: "Mandant",
    field: "tenants",
    filterPlaceholder: "Suche",
    cellStyle: { paddingLeft: "2rem" },
  },
  {
    title: "Status",
    field: "status",
    filterPlaceholder: "Suche",
    cellStyle: { paddingLeft: "2rem" },
    // lookup: { ...messageStatusFilterOption },
    // filterComponent: (props) => (
    //   <Grid width="10rem">
    //     <CustomMenuFilter {...props} />
    //   </Grid>
    // ),
  },
  {
    title: "Letzte Änderung",
    field: "letzteAnderung",
    filterPlaceholder: "Suche",
    cellStyle: { paddingLeft: "2rem" },
  },
];

export const USER_DETAILS_LABELS = [
  { label: "Mitarbeiternummer", name: "employeeId", maxLength: 50 },
  { label: "Vorname (optional)", name: "firstName", maxLength: 60 },
  { label: "Nachname", name: "lastName", maxLength: 45 },
  { label: "E-Mail Adresse", name: "empEmailId", maxLength: 200 },
  { label: "Rolle", name: "groups", maxLength: 50 },
];
export const AutomatischSwitchingMessages = {
  false: `Mit dem Klick auf "Ja" wird die automatische Weiterleitung initiiert.`,
  true: `Mit dem Klick auf "Nein"  wird die automatische Weiterleitung gestoppt.`,
  createUserInfo:
    "Bitte erstellen Sie einen User für die automatische Weiterleitung",
};

export const AutomatischEinreichenUserLabels = [
  { label: "Vorname", name: "firstName", maxLength: 60 },
  { label: "Nachname", name: "lastName", maxLength: 45 },
  { label: "Mitarbeiternummer", name: "employeeId", maxLength: 50 },
];

export const yesORnoBooleanOptions = [
  {
    label: "Ja",
    value: true,
  },
  {
    label: "Nein",
    value: false,
  },
];

export const initialWebhookDetails = {
  active: true,
  webhookUrl: "",
  authType: "Http custom headers",
  userName: "",
  passcode: "",
  headers: {},
  errorMessage: "",
};

export const statusOptions = [
  {
    label: "Aktivieren",
    value: true,
  },
  {
    label: "Deaktivieren",
    value: false,
  },
];

export const authenticationOptions = [
  {
    label: "Basic authentication",
    value: "Basic authentication",
  },
  {
    label: "Http custom headers",
    value: "Http custom headers",
  },
];

export const deactivateUserValidation =
  "Kundendeaktivierung bei eingeschalteter automatischer Weiterleitung nicht möglich.";

export const disableAutoForwardValidation =
  "Bitte aktivieren Sie zuerst die Kundenbearbeitung, um die automatische Weiterleitung zu aktivieren.";

export const disableEditUser =
  "Kundenbearbeitung bei eingeschalteter automatischer Weiterleitung nicht möglich.";

export const createAPIUserInfo =
  "Bitte erstellen Sie einen API-User, um die automatische Weiterleitung zu aktivieren.";

export const orderLicensePlateInfo =
  "Sind Sie sicher, dass Sie den Abschnitt zum Bestellen von Nummernschildern aktivieren möchten?";

export const initialDialogDetails = { isOpenDialog: false, dialogType: "" };

export const dialogTypesForSettingsTab = {
  user: "user",
  autoForward: "autoForward",
  registrationTypes: "registrationTypes",
  orderLicensePlate: "orderLicensePlate",
};
