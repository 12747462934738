import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  defaultDropDownCSS: {
    marginTop: "1rem",
    "& .MuiOutlinedInput-root": {
      padding: "2px !important",
      color: theme.palette.MDCColors.color18,
      fontWeight: "initial !important",
    },
    "& .MuiAutocomplete-root": {
      border: `2px solid ${theme.palette.MDCColors.color23}`,
      borderRadius: "5px",
      background: theme.palette.MDCColors.color21,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  errorText: {
    "& .MuiAutocomplete-root": {
      border: `2px solid ${theme.palette.MDCColors.errorDanger}`,
      background: "rgba(236, 88, 103, 0.3)",
    },
  },
  disabledMenu: {
    "& .MuiAutocomplete-root": {
      border: "0px",
    },
  },
}));

const MDCAutoCompleteDropdown = (props) => {
  const { options, value, onChange, error, disabled } = props;
  const { defaultDropDownCSS, errorText, disabledMenu } = useStyles();
  const emptyValue = { label: "", value: "" };
  const [selectedValue, setSelectedValue] = useState(value || emptyValue);

  const onValueChange = (newValue) => {
    //providing the empty object value in case of null selected option to make it React controlled component(P.S.-To prevent uncontrolled component warning in Console)
    setSelectedValue(newValue === null ? emptyValue : newValue);
    onChange(newValue === null ? emptyValue : newValue);
  };
  
  let MDCStyles = defaultDropDownCSS;
  if (error) {
    MDCStyles = `${MDCStyles} ${errorText}`;
  }

  if (disabled) {
    MDCStyles = `${MDCStyles} ${disabledMenu}`;
  }

  return (
    <>
      <div className={MDCStyles}>
        <Autocomplete
          autoComplete={false}
          disablePortal
          disabled={disabled}
          value={selectedValue}
          onChange={(e, newValue) => onValueChange(newValue)}
          options={options || []}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Bitte wählen Sie ein Exportland aus"
            />
          )}
        />
      </div>
    </>
  );
};

export default MDCAutoCompleteDropdown;
