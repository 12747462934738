import React from "react";
import PropTypes from "prop-types";

// Material UI Components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const useStyles = makeStyles((theme) => ({
  modalCSS: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.MDCColors.white,
    p: 0,
  },
  detailDialogWidth: {
    top: "54%",
    width: "1500px",
  },
  modalContent: {
    overflowY: "scroll",
  },
  width1200: {
    width: "1200px",
  },
  contentScroll: {
    maxHeight: "35rem",
    overflow: "auto",
  },
}));

const MDCModal = (props) => {
  const {
    id,
    open,
    title,
    body,
    footer,
    minWidth,
    isDetailDialog,
    size,
    isScroll,
  } = props;

  const {
    modalCSS,
    detailDialogWidth,
    width1200,
    modalContent,
    contentScroll,
  } = useStyles();

  let width;
  if (size === "sm") {
    width = "35%";
  } else if (size === "md") {
    width = "45%";
  } else if (size === "lg") {
    width = "60%";
  } else if (size === "xl") {
    width = "95%";
  }

  let modalWidthSx = "";
  if (minWidth) {
    modalWidthSx = { minWidth: `${minWidth ? minWidth : "1200px"}` };
  } else if (width) {
    modalWidthSx = { maxWidth: `${width ? width : "1200px"}` };
  }

  let modalStyles = modalCSS;
  if (isDetailDialog) {
    modalStyles = `${modalCSS} ${detailDialogWidth}`;
  } else if (!minWidth) {
    modalStyles = `${modalCSS} ${width1200}`;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      id={id}
    >
      <Box className={modalStyles} sx={modalWidthSx}>
        {/* //Added component={"span"} props to prevent inspect element Console log error*/}
        {title && (
          <Typography component={"span"} id="modal-modal-title">
            {title}
          </Typography>
        )}
        {body && (
          <Typography
            component={"span"}
            id="modal-modal-description"
            sx={id === "details-modal" ? { height: "72vh" } : {}}
            className={id === "details-modal" ? modalContent : ""}
          >
            <div className={isScroll ? contentScroll : ""}>{body}</div>
          </Typography>
        )}
        {footer && (
          <Typography component={"span"} id="modal-modal-footer">
            {footer}
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

MDCModal.prototype = {
  id: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.element,
  body: PropTypes.element,
  footer: PropTypes.element,
  minWidth: PropTypes.string,
  isDetailDialog: PropTypes.bool,
  size: PropTypes.string,
};

export default MDCModal;
