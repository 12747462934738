import * as React from "react";

function FileUploadIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21.788}
      height={16.116}
      {...props}
    >
      <g data-name="Komponente 135 \u2013 1" strokeLinecap="round">
        <g data-name="Pfad 397" fill="currentColor" strokeLinejoin="round">
          <path d="M13.49 5.244c-.87-.312-1.64-.59-1.828-.664-.28-.146-.54-.055-.754.02a360.233 360.233 0 00-1.734.625l2.152-3.726 2.163 3.745z" />
          <path d="M11.805 3.827L11.325 3l-.48.832c.238-.069.584-.13.96-.004m3.357 2.813s-3.838-1.37-3.837-1.389c.001-.018-3.832 1.386-3.832 1.386L11.325 0l3.837 6.64z" />
        </g>
        <path
          data-name="Pfad 398"
          d="M.755 5.748v7.939a1.89 1.89 0 001.986 1.678s16.688-.027 16.6 0c1.551 0 1.7-1.41 1.7-2.257-.007.023 0-7.36 0-7.36"
          fill="none"
          stroke="currentColor"
          strokeWidth={1.5}
        />
        <path
          data-name="Pfad 400"
          d="M11.298 12.603v-7.51"
          fill="none"
          stroke="currentColor"
          strokeWidth={1.5}
        />
      </g>
    </svg>
  );
}

export default FileUploadIcon;
