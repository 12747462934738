// Constants
import { VERIMI_ADMIN_CONSTANTS } from "../../../constants/verimiAdminConstants";

const initialState = {
  isTenantUserFetching: false,
  registrationTypesList: [],
};

export default (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case VERIMI_ADMIN_CONSTANTS.GET_LOGO_SUCCESS:
      return Object.assign({}, state, {
        logo: action.logo,
      });
    case VERIMI_ADMIN_CONSTANTS.GET_DETAIL_SUCCESS:
    case VERIMI_ADMIN_CONSTANTS.UPDATE_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        details: action.details,
      });
    case VERIMI_ADMIN_CONSTANTS.GET_OPERATION_IDS_SUCCESS:
      return Object.assign({}, state, {
        operationIDs: action.details,
      });
    case VERIMI_ADMIN_CONSTANTS.GET_EMPLOYEE_IDS_SUCCESS:
      return Object.assign({}, state, {
        employeeIDs: action.details,
      });
    case VERIMI_ADMIN_CONSTANTS.GET_USER_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        tenantUsers: action.details,
        isTenantUserFetching: false,
      });
    case VERIMI_ADMIN_CONSTANTS.GET_WEBHOOK_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        webhookDetails: action.details,
      });
    case VERIMI_ADMIN_CONSTANTS.TEST_WEBHOOK_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        webhookTestDetails: action.details,
      });
    case VERIMI_ADMIN_CONSTANTS.SUBMIT_WEBHOOK_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        webhookSubmitDetails: action.details,
      });
    case VERIMI_ADMIN_CONSTANTS.GET_NACHRICHTEN_DATA_SUCCESS:
      return Object.assign({}, state, {
        nachrichtenData: action.details,
      });
    case VERIMI_ADMIN_CONSTANTS.GET_USER_GROUPS_SUCCESS:
      return Object.assign({}, state, {
        userGroupsData: action.details,
      });
    case VERIMI_ADMIN_CONSTANTS.CREATE_NACHRICHTEN_SUCCESS:
      return Object.assign({}, state, {
        nachrichtenDetails: action.details,
      });
    case VERIMI_ADMIN_CONSTANTS.GET_OPERATION_IDS_ASSOCIATED_TO_EMPLOYEE_ID_SUCCESS:
      return Object.assign({}, state, {
        opIdsLinkedWithEmpIDs: action.details,
      });
    case VERIMI_ADMIN_CONSTANTS.GET_EMPLOYEE_IDS_ASSOCIATED_TO_OPERATION_ID_SUCCESS:
      return Object.assign({}, state, {
        empIdsLinkedWithOpIds: action.details,
      });
    case VERIMI_ADMIN_CONSTANTS.AUTOFORWARD_USERDETAILS_SUCCESS:
      return Object.assign({}, state, {
        autoForwardUserDetails: action.details,
      });
    case VERIMI_ADMIN_CONSTANTS.REGISTRATION_TYPES_LIST:
      return Object.assign({}, state, {
        registrationTypesList: action.details,
      });
    default:
      return state;
  }
};
