import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";

// Helper
import { history } from "../../helpers/history";

// Service
import { globalAlertService } from "../../store/services/shared/globalAlertService";

// MUI Library
import Grid from "@mui/material/Grid";

// Core Components
import MDCGlobalAlertError from "../core/MDCGlobalAlertError";

import {
  alertCSS,
  alertErrorCSS,
  alertSuccessCSS,
  alertInfoCSS,
  closeIconCSS,
} from "../shared/AlertCSS";

const propTypes = {
  id: PropTypes.string,
  fade: PropTypes.bool,
  closeIcon: PropTypes.bool,
};

const defaultProps = {
  id: "default-alert",
  fade: true,
  closeIcon: true,
};

const styles = () => ({
  alert: alertCSS,
  alertError: alertErrorCSS,
  alertSuccess: alertSuccessCSS,
  alertInfo: alertInfoCSS,
  closeIcon: closeIconCSS,
});

class GlobalAlert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alerts: [],
      showError: false,
    };
  }

  componentDidMount() {
    // subscribe to new alert notifications
    this.subscription = globalAlertService
      .onAlert(this.props.id)
      .subscribe((alert) => {
        // clear alerts when an empty alert is received
        if (!alert.message) {
          // filter out alerts without 'keepAfterRouteChange' flag
          const alerts = this.state.alerts?.filter(
            (x) => x.keepAfterRouteChange
          );

          // remove 'keepAfterRouteChange' flag on the rest
          alerts.forEach((x) => delete x.keepAfterRouteChange);

          this.setState({ alerts });
          return;
        }

        // add alert to array
        this.setState({ alerts: [...this.state.alerts, alert] });
        // auto close alert if required
        if (alert.autoClose) {
          setTimeout(() => this.removeAlert(alert), 3000);
        }
      });

    // clear alerts on location change
    this.historyUnlisten = history.listen(() => {
      globalAlertService.clear(this.props.id);
    });
  }

  componentWillUnmount() {
    // unsubscribe & unlisten to avoid memory leaks
    this.subscription.unsubscribe();
    this.historyUnlisten();
  }  

  cssClasses = (alert) => {
    const { classes } = this.props;
    if (!alert) return;
    let CSSclasses = classes.alert;
    let newClass = "";

    if (alert?.type === "success") {
      newClass = classes.alertSuccess;
    } else if (alert?.type === "info") {
      newClass = classes.alertInfo;
    } else if (alert?.type === "error") {
      newClass = classes.alertError;
    }

    return `${CSSclasses} ${newClass}`;
  };

  removeAlert = (alert) => {
    if (this.props.fade) {
      // fade out alert
      const alertWithFade = { ...alert, fade: true };
      this.setState({
        alerts: this.state.alerts.map((x) => (x === alert ? alertWithFade : x)),
      });

      // remove alert after faded out
      setTimeout(() => {
        this.setState({
          alerts: this.state.alerts?.filter((x) => x !== alertWithFade),
        });
      }, 250);
    } else {
      // remove alert
      this.setState({ alerts: this.state.alerts?.filter((x) => x !== alert) });
    }
  };

  render() {
    const { alerts } = this.state;
    const { handleNotification } = this.props;

    return (
      alerts?.length > 0 && (
        <Grid container marginTop="1rem">
          <MDCGlobalAlertError
            removeAlert={this.removeAlert}
            cssClasses={this.cssClasses}
            certificateDetails={this.props?.certificateDetails}
            userAuthority={this.props?.userAuthority}
            handleNotification={handleNotification}
            alerts={alerts}
          />
        </Grid>
      )
    );
  }
}

GlobalAlert.propTypes = propTypes;
GlobalAlert.defaultProps = defaultProps;
export default withStyles(styles)(GlobalAlert);
