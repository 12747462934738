import Grid from "@mui/material/Grid";
import MDCLabel from "../../core/MDCLabel";
import PersonIcon from "../../iconComponents/PersonIcon";
const RepresentativeInfo = ({ personData }) => {
  return (
    <Grid container backgroundColor="#E6E6E64D" padding="1rem">
      <Grid item xs={10.5}>
        {personData.map((info) => {
          return (
            <Grid marginY={".5rem"} width="100%">
              <MDCLabel label={info} />
            </Grid>
          );
        })}
      </Grid>
      <Grid item alignSelf={"center"} xs={1.5}>
        <PersonIcon />
      </Grid>
    </Grid>
  );
};

export default RepresentativeInfo;
