import { manualRequestFormConstants } from "../../../constants/ManualRequestFormConstants";

const initialState = {};

export default (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case manualRequestFormConstants.CREATE_MANUAL_ENTRY_SUCCESS:
      return Object.assign({}, state, {
        manualRequestFormDetails: action.manualRequestFormDetails,
      });
    case manualRequestFormConstants.VOLLMACHT_TAB_VALIDATION_SUCCESS:
      return Object.assign({}, state, {
        vollmachtTabStatus: action.resp.data.qesValidationResult,
      });
    case manualRequestFormConstants.VOLLMACHT_TAB_QRCODE_SUCCESS:
      return Object.assign({}, state, {
        QRCode: action.resp.data,
      });
    case manualRequestFormConstants.VOLLMACHT_SIGNING_STATUS_SUCCESS:
      return Object.assign({}, state, {
        vollmachtSigningStatus: action.resp.data,
      });
    case manualRequestFormConstants.VOLLMACHT_SIGNED_SEPA_SUCCESS:
      return Object.assign({}, state, {
        sepaDocument: action.resp,
      });
    case manualRequestFormConstants.VOLLMACHT_SIGNED_POA_SUCCESS:
      return Object.assign({}, state, {
        poaDocument: action.resp,
      });
    case manualRequestFormConstants.VOLLMACHT_SIGNED_PROCESS_RESET:
      return Object.assign({}, state, action.resp);
    case manualRequestFormConstants.DOWNLOAD_WIRTSCHAFTS_PDF_SUCCESS:
      return Object.assign({}, state, { wirtschaftsPDF: action.resp });
    case manualRequestFormConstants.GET_WIRTSCHAFTS_DATA_SUCCESS:
      return Object.assign({}, state, { wirtschaftsData: action.resp });
    case manualRequestFormConstants.GET_STEUERBEFREIUNG_DATA_SUCCESS:
      return Object.assign({}, state, { steuerbefreiungData: action.resp });
    case manualRequestFormConstants.ZB_TEIL1_DOC_SUCCESS:
      return Object.assign({}, state, { zbTeil1PDF: action.resp });
    case manualRequestFormConstants.ZB_TEIL2_DOC_SUCCESS:
      return Object.assign({}, state, { zbTeil2PDF: action.resp });
    default:
      return state;
  }
};
