import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { render } from "react-dom";

// Config
import { config } from "./store/services/config";

// Redux
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";

import { App } from "../src/App";
import thunk from "redux-thunk";

import rootReducer from "./store/reducers/rootReducer";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

// Redux DevTools
const composeEnhancers =
  (config.buildEnv === "DEV"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("app")
);
