// Constants
import { vollmachtsdatenbankPOAConstants } from "../../constants/VollmachtsDatenBankConstants";

const initialState = {};

export default (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case vollmachtsdatenbankPOAConstants.GET_VOLLMACHTS_BANK_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        vollmachtsdatenbankPOADetails: action.resp.data,
      });
    case vollmachtsdatenbankPOAConstants.UPDATE_VOLLMACHTS_BANK_FOR_QES_SUCCESS:
      return Object.assign({}, state, {
        vollmachtsdatenbankPOAForQESUpdate: action.resp.data,
      });
    case vollmachtsdatenbankPOAConstants.GET_VOLLMACHTS_BANK_POA_QR_CODE_SUCCESS:
      return Object.assign({}, state, {
        QRCode: action.resp.data,
      });
    case vollmachtsdatenbankPOAConstants.GENERATE_VOLLMACHTS_BANK_POA_QR_CODE_SUCCESS:
      return Object.assign({}, state, {
        QRCode: action.resp.data,
      });
    case vollmachtsdatenbankPOAConstants.REGENERATE_VOLLMACHTS_BANK_POA_QR_CODE_SUCCESS:
      return Object.assign({}, state, {
        QRCode: action.resp.data,
      });
    case vollmachtsdatenbankPOAConstants.GET_VOLLMACHTS_BANK_POA_QES_STATUS_SUCCESS:
      return Object.assign({}, state, {
        qesStatus: action.resp.data,
      });
    case vollmachtsdatenbankPOAConstants.RESET_VOLLMACHTS_BANK_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        vollmachtsdatenbankPOADetails: {},
      });
    default:
      return state;
  }
};
