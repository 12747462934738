import {
  GET_TILEDETAILS_SUCCESS,
  GET_FEE_TILEDETAILS_SUCCESS,
} from "../../../constants/DashboardConstants";

const initialState = {};

const dashboardReducer = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case GET_TILEDETAILS_SUCCESS:
      return Object.assign({}, { data: action.resp });
    case GET_FEE_TILEDETAILS_SUCCESS:
      return Object.assign({}, { feeTilesdata: action.resp });
    default:
      return state;
  }
};

export default dashboardReducer;
