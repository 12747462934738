import { vorgangeDetailsConstants } from "../../../constants/VorgangDetailsConstants";

const initialState = {};

export default (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case vorgangeDetailsConstants.REGISTRATION_TO_GKS_SUCCESS:
      return Object.assign({}, state, {
        registrationResponse: action.resp,
      });
    case vorgangeDetailsConstants.VORGANGE_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        vorgangeDetails: action.vorgangeDetails,
      });
    case vorgangeDetailsConstants.GETTING_FILE_DOWNLOAD_SUCCESS:
      return Object.assign({}, state, {
        fileData: action.resp,
      });
    case vorgangeDetailsConstants.GET_REGISTER_DATEN_XML_SUCCESS:
      return Object.assign({}, state, {
        registerDatenXmlData: action.resp,
      });
    case vorgangeDetailsConstants.VORGANGE_DETAILS_DISPLAY:
      return Object.assign({}, state, {
        vorgangeDetailsDisplay: true,
        rowDetails: action.rowDetails,
      });
    default:
      return state;
  }
};
