import * as React from "react";
const ServiceInfoWarningIcon = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 36 35"
    {...props}
  >
    <path
      fill="#fff"
      d="M0 34h37L18.5 0Zm20.482-5.231h-3.964v-3.923h3.964Zm0-6.538h-3.964v-7.846h3.964Z"
      data-name="Icon ionic-md-warning"
    />
  </svg>
);
export default ServiceInfoWarningIcon;
