import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Material UI Components
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";

// Core Components
import MDCButton from "../../core/MDCButton";
import MDCRadioButton from "../../core/MDCRadioButton";
import MDCChip from "../../core/MDCChip";
import MDCInfoIcon from "../../core/MDCInfoIcon";
import MDCText from "../../core/MDCText";
import ErrorText from "../../shared/ErrorText";
import MDCSelect from "../../core/MDCSelect";
import MDCModal from "../../core/MDCModal";
import MDCLabel from "../../core/MDCLabel";

// Icons
import SmallTruckIcon from "../../iconComponents/SmallTruckIcon";

// Assets
import carLogo from "../../../assets/images/my_digital_car.svg";

// Constants
import {
  AddressOptions,
  LicensePlateSizes,
  individualAddressFields,
  nameTypeOptions,
  weeksOptions,
  LICENSE_PLATE_SAVE_INFO,
  LICENSE_PLATE_BUTTON_INFO,
} from "../../../constants/LicensePlateConstants";
import {
  VORGANT_STATUS_GESPEICHERT,
  VORGANT_STATUS_VERSANDFERTIG,
} from "../../../constants/DashboardConstants";

// Inner components
import OrderLicensePlateDeleteModal from "./OrderLicensePlateDeleteModal";

//Actions
import { OrderLicensePlateActions } from "../../../store/actions/vorgange/OrderLicensePlateActions";
import { removeNullAndEmpty } from "../../../helpers/utils";

const useStyles = makeStyles((theme) => ({
  kennzeiSection: {
    marginRight: ".5rem",
  },
  headerCss: {
    padding: "2rem",
    alignItems: "center",
  },
  headerColor: { color: theme.palette.MDCColors.black },
  backgroundCSS: {
    padding: "1rem",
    paddingBottom: ".5rem",
    margin: ".5rem 0",
    backgroundColor: theme.palette.MDCColors.cardBackgroundColor,
    width: "100%",
  },
  fieldGroup: {
    marginBottom: "1.25rem !important",
  },
  addressSpacing: {
    marginBottom: "6px",
    wordWrap: "break-word",
  },
  sectionText: {
    color: theme.palette.MDCColors.textColor,
  },
  addressSectionContent: {
    marginTop: "10px",
  },
}));

const OrderLicensePlateSection = ({
  requestId,
  status,
  operationIdsOptions,
}) => {
  const dispatch = useDispatch();
  const [createPopup, setCreatePopup] = useState(false);
  const [deleteLicensePopup, setDeleteLicensePopup] = useState(false);
  const [personenform, setPersonenform] = useState("");
  const [orderId, setOrderId] = useState("");
  const [disableSave, setDisableSave] = useState(false);

  const [formErrors, setFormErrors] = useState({});

  const formDetails = {
    type: "License Plate",
    anzahl: "520x110mm-2",
    size: "520x110mm",
    quantity: "2",
    orderLicensePlateAddressType: "",
    deliveryType: "Täglich",
    vorname: "",
    nachname: "",
  };

  const [formData, setFormData] = useState(formDetails);

  const operationIDs = operationIdsOptions;

  let OrderLicensePlateDetails = useSelector(
    (state) => state?.orderLicensePlateReducer?.orderLicensePlateDetails
  );

  OrderLicensePlateDetails =
    OrderLicensePlateDetails !== undefined ? OrderLicensePlateDetails : {};

  const OrderLicensePlateReducer = useSelector(
    (state) => state?.orderLicensePlateReducer
  );

  const orderLicensePlateFormData =
    OrderLicensePlateReducer?.orderLicensePlateFormData !== undefined
      ? OrderLicensePlateReducer?.orderLicensePlateFormData
      : {};

  useEffect(() => {
    if (requestId) {
      dispatch(OrderLicensePlateActions.getOrderLicensePlate(requestId));
    }
  }, []);

  const operationIdData =
    operationIDs !== "undefined" && operationIDs?.length > 0
      ? operationIDs?.map((operationDetails) => {
          return {
            value: operationDetails?.operationId?.value,
            label: `${operationDetails?.operationName?.value},${operationDetails.operationId?.value}`,
          };
        })
      : [];

  const {
    kennzeiSection,
    headerCss,
    headerColor,
    backgroundCSS,
    fieldGroup,
    addressSpacing,
    sectionText,
    addressSectionContent,
  } = useStyles();

  let addressTypeOptions = [...AddressOptions]; // Merging operationIds list with address type options

  if (operationIdData !== undefined) {
    addressTypeOptions = [...AddressOptions, ...operationIdData];
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAnzahl = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;

    if (value !== "adresseDesHalters" && value !== "individuelleAddresse") {
      const operationIdDetails = operationIDs?.find(
        (operationDetails) => operationDetails.operationId?.value === value
      );

      setFormData({
        ...formData,
        [name]: value,
        unternehmen: operationIdDetails?.operationName?.value,
        strasse: operationIdDetails?.strasse?.value,
        hausnummer: operationIdDetails?.nummer?.value,
        postleitzahl: operationIdDetails?.postleitzahl?.value,
        stadt: operationIdDetails?.ort?.value,
      });
    } else if (value === "individuelleAddresse") {
      setFormData({
        ...formData,
        [name]: value,
        unternehmen: "",
        strasse: "",
        hausnummer: "",
        postleitzahl: "",
        stadt: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (event) => {
    setDisableSave(true);
    event.preventDefault();

    const licenseType = formData?.anzahl?.split("-");

    const data = formData;

    const isOrderLicensePlateAddressType =
      data.orderLicensePlateAddressType === "adresseDesHalters" ||
      data.orderLicensePlateAddressType === "individuelleAddresse";

    data.size = licenseType[0];
    data.quantity = licenseType[1];
    data.betriebsnummern = isOrderLicensePlateAddressType
      ? ""
      : data.orderLicensePlateAddressType;
    data.addressType = !data.orderLicensePlateAddressType
      ? ""
      : isOrderLicensePlateAddressType
      ? data.orderLicensePlateAddressType
      : "betriebsnummern";

    if (personenform) {
      data.personenform = personenform;
    }

    const requestData = removeNullAndEmpty(data);

    if (requestId) {
      const licensePlateResponse = await dispatch(
        OrderLicensePlateActions.createOrderLicensePlate(requestData, requestId)
      );
      if (licensePlateResponse?.status === 200) {
        setCreatePopup(false);
        setFormData(formDetails);
        setFormErrors({});
        setDisableSave(false);
      }
      if (
        licensePlateResponse?.status === 400 &&
        licensePlateResponse?.data?.errorFieldMap
      ) {
        setFormErrors({
          ...licensePlateResponse?.data?.errorFieldMap,
        });
        setDisableSave(false);
      }
    } else {
      dispatch(
        OrderLicensePlateActions.setCreateOrderLicensePlateRequest(requestData)
      );
      setCreatePopup(false);
    }
  };

  const getModalHeader = () => {
    return (
      <Grid container className={headerCss}>
        <Grid item xs={8} className={headerColor}>
          <h4>Kennzeichenbestellung</h4>
        </Grid>
        <Grid item xs={4}>
          <img alt="My Digital Car" src={carLogo} width="95%" />
        </Grid>
      </Grid>
    );
  };

  const getModalFooter = () => {
    return (
      <Grid container pb={3} justifyContent={"space-between"}>
        <>
          <Grid marginLeft={"2.5rem"}>
            <MDCButton
              variant="outlined"
              label={"Schließen"}
              onClick={() => {
                setCreatePopup(false);
                setFormData(formDetails);
                setFormErrors({});
              }}
            />
          </Grid>
          <Grid>
            <Grid container paddingRight="1.5rem">
              <Grid item>
                <MDCButton
                  variant="contained"
                  label={"Bestätigen"}
                  disabled={disableSave}
                  onClick={handleSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      </Grid>
    );
  };

  const clearFormData = () => {
    setFormData(formDetails);
  };

  const showAddressDetails = (OrderLicensePlateDetails) => {
    return (
      <div>
        <Grid container spacing={1} className={backgroundCSS}>
          <Grid item xs={6}>
            <div>
              <span className={headerColor}>Versandzyklus:</span>
              <p className={addressSectionContent}>
                {OrderLicensePlateDetails?.deliveryType}
              </p>
            </div>

            <div>
              <span className={headerColor}>Versandadresse:</span>
              <p className={addressSectionContent}>
                {OrderLicensePlateDetails?.addressType ===
                  "adresseDesHalters" && (
                  <div className={addressSpacing}>
                    {AddressOptions[0]?.label}
                  </div>
                )}
                {OrderLicensePlateDetails?.addressType !==
                  "adresseDesHalters" && (
                  <>
                    <div className={addressSpacing}>
                      {OrderLicensePlateDetails?.unternehmen
                        ? `${OrderLicensePlateDetails?.unternehmen},`
                        : "" || OrderLicensePlateDetails?.vorname
                        ? `${OrderLicensePlateDetails?.vorname} ${OrderLicensePlateDetails?.nachname},`
                        : ""}
                    </div>
                    <div className={addressSpacing}>
                      {OrderLicensePlateDetails?.strasse}{" "}
                      {OrderLicensePlateDetails?.hausnummer
                        ? `${OrderLicensePlateDetails?.hausnummer},`
                        : ""}
                    </div>
                    <div className={addressSpacing}>
                      {OrderLicensePlateDetails?.stadt}{" "}
                      {OrderLicensePlateDetails?.postleitzahl}
                    </div>
                  </>
                )}
              </p>
            </div>
          </Grid>

          <Grid
            item
            xs={6}
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
          >
            <>
              {/* Ordered License Plate Processing */}
              {OrderLicensePlateDetails?.status === "Beantragt" && (
                <MDCChip
                  label={"Beantragt"}
                  bgColor={"rgb(0, 196, 222, .1)"}
                  textColor={"#00C4DE"}
                />
              )}

              {/* Ordered License Plate Success */}
              {OrderLicensePlateDetails?.status === "Abgeschlossen" && (
                <MDCChip
                  label={"Abgeschlossen"}
                  bgColor={"rgb(115, 188, 127, .1)"}
                  textColor={"#73BC7F"}
                />
              )}

              {/* Ordered License Plate Delete */}
              {(status === VORGANT_STATUS_VERSANDFERTIG ||
                status === VORGANT_STATUS_GESPEICHERT) &&
                OrderLicensePlateDetails?.status === "Created" && (
                  <MDCButton
                    variant="outlined"
                    label="Anfrage abbrechen"
                    removeMarginTop
                    onClick={() => {
                      if (!requestId) {
                        clearFormData();
                        dispatch(
                          OrderLicensePlateActions.setCreateOrderLicensePlateRequest(
                            {}
                          )
                        );
                      } else {
                        setDeleteLicensePopup(true);
                        setOrderId(OrderLicensePlateDetails?.orderId);
                      }
                    }}
                  />
                )}
            </>
          </Grid>
        </Grid>
      </div>
    );
  };

  const getModalBody = () => {
    return (
      <Grid container className={headerCss} paddingBottom="1rem">
        <Grid container justifyContent={"space-evenly"}>
          <Grid item xs={12} className={fieldGroup}>
            <Grid>
              <MDCLabel label="Anzahl" />
            </Grid>
            <MDCSelect
              value={formData?.anzahl}
              name="anzahl"
              handleChange={(e) => {
                handleAnzahl(e);
              }}
              error={formErrors?.anzahl?.length > 0}
              options={LicensePlateSizes}
            />
            <ErrorText id={"anzahl-error"} error={formErrors?.anzahl} />
          </Grid>

          <Grid item xs={12}>
            <Grid>
              <MDCLabel label="Versandzyklus" />
              <MDCInfoIcon
                title={
                  <>
                    <span className={addressSectionContent}>
                      <span className={headerColor}>Täglich: </span>
                      <span className={sectionText}>
                        Alle Bestellungen, die bis 15:30 Uhr eingehen, werden
                        noch am gleichen Tag versendet.
                      </span>
                    </span>
                    <br />
                    <span className={addressSectionContent}>
                      <span className={headerColor}>Wöchentlich: </span>{" "}
                      <span className={sectionText}>
                        Alle Bestellungen werden freitags versendet.
                      </span>
                    </span>
                  </>
                }
              />
            </Grid>

            <div>
              <MDCRadioButton
                name="deliveryType"
                selected={formData?.deliveryType}
                options={weeksOptions}
                row
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            </div>

            <div className={fieldGroup}>
              <MDCSelect
                value={formData?.orderLicensePlateAddressType || ""}
                name="orderLicensePlateAddressType"
                handleChange={(e) => {
                  handleSelectChange(e);

                  if (e.target.value === "individuelleAddresse") {
                    setPersonenform("juristische");
                  } else {
                    setPersonenform("");
                  }
                }}
                error={formErrors?.addressType?.length > 0}
                placeholder={"Bitte wählen"}
                options={addressTypeOptions}
              />
              <ErrorText
                id={"addressType-error"}
                error={formErrors?.addressType}
              />
            </div>

            {!["adresseDesHalters", ""].includes(
              formData?.orderLicensePlateAddressType
            ) && (
              <Grid
                container
                justifyContent={"space-between"}
                className={fieldGroup}
              >
                {formData?.orderLicensePlateAddressType ===
                  "individuelleAddresse" && (
                  <>
                    <MDCLabel label="Möchten Sie den Unternehmens- oder Personennamen angeben?" />
                    <Grid container>
                      <MDCRadioButton
                        name="personenform"
                        selected={personenform}
                        options={nameTypeOptions}
                        onChange={(e) => {
                          const value = e.target.value;
                          setPersonenform(value);

                          const updateState = {
                            ...formData,
                            vorname: value === "naturliche" ? "" : null,
                            nachname: value === "naturliche" ? "" : null,
                            unternehmen: value !== "naturliche" ? "" : null,
                          };

                          setFormData(updateState);
                        }}
                        row
                      />
                    </Grid>
                  </>
                )}

                <Grid container spacing={2}>
                  {personenform === "naturliche" &&
                  formData?.orderLicensePlateAddressType ===
                    "individuelleAddresse" ? (
                    <>
                      <Grid item xs={6} marginBottom=".75rem">
                        <MDCLabel label={"Vorname"} />
                        <MDCText
                          name={"vorname"}
                          value={formData?.vorname}
                          onChange={(e) => handleInputChange(e)}
                          error={formErrors?.vorname?.length > 0}
                          maxLength={60}
                        />
                        <ErrorText
                          id={"vorname-error"}
                          error={formErrors?.vorname}
                        />
                      </Grid>
                      <Grid item xs={6} marginBottom=".75rem">
                        <MDCLabel label={"Nachname"} />
                        <MDCText
                          name={"nachname"}
                          value={formData.nachname}
                          error={formErrors?.nachname?.length > 0}
                          onChange={(e) => handleInputChange(e)}
                          maxLength={45}
                        />
                        <ErrorText
                          id={"nachname-error"}
                          error={formErrors?.nachname}
                        />
                      </Grid>
                    </>
                  ) : (
                    formData?.orderLicensePlateAddressType && (
                      <Grid item xs={12} marginBottom=".75rem">
                        <MDCLabel label={"Unternehmen"} />
                        <MDCText
                          name={"unternehmen"}
                          value={formData?.unternehmen}
                          error={formErrors?.unternehmen?.length > 0}
                          onChange={(e) => handleInputChange(e)}
                          maxLength={150}
                        />
                        <ErrorText
                          id={"unternehmen-error"}
                          error={formErrors?.unternehmen}
                        />
                      </Grid>
                    )
                  )}
                </Grid>

                {formData?.orderLicensePlateAddressType &&
                  individualAddressFields.map(
                    ({ fieldId, fieldLabel, maxLength }, index) => {
                      return (
                        <Grid item xs={5.8} key={index} marginBottom=".75rem">
                          <MDCLabel label={fieldLabel} />
                          <MDCText
                            name={fieldId}
                            value={formData[fieldId]}
                            maxLength={maxLength}
                            error={formErrors[fieldId]?.length > 0}
                            onChange={(e) => handleInputChange(e)}
                          />
                          <ErrorText
                            id={`${fieldId}-error`}
                            error={formErrors[fieldId]}
                          />
                        </Grid>
                      );
                    }
                  )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const isAllowedStatus =
    status === "" ||
    status === "Versandfertig" ||
    status === "Gespeichert" ||
    Object.keys(orderLicensePlateFormData).length > 0 ||
    Object.keys(OrderLicensePlateDetails).length > 0;

  return (
    <>
      {isAllowedStatus && (
        <p>
          <span className={kennzeiSection}>
            <SmallTruckIcon />
          </span>
          <span className={headerColor}>Kennzeichenbestellung (optional)</span>
        </p>
      )}

      {Object.keys(orderLicensePlateFormData).length > 0 &&
        showAddressDetails(orderLicensePlateFormData)}

      {Object.keys(OrderLicensePlateDetails).length > 0 &&
        OrderLicensePlateDetails?.deliveryType &&
        showAddressDetails(OrderLicensePlateDetails)}

      {isAllowedStatus &&
        !Object.keys(orderLicensePlateFormData).length &&
        !Object.keys(OrderLicensePlateDetails).length && (
          <div>
            <Grid container spacing={1}>
              {!requestId ? (
                <Grid item xs={8} style={{ lineHeight: "20px" }}>
                  <span>{LICENSE_PLATE_SAVE_INFO}</span>
                </Grid>
              ) : (
                <>
                  <Grid item xs={8} style={{ lineHeight: "20px" }}>
                    <span>{LICENSE_PLATE_BUTTON_INFO}</span>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    container
                    direction="column"
                    justify="center"
                  >
                    <>
                      <MDCButton
                        variant="outlined"
                        label="Hier klicken"
                        removeMarginTop
                        onClick={() => {
                          setCreatePopup(true);
                        }}
                      />
                    </>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        )}

      {/* Create Order License Plate Delete Modal */}
      <MDCModal
        open={createPopup}
        title={getModalHeader()}
        body={getModalBody()}
        footer={getModalFooter()}
        isScroll
        size="md"
      />

      {/* Order License Plate Delete Modal */}
      <OrderLicensePlateDeleteModal
        open={deleteLicensePopup}
        setDeleteLicensePopup={setDeleteLicensePopup}
        requestId={requestId}
        orderId={orderId}
      />
    </>
  );
};

export default OrderLicensePlateSection;
