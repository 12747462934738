import React from "react";
import { ToastContainer, Zoom } from "react-toastify";

import { makeStyles } from "@mui/styles";

// Assets
import toastErrorIcon from "../../assets/images/icon_Fehler.svg";
import toastSuccessIcon from "../../assets/images/icon_Conform.svg";
import toastInfoIcon from "../../assets/images/icon_bell.svg";

const useStyles = makeStyles((theme) => ({
  toasterContainer: {
    width: "auto",
    minWidth: "320px",
    maxWidth: "450px",
    "& .Toastify__close-button": {
      color: `${theme.palette.MDCColors.color18}`,
      alignSelf: "center",
    },
    "& .Toastify__toast": {
      marginBottom: "0.5rem",
      display: "flex",
      justifyContent: "space-between",
      overflow: "hidden",
      fontFamily: `${theme.palette.typography.fontFamily}`,
      borderRadius: 5,
      background: "white",
      boxShadow: `0px 3px 0.938rem ${theme.palette.MDCColors.color18}`,
      fontSize: "1rem",
      color: `${theme.palette.MDCColors.color18}`,
      lineHeight: 1,
      padding: "5px 1.25rem 5px 2.188rem",
      cursor: "pointer",
      direction: "ltr",
      position: "relative",
      minHeight: "3.875rem",
      "& > span": {
        display: "flex",
        alignItems: "center",
        width: "100%",
      },
      "&:before": {
        content: "",
        display: "block",
        height: "1.188rem",
        width: "1.188rem",
        position: "absolute",
        left: "0.625rem",
        top: "50%",
        marginTop: "-9.5px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
    },

    "& .Toastify__toast--success": {
      border: `2px solid ${theme.palette.MDCColors.errorSuccess}`,
      "&:before": {
        content: "''", // using empty content property for showing background image
        height: "1.181rem !important",
        width: "1.181rem !important",
        backgroundImage: `url(${toastSuccessIcon})`,
      },
    },
    "& .Toastify__toast--error": {
      border: `2px solid ${theme.palette.MDCColors.errorDanger}`,
      "&:before": {
        content: "''", // using empty content property for showing background image
        backgroundImage: `url(${toastErrorIcon})`,
      },
    },
    "& .Toastify__toast--info": {
      border: `2px solid ${theme.palette.MDCColors.color23}`,
      "&:before": {
        content: "''", // using empty content property for showing background image
        height: "1.781rem !important",
        width: "1.781rem !important",
        left: "0.356rem",
        marginTop: "-1.188rem",
        backgroundImage: `url(${toastInfoIcon})`,
      },
    },
  },
}));

const MDCNotificationToaster = () => {
  const { toasterContainer } = useStyles();
  return (
    <ToastContainer
      position="top-right"
      transition={Zoom}
      hideProgressBar
      autoClose={8000}
      pauseOnHover
      limit={5}
      closeOnClick={false}
      className={toasterContainer}
    />
  );
};

export default MDCNotificationToaster;
