import React from "react";

// Material UI Components
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  defaultLabel: {
    "&.MuiTypography-root": {
      color: theme.palette.MDCColors.textColor,
      fontFamily: theme.palette.typography.fontFamily,
    },
  },
  disabledLabel: {
    "&.MuiTypography-root": {
      color: theme.palette.MDCColors.color18,
      fontFamily: theme.palette.typography.fontFamily,
      fontWeight: theme.palette.typography.fontWeightMedium,
    },
  },
  spanStyles: {
    color: theme.palette.MDCColors.color18,
    fontFamily: theme.palette.typography.fontFamily,
    fontWeight: theme.palette.typography.fontWeightMedium,
  },
}));

const MDCLabel = (props) => {
  const { label, variant, isOptional, isReadOnly } = props;

  const { defaultLabel, disabledLabel, spanStyles } = useStyles();

  let MDCStyles = defaultLabel;
  if (isReadOnly) {
    MDCStyles = `${MDCStyles} ${disabledLabel}`;
  }

  let defaultVariant = "string";

  return (
    <>
      <Typography
        component={"span"}
        variant={variant ? variant : defaultVariant}
        className={MDCStyles}
      >
        {label}
        {isOptional ? (
          <span className={spanStyles}>{" ".concat("(optional)")}</span>
        ) : (
          ""
        )}
      </Typography>
    </>
  );
};

export default MDCLabel;
