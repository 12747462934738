export const customerTypeOptions = [
  {
    label: "Betrieb",
    value: "Betrieb",
  },
  {
    label: "Kunde",
    value: "Kunde",
  },
];

export const vollmachtsdatenbankPOAConstants = {
  GET_VOLLMACHTS_BANK_DETAILS: "GET_VOLLMACHTS_BANK_DETAILS",
  GET_VOLLMACHTS_BANK_DETAILS_SUCCESS: "GET_VOLLMACHTS_BANK_DETAILS_SUCCESS",
  GET_VOLLMACHTS_BANK_DETAILS_FAILURE: "GET_VOLLMACHTS_BANK_DETAILS_FAILURE",
  UPDATE_VOLLMACHTS_BANK_FOR_QES_SUCCESS:
    "UPDATE_VOLLMACHTS_BANK_FOR_QES_SUCCESS",
  GET_VOLLMACHTS_BANK_POA_QR_CODE_SUCCESS:
    "GET_VOLLMACHTS_BANK_POA_QR_CODE_SUCCESS",
  GENERATE_VOLLMACHTS_BANK_POA_QR_CODE_SUCCESS:
    "GENERATE_VOLLMACHTS_BANK_POA_QR_CODE_SUCCESS",
  REGENERATE_VOLLMACHTS_BANK_POA_QR_CODE_SUCCESS:
    "REGENERATE_VOLLMACHTS_BANK_POA_QR_CODE_SUCCESS",
  GET_VOLLMACHTS_BANK_POA_QES_STATUS_SUCCESS:
    "GET_VOLLMACHTS_BANK_POA_QES_STATUS_SUCCESS",
  RESET_VOLLMACHTS_BANK_DETAILS_SUCCESS:
    "RESET_VOLLMACHTS_BANK_DETAILS_SUCCESS",
};

// Form Initial Values for Create POA
export const initialZbtAddress = {
  collectOrShip: "VERSAND",
  type: "",
  personenform: "juristische",
  betriebsnummer: "",
  unternehmen: "",
  vorname: "",
  nachname: "",
  strasse: "",
  houseNummer: "",
  postleitzahl: "",
  stadt: "",
};

export const initialformValuesPOA = {
  halterType: "Betrieb",
  halterName: "",
  betriebsnummer: "",
  wirtschaftszweig: "",
  strasse: "",
  houseNummer: "",
  postleitzahl: "",
  stadt: "",
  zollGkVorhanden: false,
  zollgrosskundennummer: "",
  steuerbefreiung: "",
  zahlweise: "jährlich",
  kontoinhaber: "",
  kreditinstitut: "",
  iban: "",
  bic: "",
  zbt1Address: initialZbtAddress,
  zbt2Address: initialZbtAddress,
};

// Error Messages
export const  POA_HALTER_NAME_ERROR_MESSAGE = "Dies ist ein Pflichtfeld. Bitte geben Sie den Namen der juristischen Person an."

// Statuses constants
export const STATUSES = {
  Draft: "Draft",
  Gültig: "Gültig",
  Ungültig: "Ungültig",
};