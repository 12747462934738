import * as React from "react";

function UpArrowIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12.46}
      height={6.946}
      {...props}
    >
      <path
        data-name="Pfad 711"
        d="M.67 6.18l5.376-4.854 5.77 4.854"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
      />
    </svg>
  );
}

export default UpArrowIcon;
