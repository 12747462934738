// Constants
import { GET_ARCHIV_STATISTICS_SUCCESS } from "../../../constants/DashboardConstants";

const initialState = {};

const archiveStatisticsReducer = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }

  switch (action.type) {
    case GET_ARCHIV_STATISTICS_SUCCESS:
      return Object.assign({}, { data: action.resp });
    default:
      return state;
  }
};

export default archiveStatisticsReducer;
