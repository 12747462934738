const initialState = {
  loading: true,
};

const feesReportListReducer = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }

  switch (action.type) {
    case "GET_FEES_REPORT_LIST":
      return Object.assign({}, state, {
        rows: action.resp.content,
        totalCount: action.resp.totalElements,
        loading: false,
      });

    case "GETTING_FEES_REPORT_DETAILS_XML_SUCCESS":
      return Object.assign({}, state, {
        xmlData: action.resp,
        loading: false,
      });

    case "GETTING_FEES_REPORT_ZIP_XML_OR_PDF_SUCCESS":
      return Object.assign({}, state, {
        zipData: action.resp,
        loading: false,
      });

    default:
      return state;
  }
};

export default feesReportListReducer;
