import React from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import theme from "../../themes/mdc-theme";
import MDCSimpleTable from "../../components/core/MDCSimpleTable";
const feesTableData = [
  {
    pos: "1",
    Einzelpositionen:
      "Neuzulassung / erstmalige Zulassung gebrauchter Fahrzeugeggf. zuzüglich",
    Betrag: "27,60 €",
  },
  {
    pos: "2",
    Einzelpositionen:
      "Neuzulassung / erstmalige Zulassung gebrauchter Fahrzeugeggf. zuzüglich",
    Betrag: "3,60 €",
  },
  {
    pos: "3",
    Einzelpositionen: "Mit Wunschkennzeichen",
    Betrag: "10,20 €",
  },
  {
    pos: "4",
    Einzelpositionen: "Vorwegreservierung",
    Betrag: "2,60 €",
  },
  {
    pos: "5",
    Einzelpositionen:
      "Übernahme eines Kennzeichens vor Ablauf der automatisierten Löschungsfristen",
    Betrag: "12,80 €",
  },
  {
    pos: "",
    Einzelpositionen: "Gebhramt",
    Betrag: "162,80 €",
  },
];
const styles = () => ({
  headerCss: {
    padding: "2rem",
    alignItems: "center",
  },
  formBackGround: {
    padding: "0 3%",
    marginTop: "2% !important",
    background: `${theme.palette.MDCColors.white} 0% 0% no-repeat padding-box`,
    borderRadius: "5px",
    opacity: 1,
    color: theme.palette.MDCColors.textColor,
    fontSize: "1rem",
    fontFamily: theme.palette.typography.fontFamily,
    lineHeight: 1,
  },
  totalFeesDiv: {
    padding: "1.5rem 0",
    borderRadius: "5px",
    border: `.15rem solid ${theme.palette.MDCColors.color21}`,
  },
});
class GebuhrenDetails extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {};
  }

  componentDidMount() {}
  feesDataTableColumns = [
    {
      title: "Pos.",
      field: "pos",
      cellStyle: { width: "20%" },
      headerStyle: { width: "20%" },
    },
    {
      title: "Einzelpositionen",
      field: "Einzelpositionen",
      cellStyle: { width: "45%" },
      headerStyle: { width: "45%" },
    },
    {
      title: "Betrag",
      field: "Betrag",
      cellStyle: { width: "35%" },
      headerStyle: { width: "35%" },
    },
  ];

  tableOptions = () => {
    return {
      paging: false,
      search: true,
      sorting: false,
      toolbar: false,
      filtering: true,
      maxBodyHeight: "22rem",
    };
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.formBackGround}>
        <Grid
          container
          id={"fees-info"}
          alignItems={"center"}
          className={classes.totalFeesDiv}
        >
          <Grid
            item
            xs={1.5}
            fontSize={"2rem"}
            fontWeight={"bold"}
            paddingLeft={"1.5%"}
          >
            324,00 €
          </Grid>
          <Grid item xs={10}>
            <Grid
              fontSize={"1.25rem"}
              fontWeight={"bold"}
              marginBottom={".5rem"}
            >
              Gesamtkosten der Gebühren
            </Grid>
            <Grid>
              Weitere Details zu den Gebühren können Sie der Tabelle entnehmen.
            </Grid>
          </Grid>
        </Grid>
        {[1, 2].map((tableId) => {
          return (
            <Grid item xs={12} index={tableId} marginY={"1.5rem"}>
              <MDCSimpleTable
                id={"fees-Data-List"}
                columns={this.feesDataTableColumns}
                data={feesTableData}
                options={this.tableOptions()}
                isFiltering
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GebuhrenDetails));
