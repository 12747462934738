export const config = {
  apiUrl: Window.REACT_APP_API_URL || process.env.REACT_APP_API_URL,
  verimiLoginUrl:
    `${Window.REACT_APP_API_URL}${Window.REACT_APP_VERIMI_LOGIN_URL}` ||
    `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_VERIMI_LOGIN_URL}`,
  appLoginUrl:
    `${Window.REACT_APP_API_URL}${Window.REACT_APP_MDC_LOGIN_URL}` ||
    `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_MDC_LOGIN_URL}`,
  buildEnv: Window.REACT_APP_BUILD_ENV || process.env.REACT_APP_BUILD_ENV,
  enableWebhook: Window.REACT_APP_ENABLE_WEBHOOK || process.env.REACT_APP_ENABLE_WEBHOOK,
};
