import React from "react";

// Material UI Components
import Input from "@mui/material/Input";
import { makeStyles } from "@mui/styles";

// Core Components
import MDCTooltip from "./MDCTooltip";

const useStyles = makeStyles((theme) => ({
  defaultText: {
    "& .MuiInputBase-input": {
      border: `2px solid ${theme.palette.MDCColors.color23}`,
      background: theme.palette.MDCColors.color21,
      color: theme.palette.MDCColors.color18,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      marginTop: "0.625rem",
      fontSize: "1rem",
      fontFamily: theme.palette.typography.fontFamily,
      padding: "0rem 0.938rem 0rem 0.938rem",
      height: "calc(1rem + 1rem + 0.625rem)",
      borderRadius: "5px",
    },
  },
  duplicateText: {
    "& .MuiInputBase-input": {
      border: `2px solid ${theme.palette.MDCColors.color34}`,
      marginTop: "0.313rem",
    },
  },
  errorText: {
    "& .MuiInputBase-input": {
      border: `2px solid ${theme.palette.MDCColors.errorDanger}`,
      background: "rgba(236, 88, 103, 0.3)",
    },
  },
  disabledText: {
    "& .MuiInputBase-input": {
      border: "0px",
      opacity: "0",
    },
  },
}));

const MDCText = (props) => {
  const {
    name,
    onChange,
    value,
    type,
    error,
    placeholder,
    autoComplete,
    readOnly,
    inputProps = {},
    duplicate,
    endAdornment,
    onKeyPress,
    isToolTipText,
    maxLength,
    multiline,
    rows,
  } = props;

  const { defaultText, duplicateText, errorText, disabledText } = useStyles();

  let MDCStyles = defaultText;
  if (error) {
    MDCStyles = `${MDCStyles} ${errorText}`;
  }
  if (readOnly && !duplicate) {
    MDCStyles = `${MDCStyles} ${disabledText}`;
  }
  if (duplicate && readOnly) {
    MDCStyles = `${MDCStyles} ${duplicateText}`;
  }

  if (maxLength) {
    inputProps.maxLength = maxLength;
  }

  return (
    <>
      <MDCTooltip
        showWhiteSpace={isToolTipText?.length}
        title={isToolTipText?.length ? isToolTipText : ""}
      >
        <Input
          fullWidth
          className={MDCStyles}
          onChange={onChange}
          value={value}
          type={type || "text"}
          name={name}
          error={error}
          disableUnderline
          placeholder={placeholder}
          autoComplete={autoComplete}
          disabled={readOnly}
          inputProps={inputProps}
          endAdornment={endAdornment}
          onKeyPress={onKeyPress}
          multiline={multiline}
          rows={rows}
        />
      </MDCTooltip>
    </>
  );
};

export default MDCText;
