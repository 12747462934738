import {
  GET_TENANT_TABLE_DATA_SUCCESS,
  GET_TECHNICAL_ALIAS_SUCCESS,
  GET_TENTANT_GROUPS_SUCCESS,
  GET_FEE_REPORTING_TABLE_DATA_SUCCESS,
  GET_TENANTS_REQUEST_TYPES_SUCCESS,
} from "../../../constants/DashboardConstants";

const initialState = {};

const appLoginDashboardReducer = (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case GET_TENANT_TABLE_DATA_SUCCESS:
      return Object.assign({ ...state }, { tenantTableData: action.resp });
    case GET_TECHNICAL_ALIAS_SUCCESS:
      return Object.assign({ ...state }, { tecnicalAliasDetails: action.resp });
    case GET_TENTANT_GROUPS_SUCCESS:
      return Object.assign({ ...state }, { tenantGroups: action.resp });
    case GET_FEE_REPORTING_TABLE_DATA_SUCCESS:
      return Object.assign(
        { ...state },
        { feeReportingTableData: action.resp }
      );
    case GET_TENANTS_REQUEST_TYPES_SUCCESS:
      return Object.assign(
        {
          ...state,
        },
        {
          tenantsRequestTypes: action.resp,
        }
      );
    default:
      return state;
  }
};

export default appLoginDashboardReducer;
