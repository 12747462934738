import React from "react";
import { splitedFieldsforLicensePlate } from "../../helpers/vorgange";
import MDCCheckbox from "../core/MDCCheckbox";

// Core Component
import MDCText from "../../components/core/MDCText";
import MDCRadioButton from "../core/MDCRadioButton";
import MDCLabel from "../core/MDCLabel";

// Material UI Components
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import DuplicateAcceptIcon from "../../assets/images/icon_accept_all_green.svg";
import DuplicateRejectIcon from "../../assets/images/icon_duplicate_reject.svg";
import theme from "../../themes/mdc-theme";

const useStyles = makeStyles({
  buttonDuplicate: {
    background: "none",
    border: "none",
    display: "inline-block",
    padding: 0,
    color: theme.palette.MDCColors.black,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration: "underline",
    "&:before": {
      content: "''",
      width: "1.2rem",
      height: "1.2rem",
      display: "inline-block",
      verticalAlign: "text-bottom",
      marginRight: "0.4rem",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "left center",
    },
  },
  buttonAcceptIcon: {
    "&:before": { backgroundImage: `url(${DuplicateAcceptIcon})` },
  },
  buttonRejectIcon: {
    "&:before": { backgroundImage: `url(${DuplicateRejectIcon})` },
  },
});

export const DuplicateTextField = (props) => {
  const { buttonDuplicate, buttonAcceptIcon, buttonRejectIcon, maxLength } =
    useStyles();

  return (
    <div>
      {props.name !== "Kennzeichen" && (
        <MDCText
          name={`${props.name}-duplicate`}
          value={props.value}
          readOnly={props.readOnly}
          maxLength={maxLength}
          duplicate
        />
      )}
      {props.step !== 4 &&
        !splitedFieldsforLicensePlate?.includes(props.name) && (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            marginTop=".5rem"
          >
            <button
              className={`${buttonDuplicate} ${buttonAcceptIcon}`}
              onClick={props.onAccept}
            >
              Aktualisierung annehmen
            </button>
            <button
              className={`${buttonDuplicate} ${buttonRejectIcon}`}
              onClick={props.onReject}
            >
              Aktualisierung verwerfen
            </button>
          </Grid>
        )}
    </div>
  );
};

export const DuplicateRadioValue = (props) => {
  const { buttonDuplicate, buttonAcceptIcon, buttonRejectIcon } = useStyles();

  return (
    <Grid container marginBottom="1rem !important">
      <Grid item xs={12}>
        <MDCRadioButton
          name={`${props.name}-duplicate`}
          onChange={(e) => this.handleChange(e)}
          options={[{ label: props.label, value: props.value }]}
          selected={props.value}
          disabled
          duplicate
          row
        />
      </Grid>

      {(props.value === 0 || props.value === 2) && (
        <Grid item xs={12}>
          <MDCLabel label="Kennzeichenkreis/Wunschkennzeichen" />
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <MDCText
                name="kennzeichenF"
                value={props.duplicateLicense[0]}
                readOnly
                duplicate
              />
            </Grid>
            <Grid item xs={3}>
              <MDCText
                name="kennzeichenM"
                value={props.duplicateLicense[1]}
                readOnly
                duplicate
              />
            </Grid>
            <Grid item xs={6}>
              <MDCText
                name="kennzeichenL"
                value={props.duplicateLicense[2]}
                maxLength="4"
                readOnly
                duplicate
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {props.value === 2 && (
        <Grid item xs={12} marginTop="1rem !important">
          <MDCLabel label="PIN für reserviertes Kennzeichen" />
          <MDCText
            name="pin"
            value={props.duplicateLicense[3]}
            readOnly
            duplicate
          />
        </Grid>
      )}
      {props.step !== 4 && (
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          marginTop=".5rem"
        >
          <button
            className={`${buttonDuplicate} ${buttonAcceptIcon}`}
            onClick={props.onAccept}
          >
            Aktualisierung annehmen
          </button>
          <button
            className={`${buttonDuplicate} ${buttonRejectIcon}`}
            onClick={props.onReject}
          >
            Aktualisierung verwerfen
          </button>
        </Grid>
      )}
    </Grid>
  );
};

export const DuplicateCheckboxValue = (props) => {
  const { buttonDuplicate, buttonAcceptIcon, buttonRejectIcon } = useStyles();
  return (
    <Grid container marginBottom=".5rem">
      <Grid item xs={12}>
        <MDCCheckbox
          name={props.name}
          type="checkbox"
          label={props.label}
          disabled
          checked={props.checked}
          duplicate
        />
      </Grid>
      {props.step !== 4 && (
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          marginTop=".5rem"
        >
          <button
            className={`${buttonDuplicate} ${buttonAcceptIcon}`}
            onClick={props.onAccept}
          >
            Aktualisierung annehmen
          </button>
          <button
            className={`${buttonDuplicate}  ${buttonRejectIcon}`}
            onClick={props.onReject}
          >
            Aktualisierung verwerfen
          </button>
        </Grid>
      )}
    </Grid>
  );
};
