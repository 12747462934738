import React from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";
import * as moment from "moment";
import "moment/locale/de";

// Actions
import { vorgangeDetailsActions } from "../../store/actions/vorgange/vorgangeDetailsActions";
import { userActions } from "../../store/actions/userActions";
import { verimiAdminActions } from "../../store/actions/admin/verimiAdminActions";

import MDCLicensePlate from "../core/MDCLicensePlate";

import AntragRequestForm from "../manualRequest/AntragRequestForm";
import FahrzeugRequestForm from "../manualRequest/FahrzeugRequestForm";
import KennzeichenRequestForm from "../manualRequest/KennzeichenRequestForm";
import HalterRequestForm from "../manualRequest/HalterRequestForm";
import GebuhrenDetails from "../manualRequest/GebuhrenDetails";
import MassageDialog from "../shared/MessageDialog";
import { manualRequestFormActions } from "../../store/actions/vorgange/manualRequestFormActions";

// Assets
import errorIcon from "../../assets/images/Icon_error.svg";
import checkIcon from "../../assets/images/icon_check.svg";
import blackCheckIcon from "../../assets/images/icon_check_black.svg";
import iconProfilSmall from "../../assets/images/icon_profil_small.svg";
import iconProfilSmallBlack from "../../assets/images/icon_profil_small_black.svg";
import DownloadImage from "../../assets/images/Icon_Download.svg";
import iconQESCompletionGreen from "../../assets/images/icon_vollmacht_green.svg";
import iconQESCompletionBlack from "../../assets/images/icon_vollmacht_black.svg";

// Constants
import { ABORT } from "../../constants/messageConstants";
import PopupDialog from "../shared/PopupDialog";
import DataRegistrationDialog from "./DataRegistrationDialog";
import VerwerfenPopupDialog from "../shared/VerwerfenPopupDialog";
import {
  QESFilesName,
  DataRegistrationFilesName,
  ZulassungsFilesName,
  RejectedAbgelehntFilesName,
  TEIL1,
  TEIL2,
  TEIL3,
  TEIL1_ENDPOINT,
  TEIL2_ENDPOINT,
  TEIL3_ENDPOINT,
  abmeldebescheinigung,
  ABGELEHNT_RECORD,
  ABGELEHNT_ENDPOINT,
  ABZulassungsFilesName,
} from "../../constants/VorgangDetailsConstants";
import {
  USER_ROLE_ADMIN,
  USER_ROLE_APPROVER,
  USER_ROLE_MDC_ADMIN,
  USER_ROLE_SUPPORT,
} from "../../constants/userConstants";

// Helper
import {
  formTag,
  antragDefaultForm,
  fahrzeugDefaultForm,
  kennzeichenDefaultForm,
  halterDefaultForm,
  vollmachtDefaultForm,
  getTabObjects,
  updateStateObject,
  formConstants,
} from "../../helpers/vorgange";
import { ConfirmationPopupConstants } from "../../constants/PopupDialogConstants";

// History
import { history } from "../../helpers/history";
//Core Components
import MDCButton from "../../components/core/MDCButton";
import MDCModal from "../core/MDCModal";
import { withStyles } from "@mui/styles";
import MDCLoader from "../core/MDCLoader";
import MDCTooltip from "../core/MDCTooltip";
import MDCBanner from "../core/MDCBanner";

import { alertCSS, alertInfoCSS, alertErrorCSS } from "../shared/AlertCSS";

//Button Icons
import BlockIcon from "../iconComponents/BlockIcon";
import NextIcon from "../iconComponents/NextIcon";
import RefreshIcon from "../iconComponents/RefreshIcon";
import EditIcon from "../iconComponents/EditIcon";
import ButtonDocumentIcon from "../iconComponents/ButtonDocumentIcon";
import DownArrowIcon from "../iconComponents/DownArrowIcon";
import UpArrowIcon from "../iconComponents/UpArrowIcon";

// Material UI
import Grid from "@mui/material/Grid";

// Themes
import theme from "../../themes/mdc-theme";

const styles = () => ({
  alert: alertCSS,
  alertInfo: alertInfoCSS,
  alertError: alertErrorCSS,
  numberPlate: {
    position: "absolute",
    top: "-4.5rem",
    left: "40%",
  },
  tabLabel: {
    width: "95%",
    float: "left",
    fontFamily: theme.palette.typography.fontFamily,
  },
  marginRight5: {
    marginRight: "5px",
  },
  marginRight3: {
    marginRight: "1.5rem",
  },
  tabIcon: {
    width: "5%",
    float: "right",
    marginTop: "-14px",
    marginRight: "-6px",
  },
  downloadDropdown: {
    "& .dropdown-menu": {
      minWidth: "21.875rem",
      maxWidth: "25rem",
    },
    "& .dropdown-toggle": {
      "&::after": {
        display: "none",
      },
    },
    "& .nav-link": {
      padding: "0 0.5rem !important",
    },
  },
  qesTimestamp: {
    color: theme.palette.MDCColors.color23,
    fontSize: "1rem",
    fontStyle: "italic",
    wordWrap: "break-word",
    whiteSpace: "normal",
  },
  qesDivider: {
    borderTop: `1px solid ${theme.palette.MDCColors.color23}`,
  },
  infoMsg: {
    width: "-webkit-fill-available",
    margin: "auto 1rem",
  },
  fullPageLoader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh",
  },
  detailDialog: {
    // To make content scrollable and of fixed height in Detail Dialog tabs
    "& .tab-content": {
      height: "56vh",
      overflow: "auto",
    },
  },
});
class VorgangeDetailsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      activeStep: 0,
      requestId: "",
      messageFor: ABORT,
      displayMessage: false,
      mandatoryFieldError: false,
      redirectState: false,
      displayActivityWindow: false,
      buttonDisabled: !(
        this.props?.user?.roles?.includes(USER_ROLE_MDC_ADMIN) ||
        this.props?.user?.roles?.includes(USER_ROLE_APPROVER)
      ),
      weiterleitenDissabled: !this.props?.user?.employeeId,
      pullRequestDissabled: true,
      defaultTabName: "Vorgangsdaten",
      vorgangeDetailsData: {},
      licensePlateF: "",
      licensePlateM: "",
      licensePlateL: "",
      selectedFileType: "xml",
      globalMessages: [],
      isWeiterleitenDisabled: false,
      antragRequestFormData: antragDefaultForm,
      fahrzeugRequestFormData: fahrzeugDefaultForm,
      kennzeichenRequestFormData: kennzeichenDefaultForm,
      halterRequestFormData: halterDefaultForm,
      vollmachtRequestFormData: vollmachtDefaultForm,
      displayPopup: false,
      isDownloadBtnOpen: false,
      qesTimestamp: "",
      operationIdsOptions: [],
      wirtschaftsDataOptions: [],
      registrationDate: "",
      registrationProcessedDate: "",
      employeeId: "",
      showFullPageLoader: true,
      countriesList: [{ label: "", value: "" }],
      displayVerwefenDialog: false,
      halterFormOperationIds: [],
      isZustellungZBError: false,
      openDataRegistrationModal: false,
    };
  }
  componentDidMount() {
    if (this.props?.rowDetails?.requestId) {
      // To fetch all operations Id mapped to that  particular logged in user
      this.props
        .getOperationIDs({}, this.props?.user?.tenant_alias)
        .then((resp) => {
          this.computeOperationIdsOptions(resp);
        });
      //To get the Details of that individual request
      this.props.getIndividualVorgangeDetails(
        this.props.rowDetails?.requestId,
        false
      );

      // To fetch Steuerbefreiung Data
      this.props.getSteuerbefreiungData();

      this.getCountriesList();
      //get the company details in case of company person in halterRequestFormData (form 4)
      (this.props?.rowDetails?.vorgangsart === "NZ" ||
        this.props?.rowDetails?.vorgangsart === "TZ") &&
        this.props.getCompanyDetails(this.props.user.tenant_alias);
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props?.companyDetails &&
      this.props?.companyDetails !== prevProps?.companyDetails
    ) {
      this.companyDetails = this.props.companyDetails;
    }
    if (this.props?.vorgangeDetails !== prevProps?.vorgangeDetails) {
      if (
        this.props?.vorgangeDetails?.data &&
        this.props?.vorgangeDetails?.status === 200
      ) {
        let formData = this.props.vorgangeDetails.data;
        this.setState({
          requestId: this.props.rowDetails.requestId,
        });
        this.setValuesInObject(formData, this.state.requestId);

        if (formData.licensePlate) {
          this.handleLicensePlate(formData.licensePlate);
        }

        //  For getting the timestamp for qes files if it's a qes completed request for NZ/UM/WZ request type
        ["NZ", "TZ", "UM", "HA", "WZ"].includes(
          this.props.rowDetails?.vorgangsart
        ) &&
          this.props.getVollmachtSigningStatus(this.props.rowDetails.requestId);

        if (formData.registrationDate && formData.employeeId) {
          this.setState({
            registrationDate: formData.registrationDate,
            employeeId: formData.employeeId,
          });
        }

        if (formData?.registrationProcessedDate) {
          this.setState({
            registrationProcessedDate: formData?.registrationProcessedDate,
          });
        }
        this.setState({ showFullPageLoader: false });
      } else {
        //If Api fails and give status other than success the close dialog
        this.closeDialogAndHideLoader();
      }
      this.setState({
        weiterleitenDissabled:
          !this.props.user.employeeId ||
          !this.props.companyDetails?.accountPresent,
      });

      this.setState({ showLoader: false });
    }
    //  For setting the timestamp for qes files if it's a qes completed request
    if (
      this.props.vollmachtSigningStatus !== prevProps.vollmachtSigningStatus &&
      this.props.vollmachtSigningStatus?.completedStage === "sign"
    ) {
      this.setState({
        qesTimestamp: this.props.vollmachtSigningStatus?.signedDate,
      });
    }
  }
  componentWillUnmount() {
    //For resetting the vorgangDetails Reducer every time the Dialog closes/unmounts
    this.props.resetIndividualVorgangeDetails();
  }

  getCountriesList = () => {
    this.props.getCountriesList().then((resp) => {
      if (resp?.data) {
        const countriesList = resp?.data?.map(
          ({ countryName, countryCode }) => ({
            label: countryName,
            value: countryCode,
          }) // changed countryName and countryCode keys in label and value keys according to our project standards.
        );
        countriesList.unshift({ label: "", value: "" }); // for not selecting any country in dropdown
        this.setState({
          countriesList,
        });
      }
    });
  };

  companyDetails = {};

  //set value in object once get response
  setValuesInObject(object, requestId) {
    //antrag Form
    this.setState({
      antragRequestFormData: updateStateObject(
        antragDefaultForm,
        getTabObjects(formTag[0], object),
        0,
        requestId,
        this.companyDetails
      ),
    });

    //fahrzeug form
    this.setState({
      fahrzeugRequestFormData: updateStateObject(
        fahrzeugDefaultForm,
        getTabObjects(formTag[1], object),
        1,
        requestId,
        this.companyDetails
      ),
    });
    //kennzeichen form
    this.setState({
      kennzeichenRequestFormData: updateStateObject(
        kennzeichenDefaultForm,
        getTabObjects(formTag[2], object),
        2,
        requestId,
        this.companyDetails
      ),
    });
    // halter form
    this.setState({
      halterRequestFormData: updateStateObject(
        halterDefaultForm,
        getTabObjects(formTag[3], object),
        3,
        requestId,
        this.companyDetails,
        this.state.antragRequestFormData.personenform
      ),
    });

    this.setState({
      vollmachtRequestFormData: updateStateObject(
        vollmachtDefaultForm,
        getTabObjects(formTag[4], object),
        4,
        requestId,
        this.companyDetails
      ),
    });

    if (object?.messages && object?.messages?.length) {
      this.setState({ globalMessages: object?.messages });
    }
  }

  // Splitting license plate into three part or set in state
  handleLicensePlate(licensePlate) {
    let licensePlateF = licensePlate?.substr(0, 3).trim(),
      licensePlateM = licensePlate?.substr(3, 2).trim(),
      licensePlateL = licensePlate?.substr(5).trim();

    const isELicensePlate = licensePlateL.includes("E");

    licensePlateL = isELicensePlate ? licensePlateL.replace("E", "") : licensePlateL;

    this.setState({
      licensePlateF: licensePlateF || "",
      licensePlateM: licensePlateM || "",
      licensePlateL: licensePlateL || "",
      isELicensePlate: isELicensePlate,
    });
  }

  handlePopupClose = () => {
    this.setState({ displayPopup: false });
  };

  handleRegistration = () => {
    if (
      ["NZ", "TZ", "UM", "HA", "AB", "WZ"].includes(
        this.state?.antragRequestFormData?.vorgangsart?.value
      )
    ) {
      this.setState({ displayPopup: true });
    }
  };

  handleDataRegistration = () => {
    this.setState({ openDataRegistrationModal: true });
  };

  handlePullRequest = (id) => {
    this.setState({ showLoader: true, showFullPageLoader: true });
    this.props.handlePullRequest(id).then(() => {
      this.closeDialogAndHideLoader(true);
    });
  };

  handleRejection = (id) => {
    this.setState({
      requestId: id,
      displayVerwefenDialog: true,
    });
  };

  closeDialogAndHideLoader = (isTableReload) => {
    this.props.handleDialogClose(isTableReload);
    this.setState({ showLoader: false });
  };

  downloadBlobData = (fileData, fileName, fileType) => {
    const url = window.URL.createObjectURL(
      new Blob([fileData], { type: "application/pdf" })
    );
    const link = document.createElement("a");
    link.href = url;
    let downloadedFileName =
      fileType === "sepa" || fileType === "poa"
        ? fileName
        : `${fileName}.${fileType}`;
    link.setAttribute("download", downloadedFileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  handleBlobData = (fileData, fileName) => {
    const url = window.URL.createObjectURL(
      new Blob([fileData], { type: "application/pdf" })
    );

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  //get the file name from response
  getFileNameFromHttpResponse = (httpResponse) => {
    return (httpResponse && httpResponse.headers["x-suggested-filename"]) || "";
  };
  //get document props
  getDocumentProps = (fileType) => {
    return fileType === "sepa"
      ? this.props?.sepaDocument
      : this.props?.poaDocument;
  };
  //Call to download api
  downloadFile = (fileType) => {
    if (fileType === "sepa" || fileType === "poa") {
      let fileName, documentProps;
      documentProps = this.getDocumentProps(fileType); //check for existing props
      if (documentProps) {
        fileName = this.getFileNameFromHttpResponse(documentProps);
        this.downloadBlobData(documentProps.data, fileName, fileType);
      } else {
        this.props
          .getVollmachtSignedDocuments(this.state.requestId, fileType)
          .then(() => {
            documentProps = this.getDocumentProps(fileType); //get the latest props
            if (documentProps) {
              fileName = this.getFileNameFromHttpResponse(documentProps);
              this.downloadBlobData(documentProps.data, fileName, fileType);
            }
          });
      }
    } else if (
      fileType === TEIL1 ||
      fileType === TEIL2 ||
      fileType === TEIL3 ||
      fileType === abmeldebescheinigung ||
      fileType === ABGELEHNT_RECORD
    ) {
      this.props
        .getZulassungsbescheinigungDocuments(
          this.state.requestId,
          fileType === TEIL1
            ? TEIL1_ENDPOINT
            : fileType === TEIL3
            ? TEIL3_ENDPOINT
            : fileType === abmeldebescheinigung
            ? abmeldebescheinigung
            : fileType === ABGELEHNT_RECORD
            ? ABGELEHNT_ENDPOINT
            : TEIL2_ENDPOINT
        )
        .then((resp) => {
          if (resp?.status === 200) {
            this.handleBlobData(
              resp?.data,
              this.getFileNameFromHttpResponse(resp)
            );
          }
        });
    } else if (fileType === "xml") {
      this.props.getRegisterDatenXML(this.state.requestId).then(() => {
        const xmlData = this.props.vorgangeDetailsReducer.registerDatenXmlData;
        if (xmlData?.status === 200) {
          const url = window.URL.createObjectURL(new Blob([xmlData.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${xmlData.headers["x-suggested-filename"]}`
          );
          document.body.appendChild(link);
          link.click();
        }
      });
    } else {
      let interneVorgangsNummer =
        this.state.antragRequestFormData.referenzID_23?.value; //getting it for downloaded file name

      this.props.downloadFile(this.state.requestId, fileType).then(() => {
        if (this.props.fileData && this.props.fileData !== null) {
          this.downloadBlobData(
            this.props.fileData,
            interneVorgangsNummer,
            fileType
          );
        }
      });
    }
  };
  handleFileSelection = (e) => {
    this.setState({ selectedFileType: e.target.value });
  };

  handleMessageClose = (userConformation) => {
    this.setState({ displayMessage: false, messageFor: ABORT });
    if (userConformation === true) {
      this.setState({ showLoader: true, showFullPageLoader: true });
      this.props.handleRejection(this.state.requestId).then(() => {
        this.closeDialogAndHideLoader(true);
      });
    }
  };

  handleVerwerfenClose = () => {
    this.setState({
      displayVerwefenDialog: false,
    });
  };

  handleVerwerfenSubmit = () => {
    this.setState({
      displayVerwefenDialog: false,
    });
    this.props.handleRejection(this.state.requestId).then(() => {
      this.closeDialogAndHideLoader(true);
    });
  };

  handleStep = (step) => {
    this.setState({ activeStep: step });
  };

  isFormDataHasError = (formIndex) => {
    if (formIndex === 0) {
      return this.state.antragRequestFormData.hasError ? true : false;
    }
    if (formIndex === 1) {
      return this.state.fahrzeugRequestFormData.hasError ? true : false;
    }
    if (formIndex === 2) {
      return this.state.kennzeichenRequestFormData.hasError ? true : false;
    }
    if (formIndex === 3) {
      return this.state.halterRequestFormData.hasError ? true : false;
    }
  };

  getTabLabel = (label, index) => {
    const isNZAndNaturliche =
      ["NZ", "TZ", "UM", "HA", "WZ"].includes(
        this.props.rowDetails.vorgangsart
      ) &&
      this.state.antragRequestFormData.personenform.value ===
        formConstants.individualPerson;

    const isVollmachtCompleted =
      this.props?.vollmachtSigningStatus?.completedStage === "sign";

    const icon = this.isFormDataHasError(index)
      ? errorIcon
      : index === this.state.activeStep
      ? blackCheckIcon
      : checkIcon;
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.tabLabel}>
          {this.state.requestId && (
            <img src={icon} alt="error" className={classes.marginRight5} />
          )}
          {label}
        </div>
        <div className={classes.tabIcon}>
          {index !== 2 && isNZAndNaturliche ? (
            <MDCTooltip title="Vollmachtsrelevant">
              <img
                src={
                  index === this.state.activeStep
                    ? isVollmachtCompleted
                      ? iconQESCompletionBlack
                      : iconProfilSmallBlack
                    : isVollmachtCompleted
                    ? iconQESCompletionGreen
                    : iconProfilSmall
                }
                alt=""
              />
            </MDCTooltip>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  getGlobalSuccessMessage = (classes) => {
    return (
      !this.state.showLoader &&
      this.state?.globalMessages?.length > 0 && (
        <Grid container marginTop="1rem">
          {this.state.globalMessages.map((messageDetails, i) => {
            return (
              <div className={`${classes.infoMsg}`} key={i}>
                {messageDetails?.isError ? (
                  <MDCBanner message={messageDetails?.message} error />
                ) : (
                  <MDCBanner message={messageDetails?.message} success />
                )}
              </div>
            );
          })}
        </Grid>
      )
    );
  };

  getSuccessMessage = (classes) => {
    return (
      <Grid container marginTop="1rem">
        <div
          className={`${classes.alert} ${classes.alertInfo} ${classes.infoMsg}`}
        >
          <span>
            Dieser Antrag wurde via Großkundenschnittstelle eingereicht.
          </span>
        </div>
      </Grid>
    );
  };

  closeDialog = () => {
    this.setState({
      globalMessages: [],
    });
    if (this.state.qesTimestamp) {
      // for resetting the QES files reducer to prevent file download of one request into another request
      this.props.resetVollmachtSignedData();
      this.setState({ qesTimestamp: "" });
    }

    // FYI, If vorgang list status and vorgange list status are different, Call Vorgange List API call
    if (
      this.props.rowDetails?.status !==
      this.state.antragRequestFormData?.status?.value
    ) {
      this.props.handleDialogClose(true);
    }
    this.props.handleDialogClose();
  };

  handleLoader = (param) => {
    this.setState({ showLoader: param, showFullPageLoader: param });
  };
  computeOperationIdsOptions = (resp) => {
    if (resp?.status === 200 && resp?.data?.length) {
      this.setState({ halterFormOperationIds: resp?.data });
      const options = [];
      resp?.data.map((item) => {
        options.push({
          label: `${item?.operationName?.value},${item?.operationId?.value}`,
          value: item?.operationId?.value,
        });
      });
      this.setState({
        operationIdsOptions: options,
      });
      // If there is only 1 operation id in list then it should be pre-selected in dropdown
      options.length === 1 &&
        this.setState({
          antragRequestFormData: Object.assign(
            {},
            this.state.antragRequestFormData,
            {
              betriebsnummern: {
                value: options[0].value,
                error: "",
              },
            }
          ),
        });
    }
  };
  getRegistrationDateAndEmpID = () => {
    const { employeeId, registrationDate, registrationProcessedDate } =
      this.state;
    if (employeeId && registrationDate) {
      return (
        <Grid
          container
          justifyContent="flex-end"
          paddingRight="2rem"
          color={theme.palette.MDCColors.color23}
        >
          <Grid display="flex" flexDirection="column" alignItems={"end"}>
            <Grid>
              Eingereicht von/am: {employeeId}/
              {`${moment(registrationDate).format("DD.MM.YYYY")} -
              ${moment(registrationDate).format("HH:mm")} Uhr `}
            </Grid>
            {registrationProcessedDate && (
              <Grid>
                Bescheid erhalten am:{" "}
                {`${moment(registrationProcessedDate).format("DD.MM.YYYY")} -
              ${moment(registrationProcessedDate).format("HH:mm")} Uhr `}
              </Grid>
            )}
          </Grid>
        </Grid>
      );
    }
  };

  renderFileDownload = (classes, file, index) => {
    return (
      <NavDropdown.Item
        key={index}
        onClick={() => this.downloadFile(file?.type)}
      >
        <img
          src={DownloadImage}
          className={classes.marginRight3}
          alt={file?.name}
        />
        <span>{file?.name}</span>
      </NavDropdown.Item>
    );
  };

  render() {
    let currentStatus = this.state.antragRequestFormData?.status?.value;
    let vorgangsart = this.state.antragRequestFormData.vorgangsart;
    const { classes } = this.props;
    const isSupportUserRole =
      this.props?.user?.roles?.includes(USER_ROLE_SUPPORT);
    const isOnlyAdminUserRole =
      this.props?.user?.roles?.includes(USER_ROLE_ADMIN) &&
      !this.props?.user?.roles?.includes(USER_ROLE_APPROVER);
    const isNZAndNaturliche =
      ["NZ", "TZ", "UM", "HA", "WZ"].includes(
        this.props.rowDetails.vorgangsart
      ) &&
      this.state.antragRequestFormData.personenform.value ===
        formConstants.individualPerson;

    const isVollmachtCompleted =
      this.props?.vollmachtSigningStatus?.completedStage === "sign";

    const qesCompletedStage =
      this.props?.vollmachtSigningStatus?.completedStage;

    const showDownloadsButton =
      currentStatus === "Genehmigt" ||
      currentStatus === "Abgelehnt" ||
      this.state?.qesTimestamp ||
      (isNZAndNaturliche && currentStatus === "Eingereicht");

    const displayDownloadFiles = () => {
      return (
        <>
          {this.state?.qesTimestamp &&
            QESFilesName.map((file, index) => {
              return (
                <>
                  {(this.state.halterRequestFormData?.Vorhanden.value ||
                    this.state.halterRequestFormData.steuerbefreiung.value !==
                      "") &&
                  file.type === "sepa" ? null : (
                    <NavDropdown.Item
                      key={index}
                      onClick={() => {
                        this.downloadFile(file.type);
                      }}
                    >
                      <img
                        src={DownloadImage}
                        alt=""
                        className={classes.marginRight3}
                      />
                      <span>
                        {file?.name === "Vollmachtsantrag"
                          ? this.state.vollmachtRequestFormData
                              ?.vollmachtsprozess?.value
                          : file?.name}
                      </span>
                    </NavDropdown.Item>
                  )}
                </>
              );
            })}

          {currentStatus === "Genehmigt" &&
            DataRegistrationFilesName.map((file, index) =>
              this.renderFileDownload(classes, file, index)
            )}

          {vorgangsart?.value !== "AB" &&
            currentStatus === "Genehmigt" &&
            ZulassungsFilesName.map((file, index) =>
              this.renderFileDownload(classes, file, index)
            )}

          {vorgangsart?.value === "AB" &&
            currentStatus === "Genehmigt" &&
            ABZulassungsFilesName.map((file, index) =>
              this.renderFileDownload(classes, file, index)
            )}

          {currentStatus === "Abgelehnt" &&
            RejectedAbgelehntFilesName.map((file, index) =>
              this.renderFileDownload(classes, file, index)
            )}
        </>
      );
    };

    const showGebuhrenDetailsTab = () => {
      // We need enable once we get the real time in future
      return false;
      // return currentStatus === "Genehmigt" || currentStatus === "Abgelehnt";
    };

    return (
      <div>
        <MDCModal
          id="details-modal"
          size="xl"
          open={this.props.show}
          isDetailDialog
          title={
            <Grid className={classes.numberPlate}>
              <MDCLicensePlate
                licensePlateF={this.state?.licensePlateF}
                licensePlateM={this.state.licensePlateM}
                licensePlateL={this.state?.licensePlateL}
                isELicensePlate={
                  this.state?.isELicensePlate
                }
              />
            </Grid>
          }
          body={
            <div className={this.props?.classes?.detailDialog}>
              {this.state.showFullPageLoader ? (
                <Grid className={this.props?.classes?.fullPageLoader}>
                  <MDCLoader />
                </Grid>
              ) : (
                <Tabs
                  defaultActiveKey={this.state.activeStep}
                  className="nav-justified"
                  onSelect={(tab) => this.handleStep(+tab)}
                >
                  <Tab
                    key={0}
                    eventKey={0}
                    title={this.getTabLabel("Vorgangsdaten", 0)}
                  >
                    <>
                      {this.getGlobalSuccessMessage(classes)}
                      <AntragRequestForm
                        formValues={this.state.antragRequestFormData}
                        vorgangsart={vorgangsart}
                        readOnly
                        isDetailDialog
                        isQESIconDisplay={isNZAndNaturliche}
                        isVollmachtCompleted={isVollmachtCompleted}
                        operationIdsOptions={this.state.operationIdsOptions}
                        requestId={this.state.requestId}
                        isZustellungZBError={this.state.isZustellungZBError} //Generic error flag for ZB address fields
                      />
                      {["Eingereicht", "Genehmigt", "Abgelehnt"].includes(
                        currentStatus
                      ) && this.getRegistrationDateAndEmpID()}
                    </>
                  </Tab>
                  <Tab
                    key={1}
                    eventKey={1}
                    title={this.getTabLabel("Fahrzeugidentifizierung", 1)}
                  >
                    <>
                      {this.getGlobalSuccessMessage(classes)}
                      <FahrzeugRequestForm
                        formValues={this.state.fahrzeugRequestFormData}
                        vorgangsart={vorgangsart}
                        readOnly
                        isDetailDialog
                        isQESIconDisplay={isNZAndNaturliche}
                        isVollmachtCompleted={isVollmachtCompleted}
                        countriesList={this.state.countriesList}
                      />
                    </>
                  </Tab>
                  <Tab
                    key={2}
                    eventKey={2}
                    title={this.getTabLabel("Kennzeichen", 2)}
                  >
                    <>
                      {this.getGlobalSuccessMessage(classes)}
                      <KennzeichenRequestForm
                        formValues={this.state.kennzeichenRequestFormData}
                        vorgangsart={vorgangsart}
                        readOnly
                        isDetailDialog
                        merkmalverwertungsnachweis={
                          this.state.fahrzeugRequestFormData
                            ?.merkmalverwertungsnachweis?.value
                        }
                        requestId={this.state.requestId}
                        status={this.state.antragRequestFormData?.status?.value}
                        operationIdsOptions={
                          this?.state?.halterFormOperationIds || []
                        }
                      />
                    </>
                  </Tab>
                  {this.state.antragRequestFormData.vorgangsart.value !==
                    "AB" && (
                    <Tab
                      key={3}
                      eventKey={3}
                      title={this.getTabLabel("Fahrzeughalter", 3)}
                    >
                      <>
                        {this.getGlobalSuccessMessage(classes)}
                        <HalterRequestForm
                          formValues={this.state.halterRequestFormData}
                          vorgangsart={vorgangsart}
                          readOnly
                          isDetailDialog
                          personenform={
                            this.state.antragRequestFormData.personenform
                          }
                          isQESIconDisplay={isNZAndNaturliche}
                          isVollmachtCompleted={isVollmachtCompleted}
                          companyDetails={this.props?.companyDetails}
                          qesCompletedStage={qesCompletedStage}
                          halterFormOperationIds={
                            this.state.halterFormOperationIds
                          }
                          representativeInfo={
                            this.state.vollmachtRequestFormData
                              ?.gesetztlicherVertreter?.value
                          }
                        />
                      </>
                    </Tab>
                  )}

                  {showGebuhrenDetailsTab() && (
                    <Tab
                      key={4}
                      eventKey={4}
                      title={this.getTabLabel("GEBÜHRENDETAILS", 4)}
                    >
                      <>
                        {this.getSuccessMessage(classes)}
                        <GebuhrenDetails />
                      </>
                    </Tab>
                  )}
                </Tabs>
              )}
            </div>
          }
          footer={
            !this.state.showLoader && (
              <>
                <Grid container mb={4} paddingX="1rem">
                  <Grid item xs={4}>
                    <MDCButton
                      variant="outlined"
                      label={"Schließen"}
                      onClick={this.closeDialog}
                    />
                  </Grid>

                  <Grid item xs={8}>
                    {!this.state.showLoader &&
                      this.props.activeTab !== "archiv" && (
                        <Grid container justifyContent="flex-end">
                          {currentStatus === "Genehmigt" && (
                            <MDCButton
                              variant="outlined"
                              label={"Registerdaten anzeigen"}
                              disabled={false}
                              onClick={() => {
                                this.handleDataRegistration();
                              }}
                              endIcon={<ButtonDocumentIcon />}
                            />
                          )}
                          {currentStatus === "Versandfertig" && (
                            <>
                              <MDCButton
                                variant="outlined"
                                label={"Verwerfen"}
                                onClick={() =>
                                  this.handleRejection(this.state.requestId)
                                }
                                endIcon={<BlockIcon />}
                                disabled={
                                  isSupportUserRole || isOnlyAdminUserRole
                                }
                              />
                              <MDCButton
                                variant="contained"
                                label={"Einreichen"}
                                disabled={
                                  this.state.buttonDisabled ||
                                  this.state.weiterleitenDissabled ||
                                  this.props?.rowDetails?.processing
                                }
                                onClick={() =>
                                  this.handleRegistration(this.state.requestId)
                                }
                                endIcon={<NextIcon />}
                              />
                            </>
                          )}
                          {currentStatus === "Eingereicht" && (
                            <MDCButton
                              variant="outlined"
                              label={"Aktualisieren"}
                              disabled={
                                this.state.buttonDisabled ||
                                this.state.pullRequestDissabled
                              }
                              onClick={() =>
                                this.handlePullRequest(this.state.requestId)
                              }
                              endIcon={<RefreshIcon />}
                            />
                          )}
                          {currentStatus === "Abgelehnt" && (
                            <MDCButton
                              variant="contained"
                              label={"Antrag erneut bearbeiten"}
                              disabled={this.state.buttonDisabled}
                              onClick={() => {
                                history.push("/manual-request", {
                                  requestId: this.state.requestId,
                                  createNewRequest: true,
                                });
                              }}
                              endIcon={<EditIcon />}
                            />
                          )}

                          <div className={classes.downloadDropdown}>
                            {showDownloadsButton && (
                              <NavDropdown
                                direction="top"
                                title={
                                  <MDCButton
                                    variant={
                                      this.state.isDownloadBtnOpen
                                        ? "contained"
                                        : "outlined"
                                    }
                                    label={"Downloads"}
                                    endIcon={
                                      this.state.isDownloadBtnOpen ? (
                                        <DownArrowIcon />
                                      ) : (
                                        <UpArrowIcon />
                                      )
                                    }
                                  />
                                }
                                onToggle={(toggleValue) => {
                                  this.setState({
                                    isDownloadBtnOpen: toggleValue,
                                  });
                                }}
                              >
                                {displayDownloadFiles()}
                              </NavDropdown>
                            )}
                          </div>
                        </Grid>
                      )}
                  </Grid>
                </Grid>
              </>
            )
          }
        />

        <MassageDialog
          show={this.state.displayMessage}
          messageFor={this.state.messageFor}
          handleDialogClose={this.handleMessageClose}
          mandatoryFieldError={this.state.mandatoryFieldError}
          redirectState={this.state.redirectState}
        />

        <VerwerfenPopupDialog
          show={this.state.displayVerwefenDialog}
          requestId={this.state.requestId}
          handleVerwerfenClose={this.handleVerwerfenClose}
          handleVerwerfenSubmit={this.handleVerwerfenSubmit}
        />

        <PopupDialog
          certificateDetails={
            this.props?.companyDetails?.accountPresent &&
            this.props?.companyDetails?.gksDetailsPresent
          }
          popUpHeading={ConfirmationPopupConstants.REGISTRATION_POPUP_HEADING}
          show={this.state.displayPopup}
          requestIds={[this.state.requestId]}
          handlePopupClose={this.handlePopupClose}
          handleLoader={this.handleLoader}
          handleDialogClose={this.props.handleDialogClose}
          isFromDetailView
        />
        {this.state.openDataRegistrationModal && (
          <DataRegistrationDialog
            show={this.state.openDataRegistrationModal}
            handlePopupClose={() =>
              this.setState({ openDataRegistrationModal: false })
            }
            requestId={this.state.requestId}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyDetails: state.authentication.companyDetails,
    vorgangeDetails: state.vorgangeDetailsReducer.vorgangeDetails,
    fileData: state.vorgangeDetailsReducer.fileData,
    vorgangeDetailsReducer: state.vorgangeDetailsReducer,
    user: state.authentication.user,
    sepaDocument: state.manualRequestFormReducer.sepaDocument,
    poaDocument: state.manualRequestFormReducer.poaDocument,
    vollmachtSigningStatus:
      state.manualRequestFormReducer.vollmachtSigningStatus,
    zbTeil1PDF: state.manualRequestFormReducer.zbTeil1PDF,
    zbTeil2PDF: state.manualRequestFormReducer.zbTeil2PDF,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIndividualVorgangeDetails: (id, isArchivData) =>
      dispatch(
        vorgangeDetailsActions.getIndividualVorgangeDetails(id, isArchivData)
      ),
    handleRejection: (id) =>
      dispatch(vorgangeDetailsActions.handleRejection(id)),
    handlePullRequest: (id) =>
      dispatch(vorgangeDetailsActions.handlePullRequest(id)),
    downloadFile: (requestId, fileType) =>
      dispatch(vorgangeDetailsActions.downloadFile(requestId, fileType)),
    getVollmachtSigningStatus: (requestId) =>
      dispatch(manualRequestFormActions.getVollmachtSigningStatus(requestId)),
    getVollmachtSignedDocuments: (requestId, docName) =>
      dispatch(
        manualRequestFormActions.getVollmachtSignedDocuments(requestId, docName)
      ),
    getZulassungsbescheinigungDocuments: (requestId, docName) =>
      dispatch(
        manualRequestFormActions.getZulassungsbescheinigungDocuments(
          requestId,
          docName
        )
      ),
    resetVollmachtSignedData: () =>
      dispatch(manualRequestFormActions.resetVollmachtSignedData()),
    // immutate getCompanyDetails because it cause problem in notification component.
    getCompanyDetails: (aliasName) =>
      dispatch(userActions.getCompanyDetails(aliasName)),
    getOperationIDs: (query, aliasName, tenantGroupData) =>
      dispatch(
        verimiAdminActions.getOperationIDs(query, aliasName, tenantGroupData)
      ),
    resetIndividualVorgangeDetails: () =>
      dispatch(vorgangeDetailsActions.resetIndividualVorgangeDetails()),
    getCountriesList: () =>
      dispatch(manualRequestFormActions.getCountriesList()),
    getSteuerbefreiungData: () =>
      dispatch(manualRequestFormActions.getSteuerbefreiungData()),
    getRegisterDatenXML: (requestId) =>
      dispatch(vorgangeDetailsActions.getRegisterDatenXML(requestId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(VorgangeDetailsDialog));
