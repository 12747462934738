import * as React from "react";

function FehlerIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 19.225 18.322"
      {...props}
    >
      <g id="ICON_Fehler" transform="translate(0 0.75)">
        <g
          id="Gruppe_3283"
          data-name="Gruppe 3283"
          transform="translate(3.542)"
        >
          <path
            id="Pfad_391"
            data-name="Pfad 391"
            d="M947.5,523.822l1.672-6.239.542-2.235L951.737,507h3.794l1.926,8.348.472,2.046,1.522,6.428Z"
            transform="translate(-947.5 -507)"
            fill="none"
            stroke="#ec5867"
            stroke-miterlimit="10"
            stroke-width="1.5"
          />
        </g>
        <path
          id="Pfad_392"
          data-name="Pfad 392"
          d="M955.129,540.5h7.589l.506,2.024-8.474-.126Z"
          transform="translate(-949.375 -532.026)"
          fill="#ec5867"
        />
        <path
          id="Pfad_393"
          data-name="Pfad 393"
          d="M959.006,522.5h5.565l.506,2.024H958.5Z"
          transform="translate(-952.176 -518.579)"
          fill="#ec5867"
        />
        <line
          id="Linie_118"
          data-name="Linie 118"
          x2="19.225"
          transform="translate(0 16.822)"
          fill="none"
          stroke="#ec5867"
          stroke-miterlimit="10"
          stroke-width="1.5"
        />
      </g>
    </svg>
  );
}

export default FehlerIcon;
