module.exports = {
  REQUEST_XML_LOGS_STATUS_ARR: {
    Abgelehnt: "Abgelehnt",
    Genehmigt: "Genehmigt",
    Versandfertig: "Versandfertig",
    Eingereicht: "Eingereicht",
    Fehler: "Fehler",
    "Fehler (B)": "Fehler (B)",
    "Fehler (T)": "Fehler (T)",
  },
  REQUESTS_TYPE_DISPLAY_NAMES: {
    AB: "Außerbetriebsetzung",
    NZ: "Neuzulassung",
    TZ: "Tageszulassung",
    UM: "Ummeldung",
    HA: "Namens- oder Adressänderung des Halters",
    WZ: "Wiederzulassung",
  },
  NO_SUPPORT_KBA_DATA: "In dem Vorgang ist keine Supporteinheit hinterlegt.",

  // Support KBA Reducer Starts
  SUPPORT_KBA_REQUEST: "SUPPORT_KBA_REQUEST",
  SUPPORT_KBA_SUCCESS: "SUPPORT_KBA_SUCCESS",
  RESET_SUPPORT_KBA_SUCCESS: "RESET_SUPPORT_KBA_SUCCESS",
  // Ends
};
