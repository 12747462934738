import React from "react";
import PropTypes from "prop-types";

// Material UI Components
import { withStyles } from "@mui/styles";
import MaterialTable from "material-table";

//MDC Theme
import theme from "../../themes/mdc-theme";

const styles = () => ({
  defaultTableCSS: {
    // Scrollbar CSS
    "& ::-webkit-scrollbar-track": {
      background: theme.palette.MDCColors.color21,
    },

    "& ::-webkit-scrollbar-thumb": {
      border: theme.palette.MDCColors.color21,
    },

    "& .MuiPaper-elevation2": {
      boxShadow: "none",
    },
    //Table Column Header CSS
    "& .MuiTableHead-root": {
      "& .MuiTableCell-head": {
        color: theme.palette.MDCColors.textColor,
        fontFamily: theme.palette.typography.fontFamily,
        fontSize: "1rem",
        backgroundColor: theme.palette.MDCColors.color21,
        padding: "3%",
      },
    },
    // Table Body CSS
    "& .MuiTableBody-root": {
      color: theme.palette.MDCColors.textColor,
      "& .MuiTableRow-root": {
        "& .MuiTableCell-body": {
          padding: "3%",
        },
      },
    },
    "& .MuiTableCell-root": {
      wordBreak: "break-word",
    },
  },
  filterTableCSS: {
    //Table Column Header CSS
    "& .MuiTableHead-root": {
      "& .MuiTableCell-head": {
        padding: "1.5%",
      },
    },
    // Table Body CSS
    "& .MuiTableBody-root": {
      "& .MuiTableRow-root": {
        //Filter CSS starts
        "&:first-child": {
          background: theme.palette.MDCColors.color21,
        },
        "& .MuiFormControl-root": {
          width: "100%",
          background: theme.palette.MDCColors.white,
          fontsize: "1rem",
          "& .MuiFormLabel-root": {
            top: "-6px",
          },
          "& .MuiInputBase-input": {
            color: theme.palette.MDCColors.color18,
          },
          "& .MuiFormLabel-root, & .MuiInputBase-input": {
            padding: "8px 8px 8px",
            height: "auto",
            color: theme.palette.MDCColors.color18,
          },
          "& .MuiIcon-root": { display: "none" },
        },
        "& .MuiInput-underline": {
          "&:before": {
            display: "none",
          },
        },
        "&:last-child": {
          fontWeight: "bold",
        },
        //Filter CSS ends
        "& .MuiTableCell-body": {
          padding: "1.5%",
        },
      },
    },
  },
});

function MDCSimpleTable(props) {
  const { id, columns, data, options, classes, isFiltering } = props;
  const tableCSS = `${classes.defaultTableCSS}  ${
    isFiltering ? classes.filterTableCSS : ""
  }`;
  return (
    <div className={tableCSS}>
      <MaterialTable id={id} columns={columns} data={data} options={options} />
    </div>
  );
}

MDCSimpleTable.prototype = {
  id: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  options: PropTypes.object,
};

export default withStyles(styles)(MDCSimpleTable);
