import axios from "axios";

// Config
import { config } from "../config";

// Create Order License Plate
async function createOrderLicensePlate(data, requestId) {
  const url = `${config.apiUrl}/vorgangs/${requestId}/orders/license-plate`;

  try {
    const resp = await axios.post(url, data);
    return resp;
  } catch (error) {
    return error.response;
  }
}

// Get Order License Plate
async function getOrderLicensePlate(requestId) {
  const url = `${config.apiUrl}/vorgangs/${requestId}/orders`;

  try {
    const resp = await axios.get(url);
    return resp;
  } catch (error) {
    return error;
  }
}

// Delete Order License Plate
async function deleteOrderLicensePlate(requestId, orderId) {
  const url = `${config.apiUrl}/vorgangs/${requestId}/orders/${orderId}`;

  try {
    const resp = await axios.delete(url);
    return resp;
  } catch (error) {
    return error;
  }
}

export const OrderLicensePlateService = {
  createOrderLicensePlate,
  getOrderLicensePlate,
  deleteOrderLicensePlate,
};
