//lOGOS
import errorMessageLogo from "../../assets/images/icon_Fehler.svg";
import successMessageLogo from "../../assets/images/icon_Conform.svg";
import infoMessageLogo from "../../assets/images/icon_bell.svg";
import duplicateMessageLogo from "../../assets/images/icon_duplicate.svg";
import iconInfoYellow from "../../assets/images/icon_info_yellow.svg";

import theme from "../../themes/mdc-theme";

export const alertCSS = {
  marginBottom: "0.5rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  overflow: "hidden",
  fontFamily: theme.palette.typography.fontFamily,
  borderRadius: "5px",
  background: "#fff",
  boxShadow: `0px 3px 0.938rem ${theme.palette.MDCColors.color18}`,
  fontSize: "1rem",
  color: theme.palette.MDCColors.color18,
  lineHeight: 1,
  cursor: "pointer",
  direction: "ltr",
  position: "relative",
  minHeight: "3.875rem",
  padding: "5px 1.25rem 5px 2.188rem",
  "&> span": {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  "&:before": {
    content: "''",
    display: "block",
    height: "1.188rem",
    width: "1.188rem",
    position: "absolute",
    left: "0.625rem",
    top: "50%",
    marginTop: "-9.5px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
};

export const alertErrorCSS = {
  border: `2px solid ${theme.palette.MDCColors.errorDanger}`,
  "&:before": {
    backgroundImage: `url(${errorMessageLogo})`,
  },
};

export const alertSuccessCSS = {
  border: `2px solid ${theme.palette.MDCColors.errorSuccess}`,
  "&:before": {
    backgroundImage: `url(${successMessageLogo})`,
  },
};

export const alertDuplicateCSS = {
  border: `2px solid ${theme.palette.MDCColors.color34}`,
  "&:before": {
    backgroundImage: `url(${duplicateMessageLogo})`,
  },
};

export const alertWithInfoIconCSS = {
  border: `2px solid ${theme.palette.MDCColors.color34}`,
  "&:before": {
    backgroundImage: `url(${iconInfoYellow})`,
  },
};

export const alertInfoCSS = {
  border: `2px solid ${theme.palette.MDCColors.color23}`,
  "&:before": {
    backgroundImage: `url(${infoMessageLogo})`,
    top: "40%",
    width: "2rem",
    height: "2rem",
    left: "0.5rem",
  },
};

export const closeIconCSS = {
  position: "absolute",
  top: "50%",
  right: "0",
  zIndex: 9,
  fontWeight: 200,
  fontSize: "2vw",
  color: theme.palette.MDCColors.color18,
  opacity: 0.8,
  fontFamily: theme.palette.typography.fontFamily,
  textShadow: "none",
  lineHeight: "1.188rem",
  marginTop: "-0.5vw",
  background: "none",
  border: "none",
  "&:hover": {
    opacity: 1,
  },
};
