import { ADMIN_CONSTANTS } from "../../../constants/adminConstants";

export default (state, action) => {
  if (typeof state === "undefined") {
    state = {};
  }
  switch (action.type) {
    case ADMIN_CONSTANTS.GET_CLIENT:
      return {
        client: action.client,
      };
    case ADMIN_CONSTANTS.GET_ALL_USERS_SUCCESS:
      return Object.assign({}, state, {
        allUsers: action.users,
      });
    case ADMIN_CONSTANTS.GET_USERS:
      return {
        user: action.user,
      };
    case ADMIN_CONSTANTS.GET_VERIMI_SUCCESS:
      return Object.assign({}, state, {
        verimi: action.verimi,
      });
    default:
      return state;
  }
};
