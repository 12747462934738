export const userStatusFilterOption = {
  Aktiv: "Aktiv",
  Angelegt: "Angelegt",
  Gesperrt: "Gesperrt",
};

export const messageStatusFilterOption = {
  "Nachricht aktiv": "Nachricht aktiv",
  "Nachricht datiert": "Nachricht datiert",
  "Nachricht deaktiviert": "Nachricht deaktiviert",
};

export const NACHRICHT_DEAKTIVIERT = "Nachricht deaktiviert";

export const userRoleFilterOption = {
  MDC_ADMIN: "MDC_ADMIN",
  ADMIN: "ADMIN",
  API_USER: "API_USER",
  SUPPORT: "SUPPORT",
  APPROVER: "APPROVER",
};
