import * as React from "react";

function RefreshIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22.432}
      height={18.859}
      {...props}
    >
      <g strokeLinecap="round">
        <path
          data-name="Pfad 554"
          d="M17.517 7.044a8.421 8.421 0 11-6.61-5.915"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <g data-name="Pfad 397" fill="currentColor" strokeLinejoin="round">
          <path d="M16.156 3.26l3.1 2.898a275.797 275.797 0 00-1.529.002c-.25.003-.654.008-.944.366-.136.138-.61.595-1.19 1.151l.563-4.417z" />
          <path d="M14.244 10.35l1.167-9.155L21.8 7.166s-4.258-.02-4.247 0c.01.02-3.309 3.184-3.309 3.184z" />
        </g>
      </g>
    </svg>
  );
}

export default RefreshIcon;
